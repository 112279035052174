// main: ../../App.scss

$offDark: #111111;
$spiderBlue: #1aa6a7;
$spiderRed: #b0120f;

.Demo {
  height: 100%;
  background: $offDark;
  overflow-y: scroll;
  .DemoBallroom {
    background: $offDark;
    max-width: 1024px;
    margin: 0px auto;
    text-transform: uppercase;

    .DemoBallroomTopBar {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 32px 8px;

      .DemoBallroomTopBarLogo {
        width: 100px;
        height: 44px;
        object-fit: contain;
      }
      .DemoBallroomTopBarLinks {
        display: flex;
        flex-flow: nowrap;
        justify-content: flex-end;
        align-items: center;

        .DemoBallroomTopBarItem {
          @include fontSizeMedium();
          color: $spiderBlue;
          @include pushable();
          padding-left: 12px;
        }
        .DemoBallroomTopBarItemRed {
          color: $spiderRed;
        }
        .DemoBallroomTopBarItemWhite {
          color: var(--whiteColor);
        }
      }
    }

    .DemoBallroomEvents {
      background: $offDark;
      padding-bottom: 32px;
      .DemoBallroomEvent {
        color: var(--whiteColor);
        padding: 32px 8px;
        border-bottom: 1px solid #333333;

        .DemoBallroomEventTop {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 16px;

          .DemoBallroomEventTopDate {
            text-align: left;
            @include fontSizeMedium();
            color: var(--whiteColor);
          }
          .DemoBallroomEventTopTime {
            text-align: right;
            @include fontSizeMedium();
            color: var(--whiteColor);
          }
        }

        .DemoBallroomEventContent {
          display: flex;
          flex-flow: row wrap-reverse;
          justify-content: flex-start;
          align-items: center;

          .DemoBallroomEventContentLeft {
            .DemoBallroomEventContentLeftTickets {
              @include fontSizeMedium();
              color: $offDark;
              width: 186px;
              background: $spiderBlue;
              padding: 12px;
              @include pushable();
              @include noselect();
            }
          }

          .DemoBallroomEventContentMiddle {
            flex-grow: 1;
            text-align: left;
            padding: 0px 32px;
            max-width: calc(100% - 280px);

            .DemoBallroomEventContentMiddleTitle {
              @include fontSizeTitle();
              font-size: 54px;
              color: var(--whiteColor);
              padding-bottom: 4px;
            }
            .DemoBallroomEventContentMiddleDescription {
              @include fontSizeMedium();
              color: var(--whiteColor);
            }
          }

          @media only screen and (max-width: 700px) {
            .DemoBallroomEventContentLeft {
              text-align: center;
              margin: 32px auto 0px auto;
            }
            .DemoBallroomEventContentMiddle {
              width: calc(100% - 16px);
              max-width: calc(100% - 16px);
              text-align: center;

              .DemoBallroomEventContentMiddleTitle {
                font-size: 32px;
              }
              .DemoBallroomEventContentMiddleDescription {

              }
            }
          }
        }
      }
    }

    .DemoBallroomBottomBar {
      padding: 32px 8px;
      background: #222222;

      .DemoBallroomBottomBarItem {
        @include fontSizeMedium();
        color: var(--whiteColor);
      }
    }
  }

  .DemoList {
    background: var(--whiteColor);
    height: calc(100% - 32px);
    padding-top: 32px;

    .DemoListTitle {
      @include fontSizeTitle();
      color: var(--darkColor);
    }
    .DemoListDescription {
      @include fontSizeNormal();
      color: var(--grayColor);
      padding-top: 8px;
    }

    .DemoListItem {
      @include fontSizeMedium();
      color: var(--darkColor);
      display: block;
      text-decoration: none;
      background: var(--offWhiteColor);
      padding: 12px;
      width: 186px;
      border-radius: 12px;
      margin: 16px auto;
      @include pushable();
      @include noselect();
      text-transform: capitalize;
    }
  }
}
