// main: ../../App.scss

@mixin pulse($time: 1s) {
    -webkit-animation: pulsea $time;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: pulsea $time;
    /* Firefox < 16 */
    -ms-animation: pulsea $time;
    /* Internet Explorer */
    -o-animation: pulsea $time;
    /* Opera < 12.1 */
    animation: pulsea $time;
    animation-iteration-count: infinite;
}

@keyframes pulsea {
    0% {
        width: 150px;
        opacity: 0;
    }

    20% {
        width: 150px;
        opacity: 0;
    }

    50% {
        width: 200px;
        opacity: 1.0;
    }

    100% {
        width: 150px;
        opacity: 0.0;
    }
}

@mixin fadeIn($time: 1s) {
    -webkit-animation: fadein $time;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein $time;
    /* Firefox < 16 */
    -ms-animation: fadein $time;
    /* Internet Explorer */
    -o-animation: fadein $time;
    /* Opera < 12.1 */
    animation: fadein $time;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1.0;
    }
}

@mixin scrollAnimation($time: 1s) {
    -webkit-animation: scrollanimation $time linear infinite;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: scrollanimation $time linear infinite;
    /* Firefox < 16 */
    -ms-animation: scrollanimation $time linear infinite;
    /* Internet Explorer */
    -o-animation: scrollanimation $time linear infinite;
    /* Opera < 12.1 */
    animation: scrollanimation $time linear infinite;
}

/* Move it (define the animation) */
@-moz-keyframes scrollanimation {
    0% {
        -moz-transform: translateX(382px);
    }

    100% {
        -moz-transform: translateX(-120%);
    }
}

@-webkit-keyframes scrollanimation {
    0% {
        -webkit-transform: translateX(382px);
    }

    100% {
        -webkit-transform: translateX(-120%);
    }
}

@keyframes scrollanimation {
    0% {
        -moz-transform: translateX(382px);
        /* Firefox bug fix */
        -webkit-transform: translateX(382px);
        /* Firefox bug fix */
        transform: translateX(382px);
    }

    100% {
        -moz-transform: translateX(-120%);
        /* Firefox bug fix */
        -webkit-transform: translateX(-120%);
        /* Firefox bug fix */
        transform: translateX(-120%);
    }
}



@mixin wristbandAnimation($time: 1s) {
    -webkit-animation: wristbandanimation $time linear infinite;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: wristbandanimation $time linear infinite;
    /* Firefox < 16 */
    -ms-animation: wristbandanimation $time linear infinite;
    /* Internet Explorer */
    -o-animation: wristbandanimation $time linear infinite;
    /* Opera < 12.1 */
    animation: wristbandanimation $time linear infinite;
}

@-moz-keyframes wristbandanimation {
    0% {
        -moz-transform: translateX(0px);
    }

    100% {
        -moz-transform: translateX(100%);
    }
}

@-webkit-keyframes wristbandanimation {
    0% {
        -webkit-transform: translateX(0px);
    }

    100% {
        -webkit-transform: translateX(100%);
    }
}

@keyframes wristbandanimation {
    0% {
        -moz-transform: translateX(0px);
        /* Firefox bug fix */
        -webkit-transform: translateX(0px);
        /* Firefox bug fix */
        transform: translateX(0px);
    }

    100% {
        -moz-transform: translateX(100vw);
        /* Firefox bug fix */
        -webkit-transform: translateX(100vw);
        /* Firefox bug fix */
        transform: translateX(100%);
    }
}

$adminMaxWidth: 1024px;

// The 
.Geoji .GeojiCn .GeojiCnInner, .GeojiCnInner {
    background: var(--offWhiteColor);
    width: 100%;
    max-width: 414px;
    border-radius: 0px;
    margin: 8px 0px;
    @include materialSpreadShadow();
    border-radius: 32px;

    @media only screen and (max-width: 414px) {
        border-radius: 0px;
        margin: 0px;
        box-shadow: none;
        //height: 100%;
        //overflow-y: scroll;
    }

    .GeojiCnInnerWhite {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;

        @media only screen and (max-width: 414px) {
            border-radius: 0px 0px 36px 36px;
        }

        .GeojiCnTopBar {
            margin: 0px auto;
            padding-bottom: 16px;

            .GeojiCnTopBarEmoji {
                @include fontSizeTitle();
            }
        }

        //default geoji theme
        .GeojiCnImageView {
            width: 311px;
            height: 311px;
            margin: 0px auto;
            background: var(--offWhiteColor);
            border-radius: 32px;
            overflow: hidden;
            position: relative;
            @include materialSpreadShadow();

            @media only screen and (max-width: 500px) {
                width: 288px;
                height: 288px;
            }

            @media only screen and (max-width: 400px) {
                width: 248px;
                height: 248px;
            }

            .GeojiCnImageViewImage {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .GeojiCnImageViewEmoji {
                font-size: 180px;
                position: absolute;
                left: 0px;
                right: 0px;
                top: calc(50% - 100px);
                line-height: 200px;
            }
        }

        //tall geoji theme
        .GeojiCnImageViewTall {
            width: calc(100% + 64px);
            margin-left: -32px;
            margin-top: -85px;
            background: var(--whiteColor);
            border-radius: 32px 32px 0px 0px;
            overflow: hidden;
            position: relative;

            @media only screen and (max-width: 414px) {
                border-radius: 0px;
            }

            .GeojiCnImageViewTallBackground {
                position: absolute;
                bottom: 0px;
                left: 0px;
                z-index: 2;
                width: 100%;
                height: 100px;

                background: rgb(255, 255, 255);
                background: -moz-linear-gradient(180deg, var(--whiteTransparentColor00) 0%, var(--whiteColor) 100%);
                background: -webkit-linear-gradient(180deg, var(--whiteTransparentColor00) 0%, var(--whiteColor) 100%);
                background: linear-gradient(180deg, var(--whiteTransparentColor00) 0%, var(--whiteColor) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
            }

            .GeojiCnImageViewImage {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .GeojiCnImageViewEmoji {
                width: 100%;
                height: 400px;
                line-height: 400px;
                font-size: 180px;
            }
        }

        .InputTitle {
            padding: 32px 0px 0px 0px;
            margin: 0px;
            
            .inputBottomLineLightContainer  {
                .inputBottomLineTop {
                    display: none;
                }
                .inputBottomLineLightFlexBox {
                    border: 2px dashed var(--grayLineColor);
                    background-color: var(--whiteColor);
                    padding: 0px 4px;
                    width: calc(100%);
                    margin-left: -6px;

                    .inputBottomAreaLight {
                        @include fontSizeTitle();
                        min-height: 33px;
                        padding: 0px;
                    }

                    .inputBottomLineLight {
                        @include fontSizeTitle();
                        background-color: var(--whiteColor);
                        padding: 0px;
                    }
                    .inputBottomLineIcon {
                        padding: 8px 12.5px 0px 0px;
                    }
                }
                .inputBottomLineInvalid {
                    display: none;
                }
            }
        }

        .InputPhoto {
            padding: 0px 0px 0px 0px;
            margin: -32px -32px 0px -32px;
            border-radius: 32px 32px 0px 0px;
            overflow: hidden;

            .inputBottomLineLightContainer {
                .inputBottomLineTop {
                    display: none;
                }

                .FileSelectorDiv {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    margin: 0px;

                    .FileSelectorDivImageContainer {
                        background-color: var(--whiteColor);
                        width: calc(100% - 8px);
                        height: auto;
                        padding: 0px;
                        margin: 4px 4px 0px 4px;
                        cursor: pointer;
                        transition: all 500ms;
                        border: 2px dashed var(--grayLineColor);
                        border-radius: 32px;

                        .FileSelectorDivImage {
                            width: 100px;
                            height: 100px;
                            object-fit: contain;
                            margin-top: 32px;
                        }
                        .FileSelectorDivTitle {
                            @include fontSizeMedium();
                            color: var(--darkColor);
                            padding-top: 8px;

                            span {
                                color: var(--blueColor);
                            }
                        }
                        .FileSelectorDivDescription {
                            @include fontSizeSmall();
                            color: var(--grayColor);
                            padding-top: 8px;
                            padding-bottom: 32px;
                        }
                    }
                    .FileSelectorDivImageContainer:hover {
                        background-color: var(--offWhiteColor);
                    }
                    .FileSelectorDivImageContainerDragging {
                        background-color: var(--blueColorLighten45) !important;
                        border-color: var(--blueColor) !important;
                    }
                    .FileSelectorDivImageContainerSet {
                        background-color: var(--whiteColor);
                        width: 100%;
                        height: 220px;
                        padding: 0px;
                        margin: 0px;
                        cursor: pointer;
                        position: relative;

                        .FileSelectorDivImageSelected {
                            width: 100%;
                            object-fit: cover;
                        }

                        .FileSelectorDivImageX {
                            position: absolute;
                            right: 20px;
                            top: 20px;
                            z-index: 1;
                            background-color: var(--whiteColor);
                            padding: 2px;
                            margin: 0px;
                            width: 32px;
                            height: 32px;
                            border-radius: 32px;
                            img {
                                width: 32px;
                            }
                        }
                    }

                    .FileSelectorDivImageContainerSetDragging {
                        .FileSelectorDivImageSelected {
                            opacity: 0.5;
                        }
                    }

                    .FileSelectorDivUploading {
                        width: 80%;
                        margin: 0px auto 16px auto;
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid var(--offWhiteColor);
                        border-radius: 16px;
                        padding: 8px;

                        .FileSelectorDivUploadingImage {
                            height: 40px;
                            object-fit: contain;
                        }
                        .FileSelectorDivUploadingDetails {
                            flex-grow: 1;
                            width: 100%;
                            margin: 0px 8px 0px 8px;
                            @include fadeIn();

                            .FileSelectorDivUploadingName {
                                @include fontSizeSmall();
                                font-weight: normal;
                                color: var(--darkColor);
                                text-align: left;
                                margin-bottom: 8px;
                            }
                            .FileSelectorDivUploadingBar {
                                background-color: var(--grayLineColor);
                                height: 3px;
                                border-radius: 2px;
                                .FileSelectorDivUploadingBarInner {
                                    height: 3px;
                                    background-color: var(--blueColor);
                                    border-radius: 2px;
                                    transition: all 500ms;
                                }
                            }
                        }
                        .FileSelectorDivUploadingPercent {
                            @include fontSizeSmall();
                            color: var(--darkColor);
                            min-width: 40px;
                        }
                    }
                }

                .inputBottomLineInvalid {
                    display: none;
                }
            }
        }

        .GeojiCnTitle {
            @include fontSizeTitle();
            padding-top: 32px;
            text-align: left;
            word-wrap: break-word;
            margin: 0px;
        }

        .GeojiCnTitleTall {
            position: relative;
            padding-top: 0px;
            //margin-top: -12px;
            margin-top: 16px;
            z-index: 5;
        }

        .GeojiCnDateInfinite {
            padding: 16px 0px 0px 0px;
            text-align: left;

            .GeojiCnDateInfiniteImage {
                height: 20px;
                width: 40px;
                object-fit: contain;
            }
        }

        .GeojiCnDate {
            @include fontSizeMedium();
            color: var(--grayColor);
            padding: 16px 0px 0px 0px;
            text-align: left;
        }

        .GeojiCnTime {
            @include fontSizeNormal();
            padding: 16px 0px 0px 0px;
            margin: 0px;
            color: var(--grayColor);
            text-align: left;
        }

        .GeojiCnActivation {
            height: 50px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            position: relative;

            .GeojiCnActivationText {
                @include fontSizeNormal();
                color: var(--grayColor);
                position: absolute;
                height: 100%;
                margin: 0px;
                line-height: 50px;
                text-align: center;

                @include scrollAnimation(10s);
            }
        }

        .GeojiCnAdminButton {
            background: var(--dashboardColor);
            padding: 12px 0px;
            margin-top: 16px;
            @include fontSizeMedium();
            color: $whiteColor;
            border-radius: 12px;
            @include pushable();

            img {
                height: 14px;
                margin-right: 8px;
            }
        }

        .GeojiCnMakeMoneyButton {
            @include blueGreenGradient();
            padding: 12px 0px;
            margin-top: 16px;
            @include fontSizeMedium();
            color: $whiteColor;
            border-radius: 12px;
            @include pushable();

            img {
                height: 14px;
                margin-right: 8px;
            }
        }
    }

    .GeojiCnInnerGray {
        min-height: 100px;
        padding: 16px 32px 32px 32px;

        .GeojiCnInnerGrayGroup {

            #GeojiTokenHeader {
                display: block;
                position: relative;
                left: 0px;
                top: -320px;
                height: 1px;
            }

            .GeojiCnInnerGrayGroupTitle {
                @include fontSizeMid();
                color: var(--darkColor);
                text-align: left;
                padding: 16px 0px;
            }

            .InputDetails {
                padding: 0px 0px 0px 0px;
                margin: 0px;
                
                .inputBottomLineLightContainer  {
                    .inputBottomLineTop {
                        display: none;
                    }
                    .inputBottomLineLightFlexBox {
                        border: 2px dashed var(--grayLineColor);
                        background-color: var(--offWhiteColor);
                        padding: 0px 4px;
                        width: calc(100%);
                        margin-left: -6px;
    
                        .inputBottomAreaLight {
                            @include fontSizeNormal();
                            color: var(--grayColor);
                            min-height: 36px;
                            padding: 0px;
                        }
                        .inputBottomLineIcon {
                            padding: 8px 12.5px 0px 0px;
                        }
                    }
                    .inputBottomLineInvalid {
                        display: none;
                    }
                }
            }

            .GeojiCnInnerGrayGroupBody {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;

                .GeojiCnInnerGrayGroupBodyIcon {
                    height: 18px;
                    width: 18px;
                    object-fit: contain;
                    background: var(--whiteColor);
                    padding: 8px;
                    border-radius: 17px;
                    margin-right: 8px;
                }

                .GeojiCnInnerGrayGroupBodyText {
                    @include fontSizeNormal();
                    color: var(--grayColor);
                    text-align: left;
                    word-wrap: break-word;
                    word-break: break-word;
                    text-decoration: none;
                    @include pushable();
                }
            }

            .GeojiCnInnerGrayGroupDescription {
                @include fontSizeNormal();
                color: var(--grayColor);
                text-align: left;
                word-wrap: break-word;
                white-space: pre-wrap;
                padding: 0px;
                margin: 0px;

                .GeojiCnInnerGrayGroupDescriptionPart {

                    .GeojiCnInnerGrayGroupDescriptionPartLink {
                        @include fontSizeMedium();
                        color: var(--blueColor);
                        text-decoration: none;
                        @include pushable();

                        &:hover {
                            color: var(--blueDarkenColor10);
                        }
                    }

                    .GeojiCnInnerGrayGroupDescriptionPartBold {
                        @include fontSizeMid();
                        color: var(--grayColor);
                    }
                }
            }

            .GeojiCnInnerGrayGroupTokens {}

            .GeojiCnInnerGrayGroupAccept {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;

                .GeojiCnInnerGrayGroupAcceptBox {
                    padding: 0px 0px 0px 0px;
                    margin: 0px 0px 0px 16px;
                }

                .GeojiCnInnerGrayGroupAcceptTitle {
                    width: calc(100% - 48px);
                    @include fontSizeSmall();
                    font-weight: normal;
                    color: var(--grayColor);
                    text-align: left;
                    padding: 0px 16px 0px 16px;

                    a,
                    a:hover,
                    a:visited,
                    a:active,
                    a:focus,
                    a:target {
                        color: var(--darkColor) !important;
                        text-decoration: none !important;
                    }
                }
            }

            .GeojiCnInnerGrayGroupEventDates {
                .rmdp-wrapper {
                    width: 100%;
                    box-shadow: none;
                    border-radius: 12px;
                    background: var(--whiteColor);

                    .rmdp-calendar {
                        width: 100%;
                        height: 100%;
                        @include noselect();

                        .rmdp-header {
                            .rmdp-header-values {
                                @include fontSizeNormal();
                                color: var(--darkColor);
                            }

                            .rmdp-arrow-container:hover {
                                background: var(--blueColor);
                                box-shadow: none;

                                .rmdp-arrow {
                                    border: solid var(--whiteColor);
                                    border-width: 0px 2px 2px 0px;
                                }
                            }

                            .rmdp-arrow {
                                border: solid var(--blueColor);
                                border-width: 0px 2px 2px 0px;
                            }
                        }

                        .rmdp-day-picker {
                            flex-flow: row nowrap;
                            justify-content: center;
                            align-items: center;

                            div:first-of-type {
                                width: 100%;

                                .rmdp-week {
                                    flex-flow: row nowrap;
                                    justify-content: space-between;
                                    align-items: center;

                                    .rmdp-week-day {
                                        @include fontSizeSmall();
                                        color: var(--blueColor);
                                        width: 34px;
                                        height: 34px;
                                    }

                                    .rmdp-day {
                                        width: 34px;
                                        height: 34px;
                                        @include fontSizeSmall();
                                        color: var(--darkColor);
                                    }

                                    .rmdp-today {
                                        span {
                                            background: var(--grayLineColor);
                                            color: var(--darkColor);
                                        }
                                    }

                                    .rmdp-selected {
                                        span {
                                            box-shadow: none;
                                            background: var(--blueColor);
                                            color: var(--whiteColor);
                                        }
                                    }
                                }
                            }
                        }

                        .rmdp-year-picker {
                            background: var(--whiteColor);

                            .rmdp-ym {
                                .rmdp-day {
                                    span {
                                        @include fontSizeSmall();
                                        color: var(--darkColor);
                                    }
                                }

                                .rmdp-today {
                                    span {
                                        background: var(--grayLineColor);
                                        color: var(--darkColor);
                                    }
                                }

                                .rmdp-selected {
                                    span {
                                        background: var(--blueColor);
                                        color: var(--whiteColor);
                                        box-shadow: none;
                                    }
                                }
                            }
                        }

                        .rmdp-month-picker {
                            background: var(--whiteColor);

                            .rmdp-ym {
                                .rmdp-day {
                                    span {
                                        @include fontSizeSmall();
                                        color: var(--darkColor);
                                    }
                                }

                                .rmdp-today {
                                    span {
                                        background: var(--grayLineColor);
                                        color: var(--darkColor);
                                    }
                                }

                                .rmdp-selected {
                                    span {
                                        background: var(--blueColor);
                                        color: var(--whiteColor);
                                        box-shadow: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .GeojiCnInnerGrayGroupWhoGoing {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                @include pushableSimple();

                .GeojiCnInnerGrayGroupWhoGoingProfile {
                    position: relative;

                    .URLImage {
                        .URLImageError {
                            width: 44px;
                            height: 44px;
                            background: var(--grayLineColor);
                            border-radius: 22px;
                        }

                        .URLImageImage {
                            width: 44px;
                            height: 44px;
                            object-fit: cover;
                            border-radius: 22px;
                        }
                    }
                }
            }

            .GeojiCnInnerGrayGroupWhoGoingBody {
                @include fontSizeNormal();
                text-align: left;
                color: var(--grayColor);
                padding-top: 8px;
                padding-bottom: 16px;
            }

            .GeojiCnInnerGrayGroupRequestAccessBody {
                @include fontSizeNormal();
                text-align: left;
                color: var(--grayColor);
                padding-top: 8px;
                padding-bottom: 16px;
            }

            .GeojiCnInnerGrayGroupRequestAccessButton {
                background: var(--blueColor);
                padding: 12px 0px;
                margin-bottom: 16px;
                @include fontSizeMedium();
                color: $whiteColor;
                border-radius: 12px;
                @include pushable();
                @include noselect();

                img {
                    height: 14px;
                    object-fit: contain;
                    margin-right: 8px;
                }
            }

            .GeojiCnInnerGrayGroupPurchases {
                .GeojiCnInnerGrayGroupPurchase {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: flex-start;
                    margin-bottom: 16px;
                    @include pushable();

                    .GeojiCnInnerGrayGroupPurchaseLeft {
                        @include fontSizeNormal();
                        background: var(--whiteColor);
                        width: 70px;
                        height: 100px;
                        line-height: 100px;
                        border-radius: 12px;
                        font-size: 40px;
                        flex-shrink: 0;
                    }

                    .GeojiCnInnerGrayGroupPurchaseMiddle {
                        flex-grow: 1;
                        text-align: left;
                        padding-left: 8px;
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: flex-start;
                        align-items: flex-start;
                        height: 100px;

                        .GeojiCnInnerGrayGroupPurchaseMiddleDate {
                            @include fontSizeNormal();
                            color: var(--darkColor);
                            font-weight: bold;
                            padding-top: 8px;
                        }

                        .GeojiCnInnerGrayGroupPurchaseMiddleDescription {
                            @include fontSizeNormal();
                            color: var(--grayColor);
                            padding-top: 4px;
                        }
                    }

                    .GeojiCnInnerGrayGroupPurchaseRight {
                        @include fontSizeNormal();
                        font-weight: bold;
                        color: var(--darkColor);
                        padding-top: 32px;
                        flex-shrink: 0;
                    }
                }
            }

            .GeojiCnInnerGrayGroupPurchasesShowMore {
                margin-top: 16px;
                width: 100%;
                //background: var(--grayColor);
                border-radius: 12px;
                height: 50px;
                line-height: 50px;
                @include fontSizeNormal();
                font-weight: bold;
                color: var(--grayColor);
                @include pushable();
            }

            .GeojiCnInnerGrayGroupButton {
                margin-top: 32px;
                width: 100%;
                background: var(--darkColor);
                border-radius: 12px;
                height: 50px;
                line-height: 50px;
                @include fontSizeNormal();
                font-weight: bold;
                color: var(--whiteColor);
                @include pushable();
            }
        }
    }

    .GeojiCnBottomBar {
        height: 100px;
        background: var(--offWhiteColor);
        background: -moz-linear-gradient(180deg, var(--offWhiteTransparentColor0) 0%, var(--offWhiteColor) 50%, var(--offWhiteColor) 100%);
        background: -webkit-linear-gradient(180deg, var(--offWhiteTransparentColor0) 0%, var(--offWhiteColor) 50%, var(--offWhiteColor) 100%);
        background: linear-gradient(180deg, var(--offWhiteTransparentColor0) 0%, var(--offWhiteColor) 50%, var(--offWhiteColor) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f6f6", endColorstr="#f6f6f6", GradientType=1);
        position: -webkit-sticky;
        position: sticky;
        bottom: 0px;
        border-radius: 0px 0px 32px 32px;
        z-index: 10;

        .GeojiCnBottomBarPoweredBy {
            position: absolute;
            right: 8px;
            bottom: 2px;

            img {
                width: 56px;
            }
        }

        .GeojiCnBottomBarButtonCart {
            background: var(--whiteColor);
            border-radius: 30px;
            @include materialSpreadShadow();
            @include pushable();
            @include noselect();
            width: 60px;
            height: 60px;
            margin: 0px auto;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            padding: 0px;
            overflow: hidden;
            position: relative;

            .GeojiCnBottomBarButtonCartImage {
                width: 24px;
                height: 24px;
                object-fit: contain;
                padding: 18px;
            }

            .GeojiCnBottomBarButtonCartLoading {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 60px;
                z-index: 100;
                @include grayLoading();
                @include fadeIn(0.5s);
            }
        }

        .GeojiCnBottomBarButtonCartCheckout {
            width: calc(100% - 96px);
            padding: 0px 24px 0px 8px;
            background: var(--greenColor);

            .GeojiCnBottomBarButtonCartImage {
                filter: brightness(0) invert(1);
            }

            .GeojiCnBottomBarButtonCartContent {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                flex-grow: 1;

                .GeojiCnBottomBarButtonCartTitle {
                    @include fontSizeMid();
                    font-size: 20px;
                    color: $whiteColor;
                    flex-shrink: 0;
                }

                .GeojiCnBottomBarButtonCartPrice {
                    @include fontSizeMid();
                    font-size: 20px;
                    color: $whiteColor;
                    z-index: 1;
                }
            }
        }
    }

    .GeojiCnInnerPrompt {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;

        @media only screen and (max-width: 414px) {
            border-radius: 0px 0px;
            min-height: calc(100% - 64px);
        }

        .GeojiCnInnerPromptTopBar {
            text-align: left;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            .GeojiCnInnerPromptTopBarBackArrow {
                padding-right: 67px;
                width: 13px;
                object-fit: contain;
                @include pushable();
                flex-shrink: 0;
            }

            .GeojiCnInnerPromptTopBarGeojiLogo {
                //padding-right: 67px;
                height: 32px;
                object-fit: contain;
                flex-grow: 1;
            }

            .GeojiCnInnerPromptTopBarRight {
                width: 13px;
                height: 10px;
                padding-left: 67px;
                flex-shrink: 0;
            }

            .GeojiCnInnerPromptTopBarTitle {
                @include fontSizeMedium();
                color: var(--darkColor);
                flex-grow: 1;
                text-align: center;
                flex-shrink: 1;
            }

            .GeojiCnInnerPromptTopBarSandbox {
                @include fontSizeSmall();
                color: var(--greenColor);
                background: var(--greenTransparentColor80);
                padding: 4px 12px;
                border-radius: 12px;
                flex-shrink: 0;
            }

            .GeojiCnInnerPromptTopBarSandboxEmpty {
                width: 80px;
                height: 20px;
                flex-shrink: 0;
            }
        }

        .GeojiCnInnerPromptTopBarMargin {
            margin-bottom: 22px;
        }

        .GeojiCnInnerPromptClient {
            .GeojiCnInnerPromptClientImage {
                height: 96px;
                width: 96px;
                object-fit: contain;
            }
            .GeojiCnInnerPromptClientName {
                @include fontSizeTitle();
                color: var(--darkColor);
                padding: 22px 0px 8px 0px;
            }
        }
        .GeojiCnInnerPromptAccess {
            @include fontSizeNormal();
            color: var(--darkColor);
            padding-bottom: 22px;
        }
        .GeojiCnInnerPromptAccessButtons {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            .GeojiCnInnerPromptAccessButton {
                @include fontSizeMedium();
                padding: 15px 16px;
                background-color: var(--offWhiteColor);
                border-radius: 8px;
                width: 50%;
                @include pushable();
                @include noselect();
            }
            .GeojiCnInnerPromptAccessButtonAllow {
                margin-left: 12px;
                background-color: var(--blueColor);
                color: var(--whiteColor);
            }
        }
        .GeojiCnInnerPromptAccessBottom {
            padding: 22px 0px;
            @include fontSizeSmall();
            font-weight: normal;
            color: var(--grayColor);
        }
        .GeojiCnInnerPromptAccessCopyright {
            @include fontSizeSmall();
            color: var(--grayColor);
        }

        .GeojiCnInnerPromptEmoji {
            font-size: 96px;
        }

        .GeojiCnInnerPromptTitle {
            @include fontSizeTitle();
            color: var(--darkColor);
            padding-bottom: 32px;
        }

        .GeojiCnInnerPromptField {}

        .GeojiCnInnerPromptError {
            @include fontSizeNormal();
            font-weight: 600;
            color: var(--redColor);
            padding-top: 16px;
        }

        .GeojiCnInnerPromptLoading {
            padding-bottom: 100px;

            .GeojiCnInnerPromptLoadingLogo {
                width: 100px;
                height: 100px;
                object-fit: contain;
                filter: brightness(0.9);
                @include pulse(2.4s);
            }
        }

        .GeojiCnInnerPromptDescription {
            color: var(--grayColor);
            padding-bottom: 64px;
            padding-top: 32px;

            .GeojiCnInnerPromptDescriptionLink {
                color: var(--darkColor);
                display: inline-block;
                @include pushable();
            }
        }

        .GeojiCnInnerPromptButton {
            background: var(--darkColor);
            width: 100%;
            border-radius: 12px;
            @include fontSizeStat();
            font-weight: 600;
            padding: 16px 0px;
            color: var(--whiteColor);
            @include pushable();
            @include noselect();
        }

        .GeojiCnInnerPromptButton2 {
            background: var(--greenColor);
            color: $whiteColor;
        }

        .GeojiCnInnerPromptButtonLoading {
            width: 100%;
            height: 60px;
            border-radius: 12px;
            @include grayLoading();
        }

        .GeojiCnInnerPurchaseTale {
            @include fontSizeNormal();
            color: var(--grayColor);
            text-align: center;
            padding-top: 32px;

            .GeojiCnInnerPurchaseTaleBold {
                color: var(--darkColor);
                font-weight: bold;
                padding: 4px 0px;
            }
        }

        .AppStoreBadge {
            display: inline-block;
            overflow: hidden;
            border-radius: 13px;
            width: 250px;
            height: 60px;
            margin-top: 16px;

            img {
                border-top-left-radius: 13px;
                border-top-right-radius: 13px;
                border-bottom-right-radius: 13px;
                border-bottom-left-radius: 13px;
                width: 100%;
                height: 100%;
            }
        }

        .GeojiCnInnerWhosGoing {
            padding-top: 16px;

            .GeojiCnInnerWhosGoingLoading {

                .GeojiCnInnerWhosGoingLoadingBar {
                    width: 100%;
                    height: 80px;
                    border-radius: 12px;
                    margin-top: 16px;
                    @include grayLoading();
                }
            }

            .GeojiCnInnerWhosGoingEmpty {
                .GeojiCnInnerWhosGoingEmptyTop {
                    @include fontSizeTitle();
                    font-size: 72px;
                    color: var(--darkColor);
                    padding-top: 16px;
                }

                .GeojiCnInnerWhosGoingEmptyMiddle {
                    @include fontSizeMedium();
                    color: var(--darkColor);
                    padding-top: 16px;
                }

                .GeojiCnInnerWhosGoingEmptyBottom {
                    @include fontSizeNormal();
                    color: var(--grayColor);
                    padding-top: 8px;
                }
            }

            .GeojiCnInnerWhosGoingList {
                padding-top: 16px;

                .GeojiCnInnerWhosGoingProfile {
                    padding-bottom: 16px;
                    width: 100%;
                    height: 80px;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    @include pushableSimple();

                    .GeojiCnInnerWhosGoingProfileInitials {
                        width: 80px;
                        height: 80px;
                        background: var(--offWhiteColor);
                        border-radius: 40px;
                        @include fontSizeMid();
                        line-height: 80px;
                        color: var(--grayColor);
                    }

                    .GeojiCnInnerWhosGoingProfileImage {
                        width: 80px;
                        height: 80px;
                        object-fit: cover;
                        border-radius: 40px;
                        background: var(--offWhiteColor);
                    }

                    .GeojiCnInnerWhosGoingProfileBody {
                        padding-left: 16px;
                        text-align: left;

                        .GeojiCnInnerWhosGoingProfileBodyName {
                            @include fontSizeMedium();
                            color: var(--darkColor);
                        }

                        .GeojiCnInnerWhosGoingProfileBodyInsta {
                            @include fontSizeSmall();
                            color: var(--grayColor);
                            padding-top: 4px;
                        }
                    }
                }
            }
        }

        .GeojiCnInnerKickback {
            padding-top: 16px;

            .GeojiCnInnerKickbackLoading {

                .GeojiCnInnerKickbackLoadingBar {
                    width: 100%;
                    height: 44px;
                    border-radius: 12px;
                    margin-top: 16px;
                    @include grayLoading();
                }
            }

            .GeojiCnInnerKickbackTitle {
                @include fontSizeMid();
                color: var(--darkColor);
                padding-bottom: 16px;
                padding-top: 32px;
            }

            .GeojiCnInnerKickbackBody {
                @include fontSizeNormal();
                color: var(--grayColor);
                padding-bottom: 16px;
            }

            .GeojiCnMakeMoneyButton {
                @include blueGreenGradient();
                padding: 12px 0px;
                margin-top: 16px;
                @include fontSizeMedium();
                color: $whiteColor;
                border-radius: 12px;
                @include pushable();

                img {
                    height: 14px;
                    margin-right: 8px;
                }
            }

            .GeojiCnMakeMoneyButtonCopied {
                background: var(--greenColor);
            }

            .GeojiCnInnerKickbackStat {
                @include fontSizeMedium();
                color: var(--darkColor);
                padding-top: 16px;
            }
        }

        .GeojiCnInnerRequestAccess {
            padding-top: 16px;

            .GeojiCnInnerRequestAccessLoading {

                .GeojiCnInnerRequestAccessLoadingBar {
                    width: 100%;
                    height: 80px;
                    border-radius: 12px;
                    margin-top: 16px;
                    @include grayLoading();
                }
            }

            .GeojiCnInnerRequestAccessButton {
                background: var(--blueColor);
                padding: 12px 0px;
                margin-bottom: 16px;
                @include fontSizeMedium();
                color: $whiteColor;
                border-radius: 12px;
                @include pushable();
                @include noselect();

                img {
                    height: 14px;
                    object-fit: contain;
                    margin-right: 8px;
                }
            }
        }

        .GeojiCnInnerPurchase {
            .GeojiCnInnerPurchaseNoAccessTitle {
                padding-top: 32px;
                @include fontSizeTitle();
                color: var(--darkColor);
            }

            .GeojiCnInnerPurchaseNoAccessBody {
                padding-top: 32px;
                @include fontSizeNormal();
                color: var(--grayColor);
            }

            .GeojiCnInnerPurchaseNoAccessID {
                padding-top: 32px;
                @include fontSizeMedium();
                color: var(--blueColor);
            }

            .GeojiCnInnerPurchaseBar {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 8px;

                .GeojiCnInnerPurchaseBarEmoji {
                    @include fontSizeNormal();
                    font-size: 40px;
                    background: var(--offWhiteColor);
                    border-radius: 12px;
                    width: 70px;
                    min-width: 70px;
                    height: 100px;
                    line-height: 100px;
                    border-radius: 12px;
                    margin-right: 10px;
                }

                .GeojiCnInnerPurchaseBarList {
                    flex-grow: 1;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding-top: 8px;

                    .GeojiCnInnerPurchaseBarDate {
                        text-align: left;
                        @include fontSizeSmall();
                        font-size: 14px;
                        font-weight: bold;
                        color: var(--grayColor);

                        .GeojiCnInnerPurchaseBarDateTime {
                            font-weight: normal;
                            color: var(--grayColor);
                            display: inline-block;
                            padding-left: 8px;
                        }
                    }

                    .GeojiCnInnerPurchaseBarItem {
                        flex-grow: 1;
                        width: 100%;
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        align-items: center;
                        @include fontSizeNormal();
                        color: var(--grayColor);
                        padding-top: 4px;

                        .GeojiCnInnerPurchaseBarItemQuantity {
                            font-weight: bold;
                            padding-right: 8px;
                            flex-shrink: 0;
                        }

                        .GeojiCnInnerPurchaseBarItemTitle {
                            text-align: left;
                            flex-grow: 1;
                        }

                        .GeojiCnInnerPurchaseBarItemPrice {
                            color: var(--darkColor);
                            font-weight: bold;
                            padding-left: 8px;
                            flex-shrink: 0;
                        }
                    }
                }

                .GeojiCnInnerPurchaseBarLoading {
                    width: 100%;
                    height: 20px;
                    border-radius: 12px;
                    margin-top: 8px;
                    @include grayLoading();
                }
            }

            .GeojiCnInnerPurchaseTotal {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                margin-top: 32px;

                .GeojiCnInnerPurchaseTotalTitle {
                    @include fontSizeTitle();
                    color: var(--darkColor);
                }

                .GeojiCnInnerPurchaseTotalValue {
                    @include fontSizeTitle();
                    color: var(--darkColor);
                }
            }

            .GeojiCnInnerPurchaseProfile {
                margin-top: 16px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;

                .GeojiCnInnerPurchaseProfileImageView {
                    flex-shrink: 0;
                    width: 70px;
                    height: 70px;
                    position: relative;
                    background: var(--offWhiteColor);
                    border-radius: 24px;
                    margin-right: 8px;
                    @include pushable();

                    .GeojiCnInnerPurchaseProfileImageViewImage {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 24px;
                    }

                    .GeojiCnInnerPurchaseProfileImageViewImageDefault {
                        margin-top: 25%;
                        width: 50%;
                        height: 50%;
                        object-fit: contain;
                    }

                    .GeojiCnInnerPurchaseProfileImageViewEdit {
                        position: absolute;
                        left: -3px;
                        bottom: -3px;
                        width: 21px;
                        height: 21px;
                        object-fit: contain;
                    }
                }

                .GeojiCnInnerPurchaseProfileRight {
                    flex-grow: 1;
                    text-align: left;
                }
            }

            .GeojiCnInnerPurchaseNotes {
                .GeojiCnInnerPurchaseNotesLoading {
                    width: 100%;
                    height: 44px;
                    border-radius: 12px;
                    margin-top: 34px;
                    margin-bottom: 27px;
                    @include grayLoading();
                }
            }

            .GeojiCnInnerPurchaseLocation {
                .GeojiCnInnerPurchaseLocationTitle {
                    @include fontSizeMedium();
                    color: var(--darkColor);
                    text-align: left;
                    padding: 16px 0px 8px 16px;
                }

                .GeojiCnInnerPurchaseLocationBody {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 16px;

                    .GeojiCnInnerPurchaseLocationBodyIcon {
                        height: 18px;
                        width: 18px;
                        object-fit: contain;
                        background: var(--offWhiteColor);
                        padding: 8px;
                        border-radius: 17px;
                        margin-right: 8px;
                    }

                    .GeojiCnInnerPurchaseLocationBodyText {
                        @include fontSizeNormal();
                        color: var(--grayColor);
                        text-align: left;
                        word-wrap: break-word;
                        text-decoration: none;
                        @include pushable();
                    }
                }
            }

            .GeojiCnInnerPurchaseWaivers {
                padding-bottom: 32px;

                .GeojiCnInnerPurchaseWaiversTitle {
                    @include fontSizeMid();
                    color: var(--darkColor);
                    text-align: left;
                    margin-top: 32px;
                }

                .GeojiCnInnerPurchaseWaiversW {
                    background: var(--offWhiteColor);
                    border-radius: 12px;
                    margin-top: 16px;
                    padding: 12px 16px;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    @include pushable();
                    @include noselect();

                    .GeojiCnInnerPurchaseWaiversWLeft {
                        flex-grow: 1;
                        text-align: left;

                        .GeojiCnInnerPurchaseWaiversWLeftTitle {
                            @include fontSizeNormal();
                            font-weight: 600;
                            color: var(--grayColor);
                            padding-bottom: 8px;
                        }

                        .GeojiCnInnerPurchaseWaiversWLeftBody {
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: center;
                            align-items: center;

                            .GeojiCnInnerPurchaseWaiversWLeftBodySignature {
                                flex-grow: 0;
                                margin-right: 8px;

                                img {
                                    width: 26px;
                                    object-fit: contain;
                                }
                            }

                            .GeojiCnInnerPurchaseWaiversWLeftBodyName {
                                flex-grow: 1;
                                @include fontSizeNormal();
                                color: var(--darkColor);
                                font-weight: bold;
                                font-style: italic;
                            }
                        }
                    }

                    .GeojiCnInnerPurchaseWaiversWRight {
                        flex-grow: 0;

                        img {
                            width: 12px;
                            object-fit: contain;
                        }
                    }
                }
            }

            .GeojiCnInnerPurchaseTicketNotes {
                padding-bottom: 16px;
                padding-top: 16px;
                @include fontSizeNormal();
                color: var(--grayColor);
                text-align: center;
            }

            .GeojiCnInnerPurchaseMassLoading {
                width: 100%;
                height: 68px;
                border-radius: 12px;
                margin-top: 32px;
                @include grayLoading();
            }

            .GeojiCnInnerPurchaseMassContainer {
                width: calc(100% + 64px);
                margin-left: -32px;

                .GeojiCnInnerPurchaseMassAd {
                    .GeojiCnInnerPurchaseMassAdBandText {
                        width: calc(100% - 32px);
                        padding: 16px 16px 0px 16px;
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: center;
                        align-items: center;

                        .GeojiCnInnerPurchaseMassAdBandTextL {
                            width: 24px;
                            height: 24px;
                            margin-right: 8px;
                            flex-shrink: 0;
                        }

                        .GeojiCnInnerPurchaseMassAdBandTextM {
                            flex-grow: 1;

                            .GeojiCnInnerPurchaseMassAdBandTextMT {
                                @include fontSizeMid();
                                color: var(--darkColor);
                            }

                            .GeojiCnInnerPurchaseMassAdBandTextMB {
                                @include fontSizeNormal();
                                color: var(--grayColor);
                            }
                        }

                        .GeojiCnInnerPurchaseMassAdBandTextR {
                            width: 24px;
                            height: 24px;
                            margin-left: 8px;
                            flex-shrink: 0;
                        }
                    }

                    .GeojiCnInnerPurchaseMassAdNumber {
                        margin-top: 16px;
                        @include fontSizeTitle();
                        color: var(--darkColor);
                        font-size: 64px;
                        line-height: 100px;
                        background: var(--offWhiteColor);
                        min-height: 100px;
                        display: inline-block;
                        min-width: 36px;
                        text-align: center;
                        border-radius: 50px;
                        padding: 0px 32px;
                    }
                }

                .GeojiCnInnerPurchaseMassBottom {
                    @include fontSizeNormal();
                    color: var(--grayColor);
                    margin-top: 16px;
                }
            }

            .GeojiCnInnerPurchaseMapContainer {
                width: 100%;

                .GeojiCnInnerGrayGroupTokenMap {
                    background: var(--whiteColor);
                    border-radius: 12px;
                    margin-top: 32px;

                    .GeojiCnInnerGrayGroupTokenMapGraphic {
                        border-radius: 12px 12px 0px 0px;
                        position: relative;
                        overflow: hidden;

                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: center;
                        align-items: center;

                        .GeojiCnInnerGrayGroupTokenMapGraphicImage {
                            width: 100%;
                            object-fit: contain;
                        }

                        .GeojiCnInnerGrayGroupTokenMapGraphicSeat {
                            left: 0px;
                            top: 0px;
                            position: absolute;
                            background: var(--grayLightColor);
                            width: 30px;
                            height: 30px;
                            z-index: 2;
                            border-radius: 4px;
                            @include pushable();
                            @include noselect();

                            display: flex;
                            flex-flow: column nowrap;
                            justify-content: center;
                            align-items: center;

                            .GeojiCnInnerGrayGroupTokenMapGraphicSeatText {
                                @include fontSizeMedium();
                                color: $whiteColor;
                                opacity: 0;
                            }

                            &:hover {
                                .GeojiCnInnerGrayGroupTokenMapGraphicSeatText {
                                    opacity: 1;
                                }
                            }
                        }

                        .GeojiCnInnerGrayGroupTokenMapGraphicSeatSelected {
                            background: var(--greenColor);

                            .GeojiCnInnerGrayGroupTokenMapGraphicSeatText {
                                opacity: 1;
                            }
                        }

                        .GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeCircle {
                            border-radius: 50%;
                        }

                        .GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeSquare {}

                        /*.GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeRect {
                                width: 60px;
                                margin-left: -15px;
                            }
                            .GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeVRect {
                                height: 60px;
                                margin-top: -15px;
                            }*/
                    }

                    .GeojiCnInnerGrayGroupTokenMapAbout {
                        @include fontSizeNormal();
                        color: var(--grayColor);
                        text-align: left;
                        padding: 4px 12px 0px 12px;
                    }
                }
            }

            .GeojiCnInnerPurchaseQRCodesContainer {
                margin: 16px -32px 0px -32px;

                .GeojiCnInnerPurchaseQRCodesList {
                    overflow: auto;
                    white-space: nowrap;
                    padding: 0px 16px 0px 32px;

                    .GeojiCnInnerPurchaseQRCode {
                        background: var(--offWhiteColor);
                        border-radius: 32px;
                        padding: 16px;
                        display: inline-block;
                        margin-right: 16px;
                        width: calc(100% - 48px);

                        .GeojiCnInnerPurchaseQRCodeInner {
                            padding: 16px;
                            border-radius: 32px;
                            background: var(--whiteColor);

                            .GeojiCnInnerPurchaseQRCodeInnerTop {
                                padding-bottom: 8px;
                                text-align: center;

                                img {
                                    height: 32px;
                                }
                            }

                            .GeojiCnInnerPurchaseQRCodeInnerImage {
                                margin: 0px auto;
                            }

                            .GeojiCnInnerPurchaseQRCodeLabel {
                                @include fontSizeMid();
                                padding-top: 16px;
                                display: block;
                                width: 100%;
                                white-space: normal;
                            }

                            .GeojiCnInnerPurchaseQRCodeDetailsLabel {
                                @include fontSizeNormal();
                                display: block;
                                width: 100%;
                                white-space: normal;
                                height: 20px;
                            }

                            .GeojiCnInnerPurchaseQRCodeQuantity {
                                background: var(--offWhiteColor);
                                color: var(--darkColor);
                                display: inline-block;
                                padding: 13px 32px;
                                min-width: 36px;
                                border-radius: 50px;
                                @include fontSizeTitle();
                                font-size: 64px;
                                margin: 8px 0px 0px 0px;
                            }
                        }
                    }
                }

                .GeojiCnInnerPurchaseQRCodesDots {
                    padding-top: 16px;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;

                    .GeojiCnInnerPurchaseQRCodesDot {
                        background: var(--grayLineColor);
                        border-radius: 4px;
                        width: 8px;
                        height: 8px;
                        margin: 0px 5px;
                        transition: all 300ms;
                    }

                    .GeojiCnInnerPurchaseQRCodesDotSelected {
                        background: var(--darkColor);
                    }
                }
            }

            .GeojiCnInnerPurchaseTicketBar {
                position: relative;
                left: -32px;
                width: calc(100% + 64px);
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                z-index: 2;
                padding-top: 32px;

                .GeojiCnInnerPurchaseTicketBarLeft {
                    width: 15px;
                    height: 30px;
                    flex-grow: 0;
                    border-radius: 0px 15px 15px 0px;
                    background: var(--darkColor);
                }

                .GeojiCnInnerPurchaseTicketBarMiddle {
                    height: 1px;
                    flex-grow: 1;
                    border-bottom: 2px dashed var(--darkColor);
                    margin: 0px 16px;
                }

                .GeojiCnInnerPurchaseTicketBarRight {
                    width: 15px;
                    height: 30px;
                    flex-grow: 0;
                    border-radius: 15px 0px 0px 15px;
                    background: var(--darkColor);
                }
            }

            .GeojiCnInnerPurchaseTicketImage {
                width: 200px;
                padding-top: 16px;
            }

            .GeojiCnInnerPurchaseLoading {
                width: 100%;
                height: 50px;
                border-radius: 12px;
                margin-top: 32px;
                @include grayLoading();
            }

            .GeojiCnInnerPurchaseSaveButton {
                width: 100%;
                height: 50px;
                line-height: 50px;
                background: var(--darkColor);
                border-radius: 12px;
                @include fontSizeNormal();
                font-weight: bold;
                color: var(--whiteColor);
                margin-top: 32px;
                @include pushable();
            }

            .GeojiCnInnerPurchaseSaveButtonGray {
                background: var(--grayLightColor);
                color: var(--whiteColor);
            }

            .GeojiCnInnerPurchaseSaveButtonBlue {
                background: var(--blueColor);
                color: var(--whiteColor);
            }

            .GeojiCnInnerPurchaseField {
                .InputDiv {
                    .inputBottomLineLightContainer {
                        .inputBottomLineTop {
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: space-between;
                            align-items: center;

                            .inputBottomLineTitle {
                                @include fontSizeNormal();
                                font-weight: bold;
                                padding-left: 16px;
                                padding-bottom: 4px;

                                .inputBottomLineTitleEmoji {
                                    padding-left: 8px;
                                    font-size: 12px;
                                    vertical-align: middle;
                                }
                            }

                            .inputBottomLineDescription {
                                @include fontSizeSmall();
                                color: var(--grayColor);
                            }
                        }

                        .inputBottomLineLightFlexBox {
                            .inputBottomLineLight {
                                height: 26px;
                            }
                        }
                    }
                }
            }

            .GeojiCnInnerPurchaseKeepYourTickets {
                @include fontSizeNormal();
                color: var(--grayColor);
                text-align: center;
                padding-top: 16px;
            }
        }

        .GeojiCnInnerPurchaseEmail {
            padding-top: 32px;

            .GeojiCnInnerPurchaseField {
                .InputDiv {
                    .inputBottomLineLightContainer {
                        .inputBottomLineTop {
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: space-between;
                            align-items: center;

                            .inputBottomLineTitle {
                                @include fontSizeNormal();
                                font-weight: bold;
                                padding-left: 16px;
                                padding-bottom: 4px;

                                .inputBottomLineTitleEmoji {
                                    padding-left: 8px;
                                    font-size: 12px;
                                    vertical-align: middle;
                                }
                            }

                            .inputBottomLineDescription {
                                @include fontSizeSmall();
                                color: var(--grayColor);
                            }
                        }

                        .inputBottomLineLightFlexBox {
                            .inputBottomLineLight {
                                height: 26px;
                            }
                        }
                    }
                }
            }

            .GeojiCnInnerPurchaseEmailButton {
                margin-top: 32px;
                padding: 16px 0px;
                width: 100%;
                background: var(--greenColor);
                border-radius: 12px;
                @include pushable();
                @include noselect();

                @include fontSizeMedium();
                color: var(--whiteColor);
            }

            .GeojiCnInnerPurchaseEmailButtonLoading {
                margin-top: 32px;
                width: 100%;
                height: 50px;
                border-radius: 12px;
                @include grayLoading();
            }
        }
    }

    .GeojiCnInnerPromptWaiver {
        background: var(--offWhiteColor);
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: stretch;
        height: calc(100% - 100px);

        .GeojiCnInnerWaiver {
            margin-top: 22px;
            overflow-y: scroll;
            flex-grow: 1;
            flex-shrink: 1;

            .GeojiCnInnerWaiverTitle {
                @include fontSizeMid();
                color: var(--darkColor);
                padding: 0px 0px 16px 0px;
            }

            .GeojiCnInnerWaiverBody {
                @include fontSizeNormal();
                color: var(--grayColor);
                text-align: left;
                padding-bottom: 16px;
                white-space: pre-line;
            }

            .GeojiCnInnerWaiverSignage {
                background: var(--whiteColor);
                border-radius: 12px;
                margin-top: 16px;
                padding: 12px 16px;
                @include noselect();

                .GeojiCnInnerWaiverSignageTop {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 8px;

                    .GeojiCnInnerWaiverSignageTopDate {
                        @include fontSizeNormal();
                        font-weight: bold;
                        color: var(--grayColor);
                    }

                    .GeojiCnInnerWaiverSignageTopTime {
                        @include fontSizeNormal();
                        font-weight: bold;
                        color: var(--grayColor);
                    }
                }

                .GeojiCnInnerWaiverSignageBottom {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: left;

                    .GeojiCnInnerWaiverSignageBottomSignature {
                        flex-grow: 0;
                        margin-right: 8px;

                        img {
                            width: 26px;
                            object-fit: contain;
                        }
                    }

                    .GeojiCnInnerWaiverSignageBottomName {
                        flex-grow: 1;
                        @include fontSizeNormal();
                        color: var(--darkColor);
                        font-weight: bold;
                        font-style: italic;
                    }
                }
            }
        }

        .GeojiCnInnerWaiverBottom {
            background: var(--whiteColor);
            margin: 0px -32px -32px -32px;
            flex-shrink: 0;
            border-radius: 32px;
            padding: 32px;

            @media only screen and (max-width: 414px) {
                border-radius: 32px 32px 0px 0px;
            }

            .GeojiCnInnerWaiverBottomTitle {
                @include fontSizeMid();
                color: var(--darkColor);
                padding-bottom: 16px;
            }

            .GeojiCnInnerWaiverBottomForm {}

            .GeojiCnInnerWaiverBottomText {
                @include fontSizeSmall();
                font-weight: normal;
                color: var(--grayColor);
                text-align: left;
                padding-bottom: 16px;
            }

            .GeojiCnInnerWaiverBottomTextError {
                @include fontSizeMedium();
                color: var(--redColor);
                padding-bottom: 8px;
                padding-top: 8px;
            }

            .GeojiCnInnerWaiverBottomButton {}
        }
    }

    .GeojiCnCheckout {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;
        position: relative;

        @media only screen and (max-width: 414px) {
            border-radius: 0px;
            min-height: calc(100% - 64px);
        }

        .GeojiCnCheckoutCover {
            position: absolute;
            width: 100%;
            background: var(--whiteTransparentColor10);
            height: 100%;
            margin: -32px 0px 0px -32px;
            border-radius: 36px;
            z-index: 1000;

            .GeojiCnCheckoutCoverLoading {
                height: 100%;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;

                .GeojiLoadingLogo {
                    width: 100px;
                    @include pulse(2.4s);
                }
            }
        }

        .GeojiCnCheckoutTopBar {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            .GeojiCnCheckoutTopBarBack {
                padding: 10px 10px 10px 0px;

                .GeojiCnCheckoutTopBarBackArrow {
                    padding-right: 40px;
                    width: 13px;
                    object-fit: contain;
                    @include pushable();
                }
            }

            .GeojiCnCheckoutTopBarTitle {
                @include fontSizeNormal();
                font-weight: bold;
                color: var(--darkColor);
            }

            .GeojiCnCheckoutTopBarSandbox {
                @include fontSizeSmall();
                color: var(--greenColor);
                background: var(--greenTransparentColor80);
                padding: 4px 12px;
                border-radius: 12px;
                visibility: hidden;
            }

            .GeojiCnCheckoutTopBarSandboxVisible {
                visibility: visible;
            }
        }

        .GeojiCnCheckoutTitle {
            @include fontSizeMid();
            color: var(--darkColor);
            padding: 32px 0px 16px 0px;
            text-align: left;
        }

        .GeojiCnCheckoutPaymentMethod {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            .GeojiCnCheckoutPaymentMethodIconImage {
                width: 24px;
                height: 20px;
                object-fit: contain;
                margin: 0px 8px 0px 8px;
            }

            .GeojiCnCheckoutPaymentMethodLabel {
                @include fontSizeNormal();
                color: var(--darkColor);
            }
        }

        .PaymentBoxOr {
            @include fontSizeNormal();
            color: var(--grayColor);
            padding: 0px 0px 16px 0px;
        }

        .PaymentBoxSpacer {
            height: 16px;
        }

        .PaymentBox {
            width: 100%;
            height: 60px;
            border-radius: 12px;
            margin: 0px 0px 16px 0px;
            @include pushable();
            @include noselect();
        }

        #AmazonPayBox {
            background: var(--amazonColor);
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            margin-bottom: 4px;

            .AmazonPayBoxImage {
                height: 24px;
                object-fit: contain;
                padding-top: 8px;
            }
        }

        #AmazonPayBoxBottomText {
            @include fontSizeNormal();
            color: var(--grayColor);
            margin-bottom: 16px;
        }

        .paypal-buttons {
            margin-bottom: 18px;
        }

        #OSPayBoxApple,
        #OSPayBoxGoogle {
            background: var(--darkColor);
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            .OSPayBoxText {
                @include fontSizeNormal();
                font-weight: bold;
                font-size: 20px;
                color: var(--whiteColor);
                padding-right: 4px;
                padding-bottom: 2px;
            }

            .OSPayBoxImage {
                height: 20px;
                object-fit: contain;
            }
        }

        .PayBoxLoading {

            @include grayLoading();
        }

        .GeojiCnCheckoutLoading {
            height: 60px;
            width: 100%;
            border-radius: 12px;
            @include grayLoading();
        }

        .GeojiCnCheckoutEdit {
            @include fontSizeNormal();
            font-weight: 600;
            color: var(--greenColor);
            margin-top: 16px;
            @include pushable();
            @include noselect();
            display: inline-block;
            background: none;
            padding: 4px 12px;
            border-radius: 12px;
            @include fadeIn();

            &:hover {
                background: var(--greenTransparentColor80);
            }
        }

        .GeojiCnCheckoutError {
            @include fontSizeNormal();
            font-weight: 600;
            color: var(--redColor);
            padding-top: 16px;
        }

        .GeojiCnCheckoutStripeContainer {
            padding: 16px 16px;
            margin-top: 8px;
            width: calc(100% - 36px);
            background: var(--whiteColor);
            border: 2px solid var(--grayLineColor);
            border-radius: 16px;
            position: relative;

            .GeojiCnCheckoutLoadingTop {
                height: 56px;
                width: calc(100% + 4px);
                border-radius: 12px;
                @include grayLoading();
                position: absolute;
                left: -2px;
                top: -2px;
                z-index: 100;
            }

            .GeojiCnCheckoutStripeContainerCard {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                margin-top: -3px;
                margin-bottom: -4px;

                .GeojiCnCheckoutStripeContainerCardLeft {
                    .GeojiCnCheckoutStripeContainerCardLeftTitle {
                        @include fontSizeTiny();
                        color: var(--grayColor);
                        text-align: left;
                    }

                    .GeojiCnCheckoutStripeContainerCardLeftNumber {
                        @include fontSizeSmall();
                        color: var(--darkColor);
                        text-align: left;
                    }
                }

                .GeojiCnCheckoutStripeContainerCardRight {
                    .GeojiCnCheckoutStripeContainerCardRightTitle {
                        @include fontSizeTiny();
                        color: var(--darkColor);
                        text-align: right;
                    }

                    .GeojiCnCheckoutStripeContainerCardRightDate {
                        @include fontSizeSmall();
                        color: var(--darkColor);
                        text-align: right;
                    }
                }
            }
        }

        .GeojiCnCheckoutTotal {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 60px 0px 32px 0px;

            .GeojiCnCheckoutTotalLabel {
                @include fontSizeTitle();
                font-weight: normal;
                color: var(--darkColor);
            }

            .GeojiCnCheckoutTotalValue {
                @include fontSizeTitle();
                color: var(--darkColor);
            }
        }

        .GeojiCnCheckoutTotalShort {
            padding-top: 16px;
        }

        .GeojiCnCheckoutButton {
            background: var(--darkColor);
            width: 100%;
            border-radius: 12px;
            @include fontSizeStat();
            font-weight: 600;
            padding: 16px 0px;
            color: var(--whiteColor);
            @include pushable();
            @include noselect();
            @include fadeIn();
        }
    }

    .GeojiCnAdminDashboard {
        width: calc(100%);
        background: var(--dashboardColor);
        border-radius: 36px 36px;
        padding: 32px 0px 0px 0px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 32px);
        }

        .GeojiCnAdminTopBar {
            padding: 0px 32px;

            .GeojiCnAdminTopBarTitle {
                color: var(--whiteColor);
            }

            .GeojiCnAdminTopBarSandbox {
                color: var(--dashboardColor2);
                background: var(--dashboardColor2Transparent80);
            }
        }

        .GeojiCnAdminRevenue {
            padding: 32px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;

            .GeojiCnAdminRevenueLeft {
                flex-grow: 1;
                text-align: left;

                .GeojiCnAdminRevenueTitle {
                    @include fontSizeMid();
                    font-weight: normal;
                    color: var(--dashboardColor2);
                    padding-bottom: 8px;
                }

                .GeojiCnAdminRevenueTotal {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: flex-end;
                    justify-content: flex-start;
                    color: var(--whiteColor);
                    padding-bottom: 8px;

                    .GeojiCnAdminRevenueTotalDollar {
                        @include fontSizeTitle();
                    }

                    .GeojiCnAdminRevenueTotalCents {
                        @include fontSizeMid();
                        padding-bottom: 2px;
                    }

                    .GeojiCnAdminRevenueTotalIcon {
                        width: 34px;
                        height: 34px;
                        object-fit: contain;
                        margin-left: 8px;
                        margin-bottom: 7px;
                        @include pushable();
                    }
                }

                .GeojiCnAdminRevenueToday {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: flex-end;
                    justify-content: flex-start;
                    color: var(--dashboardColor2);

                    .GeojiCnAdminRevenueTodayDollar {
                        @include fontSizeMid();
                    }

                    .GeojiCnAdminRevenueTodayCents {
                        @include fontSizeMedium();
                        padding-bottom: 2px;
                    }
                }
            }

            .GeojiCnAdminRevenueEdit {
                width: 72px;
                border: 4px solid $whiteColor;
                border-radius: 40px;
                object-fit: contain;
                @include pushable();
                @include fontSizeMid();
                color: var(--whiteColor);
                padding: 23px 0px;
            }

            .GeojiCnAdminRevenueQRCode {
                width: 72px;
                height: 72px;
                padding: 6px;
                background: white;
                border-radius: 12px;
                object-fit: contain;
                @include pushable();
            }
        }

        .GeojiCnAdminWhite {
            background: var(--whiteColor);
            padding: 0px 32px 32px 32px;
            border-radius: 32px;
            position: relative;

            @media only screen and (max-width: $adminMaxWidth) {
                border-radius: 32px 32px 0px 0px;
            }

            .GeojiCnAdminWhiteC {
                padding-top: 32px;

                .GeojiCnAdminWhiteCHeader {
                    @include fontSizeMedium();
                    color: var(--darkColor);
                    text-align: left;
                    padding-bottom: 16px;
                }
            }

            .GeojiCnAdminWhiteTokens {
                text-align: left;
                white-space: nowrap;
                overflow-x: scroll;
                width: calc(100% + 16px);
                margin-left: -32px;
                padding-left: 16px;
                padding-right: 32px;

                .GeojiCnAdminWhiteToken {
                    background: var(--offWhiteColor);
                    display: inline-block;
                    width: 150px;
                    height: 244px;
                    margin-left: 16px;
                    padding: 16px;
                    border-radius: 24px;
                    white-space: normal;
                    @include pushable();
                    vertical-align: top;

                    .GeojiCnAdminWhiteTokenTop {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-items: center;

                        .GeojiCnAdminWhiteTokenTopBubbleFull {
                            width: 50px;
                            height: 50px;
                            object-fit: contain;
                        }

                        .GeojiCnAdminWhiteTokenTopBubble {
                            width: 50px;
                            height: 50px;
                            background: white;
                            border-radius: 25px;
                            position: relative;


                            .GeojiCnAdminWhiteTokenTopBubbleImage {
                                z-index: 10;
                                position: absolute;
                                height: 30px;
                                width: 20px;
                                object-fit: contain;
                                left: 15px;
                                top: 10px;
                            }

                            .GeojiCnAdminWhiteTokenTopBubbleOutline {
                                position: absolute;
                                width: calc(100% - 4px);
                                height: calc(100% - 4px);
                                border-radius: 25px;
                                border: 2px solid var(--darkColor);
                            }
                        }

                        .GeojiCnAdminWhiteTokenTopPercent {
                            @include fontSizeMid();
                            color: var(--darkColor);
                        }
                    }

                    .GeojiCnAdminWhiteTokenTitle {
                        @include fontSizeMedium();
                        color: var(--darkColor);
                        padding-top: 10px;
                        height: 50px;
                        line-height: 25px;
                        margin-bottom: 16px;

                        //multi-line ellipsis
                        @include multiLineEllipsis(2);
                    }

                    .GeojiCnAdminWhiteTokenRevenue {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        align-items: flex-end;
                        color: var(--darkTransparentColor30);

                        .GeojiCnAdminWhiteTokenRevenueDollars {
                            @include fontSizeMedium();
                        }

                        .GeojiCnAdminWhiteTokenRevenueCents {
                            @include fontSizeSmall();
                            font-weight: bold;
                            padding-bottom: 1px;
                        }
                    }

                    .GeojiCnAdminWhiteTokenLine {
                        width: 100%;
                        background: var(--darkTransparentColor30);
                        height: 1px;
                        margin: 16px 0px;
                    }

                    .GeojiCnAdminWhiteTokenSoldTitle {
                        color: var(--darkTransparentColor30);
                        @include fontSizeMedium();
                        padding-bottom: 16px;
                    }

                    .GeojiCnAdminWhiteTokenSoldValue {
                        color: var(--darkTransparentColor30);
                        @include fontSizeMedium();
                    }
                }

                .GeojiCnAdminWhiteTokenAll {
                    background: var(--dashboardColor);

                    .GeojiCnAdminWhiteTokenTop {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-items: center;

                        .GeojiCnAdminWhiteTokenTopBubbleFull {
                            width: 20px;
                            height: 30px;
                            padding: 10px 15px 10px 15px;
                            object-fit: contain;
                        }
                    }

                    .GeojiCnAdminWhiteTokenTitle {
                        @include fontSizeMedium();
                        color: var(--whiteColor);
                        padding-top: 10px;
                        height: 50px;
                        line-height: 25px;
                        margin-bottom: 16px;

                        //multi-line ellipsis
                        @include multiLineEllipsis(2);
                    }

                    .GeojiCnAdminWhiteTokenLine {
                        width: 100%;
                        background: var(--darkTransparentColor30);
                        height: 1px;
                        margin: 52px 0px 16px 0px;
                    }

                    .GeojiCnAdminWhiteTokenSoldTitle {
                        color: var(--whiteColor);
                        @include fontSizeMedium();
                        padding-bottom: 16px;
                    }
                }
            }

            .GeojiCnAdminWhiteRedemptions {
                text-align: left;
                white-space: nowrap;
                overflow-x: scroll;
                width: calc(100% + 16px);
                margin-left: -32px;
                padding-left: 16px;
                padding-right: 32px;

                .GeojiCnAdminWhiteRedemption {
                    display: inline-block;
                    width: 120px;
                    padding: 16px;
                    background: var(--offWhiteColor);
                    border-radius: 24px;
                    white-space: normal;
                    margin-left: 16px;
                    @include pushable();
                    @include noselect();

                    .GeojiCnAdminWhiteRedemptionTop {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-items: center;

                        .GeojiCnAdminWhiteRedemptionName {
                            @include fontSizeMedium();
                            color: var(--whiteColor);
                        }

                        img {
                            height: 20px;
                            object-fit: contain;
                        }
                    }

                    .GeojiCnAdminWhiteRedemptionBody {
                        @include fontSizeSmall();
                        color: var(--whiteColor);
                        padding-top: 4px;
                    }
                }

                .GeojiCnAdminWhiteRedemptionVirtualTerminal {
                    background: var(--tokenColorPink);
                }

                .GeojiCnAdminWhiteRedemptionAZ {
                    background: var(--tokenColorGreen);
                }

                .GeojiCnAdminWhiteRedemptionQueue {
                    background: var(--tokenColorOrange);
                }

                .GeojiCnAdminWhiteRedemptionFireMarshal {
                    background: var(--offWhiteColor);

                    .GeojiCnAdminWhiteRedemptionTop {
                        .GeojiCnAdminWhiteRedemptionName {
                            color: var(--darkColor);
                        }

                        img {}
                    }

                    .GeojiCnAdminWhiteRedemptionBody {
                        color: var(--darkColor);
                    }
                }
            }

            .GeojiCnAdminWhiteTips {
                text-align: left;
                white-space: nowrap;
                overflow-x: scroll;
                overflow-y: hidden;
                width: calc(100% + 16px);
                margin-left: -32px;
                padding-left: 16px;
                padding-right: 32px;

                .GeojiCnAdminWhiteTip {
                    display: inline-block;
                    vertical-align: top;
                    width: 168px;
                    height: 228px;
                    padding: 16px;
                    background: var(--offWhiteColor);
                    border-radius: 24px;
                    white-space: normal;
                    margin-left: 16px;
                    @include pushable();
                    @include noselect();
                    position: relative;

                    .GeojiCnAdminWhiteTipTR {
                        @include fontSizeTitle();
                        font-size: 144px;
                        line-height: 150px;
                        color: var(--whiteTransparentColor90);
                        position: absolute;
                        right: 0px;
                        top: -75px;
                    }

                    .GeojiCnAdminWhiteTipBL {
                        @include fontSizeTitle();
                        font-size: 144px;
                        line-height: 150px;
                        color: var(--whiteTransparentColor90);
                        position: absolute;
                        left: 0px;
                        bottom: -75px;
                    }

                    .GeojiCnAdminWhiteTipTitle {
                        @include fontSizeMedium();
                        color: var(--whiteColor);
                        padding-bottom: 8px;
                    }

                    .GeojiCnAdminWhiteTipBody {
                        @include fontSizeNormal();
                        color: var(--offWhiteColor);
                    }

                    .GeojiCnAdminWhiteTipBottom {}
                }
            }

            .GeojiCnAdminWhiteAction {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                //@include pushable();
                @include pushableSimple();
                @include noselect();
                padding: 16px;
                border-radius: 16px;
                background: var(--offWhiteColor);

                img {
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                }

                .GeojiCnAdminWhiteActionRight {
                    text-align: left;
                    padding-left: 16px;

                    .GeojiCnAdminWhiteActionRightTitle {
                        @include fontSizeMedium();
                        color: var(--whiteColor);
                        padding-bottom: 4px;
                    }

                    .GeojiCnAdminWhiteActionRightBody {
                        @include fontSizeNormal();
                        color: var(--whiteColor);
                    }
                }
            }

            .GeojiCnAdminWhiteActionCopy {
                margin-top: 16px;
                background: var(--blueColor);
            }

            .GeojiCnAdminWhiteActionCopying {
                margin-top: 16px;
                background: var(--dashboardColor);
            }

            .GeojiCnAdminWhiteActionDownload {
                margin-top: 16px;
                background: #6DC887;
            }

            .GeojiCnAdminWhiteActionDownloading {
                margin-top: 16px;
                background: var(--greenColor);
            }

            .GeojiCnAdminWhiteActionFreeTokens {
                background: var(--blueColor);
            }

            .GeojiCnAdminWhiteActionPromo {
                margin-top: 16px;
                background: var(--tokenColorPink);
            }

            .GeojiCnAdminWhiteActionEditGeoji {
                margin-top: 16px;
                background: var(--tokenColorRed);
            }

            .GeojiCnAdminWhiteActionSettings {
                margin-top: 16px;
                background: var(--grayColor);
            }

            .GeojiCnAdminWhiteActionQRCode {
                margin-top: 16px;
                background: var(--offWhiteColor);

                .GeojiCnAdminWhiteActionRight {
                    .GeojiCnAdminWhiteActionRightTitle {
                        color: var(--darkColor);
                    }

                    .GeojiCnAdminWhiteActionRightBody {
                        color: var(--darkColor);
                    }
                }
            }

            .GeojiCnAdminWhiteTeamMembers {
                margin-top: -16px;

                .GeojiCnAdminWhiteTeamMember {
                    margin-top: 16px;

                    .GeojiCnAdminWhiteTeamMemberInner {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        align-items: center;

                        .GeojiCnAdminWhiteTeamMemberImage {
                            width: 70px;
                            height: 70px;
                            border-radius: 25px;
                            overflow: hidden;
                            background: var(--offWhiteColor);
                            flex-grow: 0;
                            flex-shrink: 0;

                            img {
                                width: 70px;
                                height: 70px;
                                object-fit: contain;
                            }

                            .dualImageError {
                                width: 35px;
                                height: 35px;
                                margin-top: 17px;
                            }

                            .defaultImage {
                                width: 35px;
                                height: 35px;
                                margin-top: 17px;
                                object-fit: contain;
                            }
                        }

                        .GeojiCnAdminWhiteTeamMemberContent {
                            padding-left: 8px;
                            text-align: left;
                            flex-grow: 1;
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: center;
                            align-items: center;

                            .GeojiCnAdminWhiteTeamMemberContentLeft {
                                flex-grow: 1;
                                .GeojiCnAdminWhiteTeamMemberName {
                                    @include fontSizeMedium();
                                    color: var(--darkColor);
                                    padding-bottom: 4px;
                                }

                                .GeojiCnAdminWhiteTeamMemberRole {
                                    display: flex;
                                    flex-flow: row nowrap;
                                    justify-content: flex-start;
                                    align-items: center;

                                    .GeojiCnAdminWhiteTeamMemberRoleLeft {
                                        @include fontSizeNormal();
                                        color: var(--grayColor);
                                        padding-right: 32px;
                                    }
                                    .GeojiCnAdminWhiteTeamMemberRoleRight {
                                        @include fontSizeNormal();
                                        font-weight: bold;
                                        color: var(--greenColor);
                                    }
                                    .GeojiCnAdminWhiteTeamMemberRoleEdit {
                                        @include fontSizeMedium();
                                        color: var(--darkColor);
                                        display: flex;
                                        flex-flow: row nowrap;
                                        justify-content: center;
                                        align-items: center;
                                        margin-left: 16px;
                                        @include noselect();
                                        background-color: var(--offWhiteColor);
                                        border-radius: 12px;

                                        .GeojiCnAdminWhiteTeamMemberRoleEditMinus {
                                            padding: 8px 18px;
                                            @include pushableSimple();
                                        }
                                        .GeojiCnAdminWhiteTeamMemberRoleEditLine {
                                            width: 1px;
                                            height: 18px;
                                            background-color: var(--grayLineColor);
                                        }
                                        .GeojiCnAdminWhiteTeamMemberRoleEditPlus {
                                            padding: 8px 18px;
                                            @include pushableSimple();
                                        }
                                    }
                                }
                            }
                            .GeojiCnAdminWhiteTeamMemberContentRight {
                                .defaultImage {
                                    width: 32px;
                                    height: 32px;
                                    object-fit: contain;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .GeojiCnAdminWhiteTeamMemberLine {
                        margin-left: 70px;
                        width: calc(100% - 70px);
                        height: 1px;
                        background: var(--offWhiteColor);
                    }
                }

                .GeojiCnAdminWhiteTeamMembersInvite {
                    @include fontSizeMedium();
                    color: var(--darkColor);
                    background: var(--offWhiteColor);
                    border-radius: 16px;
                    padding: 16px;
                    margin-top: 16px;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    @include pushableSimple();
                    @include noselect();

                    img {
                        margin-right: 8px;
                        width: 20px;
                    }
                }
            }
        }
    }

    .GeojiCnAdminQRCode {
        width: calc(100%);
        background: var(--dashboardColor);
        border-radius: 36px 36px;
        padding: 32px 0px 0px 0px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 32px);
        }

        .GeojiCnAdminTopBar {
            padding: 0px 32px;

            .GeojiCnAdminTopBarTitle {
                color: var(--whiteColor);
            }

            .GeojiCnAdminTopBarSandbox {
                color: var(--dashboardColor2);
                background: var(--dashboardColor2Transparent80);
            }
        }

        .GeojiCnAdminQRCodeTitle {
            @include fontSizeTitle();
            color: var(--whiteColor);
            padding-top: 32px;
            padding-bottom: 32px;
        }

        .GeojiCnAdminQRCodeImage {
            width: 236px;
            height: 236px;
            border-radius: 50%;
            background: var(--whiteColor);
            margin: 0px auto;
            padding: 20px;

            .GeojiCnAdminQRCodeImageInner {
                width: 152px;
                height: 152px;
                border-radius: 50%;
                border: 2px solid var(--darkColor);
                padding: 40px;
            }
        }

        .GeojiCnAdminQRCodeActions {
            padding: 32px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            .GeojiCnAdminQRCodeAction {
                width: 60px;
                height: 60px;
                background: var(--whiteColor);
                border-radius: 50%;
                @include pushable();
                @include materialSmallShadow();

                .GeojiCnAdminQRCodeActionImage {
                    width: 24px;
                    height: 24px;
                    margin-top: 18px;
                    object-fit: contain;
                }
            }
        }
    }

    .GeojiCnAdminTokenDetails {
        width: calc(100%);
        background: var(--dashboardColor);
        border-radius: 36px 36px;
        padding: 32px 0px 0px 0px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 32px);
        }

        .GeojiCnAdminTopBar {
            padding: 0px 32px;

            .GeojiCnAdminTopBarTitle {
                color: var(--whiteColor);
            }

            .GeojiCnAdminTopBarSandbox {
                color: var(--dashboardColor2);
                background: var(--dashboardColor2Transparent80);
            }
        }

        .GeojiCnAdminTokenDetailsTop {
            padding: 32px;
            text-align: left;

            .GeojiCnAdminTokenDetailsTopContent {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: flex-start;

                .GeojiCnAdminTokenDetailsTopLeft {
                    .GeojiCnAdminTokenDetailsTopName {
                        @include fontSizeMid();
                        font-weight: normal;
                        color: var(--dashboardColor2);
                        padding-bottom: 8px;
                    }

                    .GeojiCnAdminTokenDetailsTopRMoney {
                        @include fontSizeTitle();
                        color: var(--whiteColor);
                        padding-bottom: 8px;

                        span {
                            @include fontSizeMid();
                        }
                    }

                    .GeojiCnAdminTokenDetailsTopRSales {
                        @include fontSizeMid();
                        color: var(--dashboardColor2);
                    }
                }

                .GeojiCnAdminTokenDetailsTopRight {
                    .GeojiCnAdminTokenDetailsTopName {
                        @include fontSizeMid();
                        font-weight: normal;
                        color: var(--greenColor);
                        padding-bottom: 8px;
                    }

                    .GeojiCnAdminTokenDetailsTopRMoney {
                        @include fontSizeMid();
                        color: var(--greenColor);
                        padding-bottom: 8px;

                        span {
                            @include fontSizeMedium();
                        }
                    }
                }
            }

            .GeojiCnAdminTokenDetailsTopDateSelector {
                display: inline-block;
                background: var(--dashboardColor2Transparent80);
                border-radius: 12px;
                padding: 2px 16px;
                margin: 8px 0px 0px 0px;
                @include pushableSimple();
                @include noselect();

                &:hover {
                    background: var(--dashboardColor2);

                    .GeojiCnAdminTokenDetailsTopDateSelectorLabel {
                        color: var(--whiteColor);
                    }
                }

                .GeojiCnAdminTokenDetailsTopDateSelectorImg1 {
                    display: inline-block;
                    vertical-align: middle;
                    width: 20px;
                }

                .GeojiCnAdminTokenDetailsTopDateSelectorLabel {
                    display: inline-block;
                    vertical-align: middle;
                    @include fontSizeMedium();
                    color: var(--dashboardColor2);
                    padding: 4px 8px;
                    transition: 0.5s all;
                }

                .GeojiCnAdminTokenDetailsTopDateSelectorImg2 {
                    display: inline-block;
                    vertical-align: middle;
                    width: 10px;
                }
            }

            .GeojiCnAdminTokenDetailsTopFilters {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-items: center;
                padding-top: 8px;

                .GeojiCnAdminTokenDetailsTopFilter {
                    @include fontSizeMedium();
                    color: var(--whiteColor);
                    background: var(--dashboardColor);
                    padding: 4px 16px;
                    border-radius: 14px;
                    @include pushable();
                    margin: 8px 16px 8px 0px;
                }

                .GeojiCnAdminTokenDetailsTopFilterSelected {
                    background: var(--whiteColor);
                    color: var(--dashboardColor);
                }
            }

            .GeojiCnAdminTokenDetailsTopDateTime {
                @include fontSizeNormal();
                color: var(--dashboardColor2);
                padding: 8px 0px 0px 0px;
            }
        }

        .GeojiCnAdminTokenDetailsChart {
            padding: 32px;
            width: calc(100% - 64px);
            height: 250px;
        }
    }

    .GeojiCnAdminAZRedemptions {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 64px);
        }

        .GeojiCnAdminAZWristbands {
            padding-bottom: 16px;

            .GeojiCnInnerPurchaseMassAdBand {
                margin-top: 16px;
                width: calc(100% + 64px);
                margin-left: -32px;
            }

            .GeojiCnAdminAZWristband {
                .GeojiCnAdminAZWristbandNames {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    align-items: center;

                    .GeojiCnAdminAZWristbandName {
                        @include fontSizeMedium();
                        color: var(--darkColor);
                        padding: 4px 8px;
                    }
                }
            }
        }

        .GeojiCnAdminAZSearchBar {
            .InputDiv {
                padding-top: 0px;

                .inputBottomLineLightContainer {
                    .inputBottomLineLightFlexBox {
                        .inputBottomLineLight {
                            height: 24px;
                        }
                    }
                }
            }
        }

        .GeojiCnAdminOrdersList {
            .GeojiCnAdminOrder {
                position: relative;
                margin-top: 16px;
                @include pushableSimple();

                .GeojiCnAdminOrderNotes {
                    position: absolute;
                    z-index: 100;
                    left: 44px;
                    bottom: -8px;
                    width: 30px;
                    height: 30px;

                    img {
                        width: 30px;
                        height: 30px;
                        object-fit: contain;
                    }
                }

                .GeojiCnAdminOrderGiveaway {
                    position: absolute;
                    z-index: 100;
                    left: 44px;
                    top: -8px;
                    width: 30px;
                    height: 30px;

                    img {
                        width: 30px;
                        height: 30px;
                        object-fit: contain;
                    }
                }

                .GeojiCnAdminOrderWaiverSuccess {
                    position: absolute;
                    z-index: 100;
                    left: 100px;
                    top: -8px;
                    width: 30px;
                    height: 30px;

                    img {
                        width: 30px;
                        height: 30px;
                        object-fit: contain;
                    }
                }

                .GeojiCnAdminOrderWaiverFailed {
                    position: absolute;
                    z-index: 100;
                    left: 100px;
                    top: -8px;
                    width: 30px;
                    height: 30px;

                    img {
                        width: 30px;
                        height: 30px;
                        object-fit: contain;
                    }
                }

                .GeojiCnAdminOrderInner {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;

                    .GeojiCnAdminOrderButton {
                        padding: 10px;
                        margin-right: 8px;

                        img {
                            width: 24px;
                            height: 24px;
                            object-fit: contain;
                        }
                    }

                    .GeojiCnAdminOrderImage {
                        position: relative;
                        width: 70px;
                        height: 70px;
                        border-radius: 25px;
                        overflow: hidden;
                        background: var(--offWhiteColor);
                        flex-grow: 0;
                        flex-shrink: 0;

                        img {
                            width: 70px;
                            height: 70px;
                            object-fit: contain;
                        }

                        .dualImageError {
                            width: 35px;
                            height: 35px;
                            margin-top: 17px;
                        }

                        .defaultImage {
                            width: 35px;
                            height: 35px;
                            margin-top: 17px;
                            object-fit: contain;
                        }

                        .GeojiCnAdminOrderImageInitials {
                            @include fontSizeStat();
                            font-weight: bold;
                            color: var(--grayColor);
                            line-height: 70px;
                        }
                    }

                    .GeojiCnAdminOrderContent {
                        padding-left: 8px;
                        text-align: left;
                        flex-grow: 1;

                        .GeojiCnAdminOrderContentName {
                            @include fontSizeMedium();
                            color: var(--darkColor);
                            padding-bottom: 4px;
                        }

                        .GeojiCnAdminOrderContentBody {
                            @include fontSizeNormal();
                            color: var(--grayColor);
                            padding-bottom: 4px;
                        }
                    }
                }

                .GeojiCnAdminOrderLine {
                    margin-left: 70px;
                    width: calc(100% - 70px);
                    height: 1px;
                    background: var(--offWhiteColor);
                }
            }

            .GeojiCnAdminOrderTransparent {
                opacity: 0.3;
            }
        }
    }

    .GeojiCnAdminRLoading {
        .GeojiCnAdminRLoadingBar {
            @include grayLoading();
            width: 100%;
            height: 70px;
            border-radius: 12px;
            margin-top: 16px;
        }
    }

    .GeojiCnAdminRNone {
        margin-top: 16px;
        @include fontSizeNormal();
        color: var(--grayColor);
        margin-bottom: 32px;
    }

    .GeojiCnAdminQueueRedemptions {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 64px);
        }

        .GeojiCnAdminOrdersList {
            .GeojiCnAdminOrder {
                margin-top: 16px;
                @include pushableSimple();

                .GeojiCnAdminOrderInner {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;

                    .GeojiCnAdminOrderImage {
                        width: 70px;
                        height: 70px;
                        border-radius: 25px;
                        overflow: hidden;
                        background: var(--offWhiteColor);
                        flex-grow: 0;
                        flex-shrink: 0;

                        img {
                            width: 70px;
                            height: 70px;
                            object-fit: contain;
                        }

                        .dualImageError {
                            width: 35px;
                            height: 35px;
                            margin-top: 17px;
                        }

                        .defaultImage {
                            width: 35px;
                            height: 35px;
                            margin-top: 17px;
                            object-fit: contain;
                        }
                    }

                    .GeojiCnAdminOrderContent {
                        padding-left: 8px;
                        text-align: left;
                        flex-grow: 1;

                        .GeojiCnAdminOrderContentName {
                            @include fontSizeMedium();
                            color: var(--darkColor);
                            padding-bottom: 4px;
                        }

                        .GeojiCnAdminOrderContentBody {
                            @include fontSizeNormal();
                            color: var(--grayColor);
                        }

                        .GeojiCnAdminOrderContentNotes {
                            padding-top: 4px;
                            @include fontSizeNormal();
                            color: var(--darkColor);
                            font-style: italic;
                        }
                    }

                    .GeojiCnAdminOrderButton {
                        margin-left: 8px;

                        img {
                            width: 24px;
                            height: 24px;
                            object-fit: contain;
                        }
                    }
                }

                .GeojiCnAdminOrderLine {
                    margin-left: 70px;
                    width: calc(100% - 70px);
                    height: 1px;
                    background: var(--offWhiteColor);
                    margin-top: 8px;
                }
            }

            .GeojiCnAdminOrderTransparent {
                opacity: 0.3;
            }
        }
    }

    .GeojiCnAdminOrderDetails {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 64px);
        }

        .GeojiCnAdminOrderDetailsInner {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            .GeojiCnAdminOrderDetailsImage {
                position: relative;
                width: 70px;
                height: 70px;
                border-radius: 25px;
                overflow: hidden;
                background: var(--offWhiteColor);
                flex-grow: 0;
                flex-shrink: 0;

                img {
                    width: 70px;
                    height: 70px;
                    object-fit: contain;
                }

                .dualImageError {
                    width: 35px;
                    height: 35px;
                    margin-top: 17px;
                }

                .defaultImage {
                    width: 35px;
                    height: 35px;
                    margin-top: 17px;
                    object-fit: contain;
                }
            }

            .GeojiCnAdminOrderDetailsContent {
                padding-left: 8px;
                text-align: left;
                flex-grow: 1;

                .GeojiCnAdminOrderDetailsContentName {
                    @include fontSizeMedium();
                    color: var(--darkColor);
                    padding-bottom: 4px;
                }

                .GeojiCnAdminOrderDetailsContentStatus {
                    @include fontSizeMedium();
                    color: var(--grayColor);
                }
            }
        }

        .GeojiCnAdminOrderDetailsPurchaseTimestamp {
            text-align: left;
            @include fontSizeSmall();
            color: var(--grayColor);
            padding-top: 6px;
            padding-left: 8px;
        }

        .GeojiCnAdminOrderDetailsNotesSection {
            text-align: left;
            padding-top: 32px;

            .GeojiCnAdminOrderDetailsNotesTitle {
                @include fontSizeMedium();
                color: var(--darkColor);
            }

            .GeojiCnAdminOrderDetailsNotesBody {
                @include fontSizeNormal();
                font-style: italic;
                color: var(--darkColor);
                padding-top: 8px;
            }
        }

        .GeojiCnAdminOrderDetailsTokens {
            padding-top: 32px;
            text-align: left;

            .GeojiCnAdminOrderDetailsAllTokens {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                @include pushableSimple();
                @include noselect();

                img {
                    width: 24px;
                    height: 24px;
                    object-fit: contain;
                    margin-right: 8px;
                }

                .GeojiCnAdminOrderDetailsAllTokensTitle {
                    @include fontSizeMedium();
                    color: var(--darkColor);
                }
            }

            .GeojiCnAdminOrderDetailsIndiToken {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                padding-top: 16px;
                @include pushableSimple();
                @include noselect();

                img {
                    width: 24px;
                    height: 24px;
                    object-fit: contain;
                    margin-right: 8px;
                    margin-left: 32px;
                }

                .GeojiCnAdminOrderDetailsIndiTokenTitle {
                    @include fontSizeNormal();
                    color: var(--darkColor);

                    span {
                        color: var(--grayColor);
                    }
                }
            }
        }

        .GeojiCnAdminOrderDetailsActions {
            padding-top: 32px;
        }
        .GeojiCnAdminOrderDetailsSendReceipt {
            padding-top: 32px;

            .InputDiv {
                .inputBottomLineLightContainer {
                    .inputBottomLineTop {
                        .inputBottomLineTitle {
                            @include fontSizeMedium();
                            font-size: 15px;
                            color: var(--darkColor);
                            padding-bottom: 8px;
                        }
                    }

                    .inputBottomLineLightFlexBox {
                        .inputBottomLineLight {
                            height: 24px;
                        }
                    }
                }
            }

            .GeojiCnAdminOrderDetailsSendReceiptToggle {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                padding-top: 16px;

                .GeojiCnAdminOrderDetailsSendReceiptToggleText {
                    @include fontSizeNormal();
                    color: var(--grayColor);
                    flex-shrink: 1;
                }

                .react-toggle {
                    flex-shrink: 0;
                    margin: 0px 8px;

                    .react-toggle-track {
                        background: var(--grayColor);
                        height: 34px;
                        width: 70px;
                    }

                    .react-toggle-thumb {
                        left: 2px;
                        top: 2px;
                        width: 30px;
                        height: 30px;
                        border: 0px;
                    }
                }

                .react-toggle--checked {
                    .react-toggle-track {
                        background: var(--greenColor);
                    }

                    .react-toggle-thumb {
                        left: 38px;
                    }
                }
            }

            .GeojiCnAdminOrderDetailsSendReceiptLoading {
                padding-bottom: 1px;
                .GeojiCnAdminOrderDetailsSendReceiptLoadingBar {
                    @include grayLoading();
                    width: 100%;
                    height: 70px;
                    border-radius: 12px;
                    margin-top: 18px;
                }
            }
        }

        .GeojiCnAdminOrderDetailsEditNotes {
            padding-top: 32px;

            .InputDiv {
                .inputBottomLineLightContainer {
                    .inputBottomLineTop {
                        .inputBottomLineTitle {
                            @include fontSizeMedium();
                            font-size: 15px;
                            color: var(--darkColor);
                            padding-bottom: 8px;
                        }
                    }

                    .inputBottomLineLightFlexBox {
                        .inputBottomLineLight {
                            height: 24px;
                        }
                    }
                }
            }

            .GeojiCnAdminOrderDetailsEditNotesLoading {
                padding-bottom: 1px;
                .GeojiCnAdminOrderDetailsEditNotesLoadingBar {
                    @include grayLoading();
                    width: 100%;
                    height: 70px;
                    border-radius: 12px;
                    margin-top: 18px;
                }
            }
        }

        .GeojiCnAdminOrderDetailsChangeDate {
            padding-top: 32px;
        }

        .GeojiCnAdminOrderDetailsActionsHeader {
            text-align: left;
            @include fontSizeMedium();
            color: var(--darkColor);
        }

        .GeojiCnAdminOrderDetailsAction {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            background: var(--offWhiteColor);
            margin-top: 16px;
            border-radius: 12px;
            padding: 12px;
            @include pushable();
            @include noselect();

            img {
                width: 18px;
                height: 18px;
                object-fit: contain;
                margin-right: 8px;
            }

            .GeojiCnAdminOrderDetailsActionHeader {
                @include fontSizeMedium();
                color: var(--darkColor);
            }
        }
    }

    .GeojiCnAdminSendFreeTokens {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 64px);
        }

        .GeojiCnAdminSendFreeTokensHeader {
            @include fontSizeMedium();
            text-align: left;
            color: var(--darkColor);
            margin-top: 32px;
        }

        .GeojiCnAdminSendFreeTokensEmpty {
            @include fontSizeNormal();
            color: var(--grayColor);
            margin: 16px 0px;
        }

        .GeojiCnAdminSendFreeTokensList {
            padding-top: 16px;

            .GeojiCnInnerGrayGroupTokenElement {
                .GeojiCnInnerGrayGroupToken {
                    border: 2px solid var(--offWhiteColor);
                }

                .GeojiCnInnerGrayGroupTokenMap {
                    border: 2px solid var(--offWhiteColor);
                }

                .GeojiCnInnerGrayGroupTokenDateTime {
                    border: 2px solid var(--offWhiteColor);
                }
            }
        }

        .GeojiCnAdminSendFreeTokensWho {
            padding-top: 16px;
            padding-bottom: 16px;

            .GeojiCnAdminSendFreeTokensToggle {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;

                .GeojiCnAdminSendFreeTokensToggleText {
                    @include fontSizeNormal();
                    color: var(--grayColor);
                    flex-shrink: 1;
                }

                .react-toggle {
                    flex-shrink: 0;
                    margin: 0px 8px;

                    .react-toggle-track {
                        background: var(--grayColor);
                        height: 34px;
                        width: 70px;
                    }

                    .react-toggle-thumb {
                        left: 2px;
                        top: 2px;
                        width: 30px;
                        height: 30px;
                        border: 0px;
                    }
                }

                .react-toggle--checked {
                    .react-toggle-track {
                        background: var(--greenColor);
                    }

                    .react-toggle-thumb {
                        left: 38px;
                    }
                }
            }

            .GeojiCnAdminSendFreeTokensForm {
                .InputDiv {
                    .inputBottomLineLightContainer {
                        .inputBottomLineTop {
                            .inputBottomLineTitle {
                                @include fontSizeMedium();
                                font-size: 15px;
                                color: var(--darkColor);
                                padding-bottom: 8px;
                            }
                        }

                        .inputBottomLineLightFlexBox {
                            .inputBottomLineLight {
                                height: 24px;
                            }
                        }
                    }
                }
            }

            .GeojiCnAdminSendFreeTokensSendButton {
                background: var(--greenColor);
                @include fontSizeMedium();
                color: var(--whiteColor);
                border-radius: 12px;
                padding: 12px;
                @include pushableSimple();
                @include noselect();
                margin-top: 32px;
            }

            .GeojiCnAdminSendFreeTokensSendButtonLoading {
                height: 44px;
                border-radius: 12px;
                margin-top: 32px;
                @include grayLoading();
            }

            .GeojiCnAdminSendFreeTokensMultiple {
                .GeojiCnAdminSendFreeTokensMultipleDescription {
                    @include fontSizeNormal();
                    color: var(--darkColor);
                    text-align: left;
                    padding-top: 16px;
                }

                a {
                    text-decoration: none;
                }

                .GeojiCnAdminSendFreeTokensMultipleButton {
                    background: var(--offWhiteColor);
                    @include fontSizeMedium();
                    color: var(--darkColor);
                    border-radius: 12px;
                    padding: 12px;
                    @include pushable();
                    @include noselect();
                    margin-top: 16px;

                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 22px;
                        height: 22px;
                        margin-right: 8px;
                        object-fit: contain;
                    }
                }

                .GeojiCnAdminSendFreeTokensMultipleButtonBlack {
                    background: var(--darkColor);
                    color: var(--whiteColor);
                }

                .FileSelectorDiv {
                    .FileSelectorUploadInput {
                        display: none;
                    }
                }
            }

            .GeojiCnAdminSendFreeTokensPeopleList {

                .GeojiCnAdminSendFreeTokensPerson {
                    position: relative;
                    margin-top: 16px;

                    .GeojiCnAdminSendFreeTokensPersonInner {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        align-items: center;

                        .GeojiCnAdminSendFreeTokensPersonImage {
                            position: relative;
                            width: 70px;
                            height: 70px;
                            border-radius: 25px;
                            overflow: hidden;
                            background: var(--offWhiteColor);
                            flex-grow: 0;
                            flex-shrink: 0;

                            @include fontSizeMid();
                            color: var(--grayColor);
                            line-height: 70px;
                        }

                        .GeojiCnAdminSendFreeTokensPersonContent {
                            padding-left: 8px;
                            text-align: left;
                            flex-grow: 1;

                            .GeojiCnAdminSendFreeTokensPersonContentName {
                                @include fontSizeMedium();
                                color: var(--darkColor);
                                padding-bottom: 4px;
                            }

                            .GeojiCnAdminSendFreeTokensPersonContentPhone {
                                @include fontSizeNormal();
                                color: var(--grayColor);
                                padding-bottom: 4px;
                            }

                            .GeojiCnAdminSendFreeTokensPersonContentEmail {
                                @include fontSizeNormal();
                                color: var(--grayColor);
                                padding-bottom: 4px;
                            }
                        }
                    }

                    .GeojiCnAdminSendFreeTokensPersonLine {
                        margin-left: 70px;
                        width: calc(100% - 70px);
                        height: 1px;
                        background: var(--offWhiteColor);
                    }
                }
            }

            .GeojiCnAdminSendFreeTokensSuccess {
                .GeojiCnAdminSendFreeTokensSuccessHeader {
                    @include fontSizeTitle();
                    color: var(--darkColor);
                    margin-top: 32px;
                }

                .GeojiCnAdminSendFreeTokensSuccessBody {
                    @include fontSizeNormal();
                    color: var(--grayColor);
                    margin-top: 8px;
                }

                .GeojiCnAdminSendFreeTokensSuccessButton {
                    background: var(--greenColor);
                    @include fontSizeMedium();
                    color: var(--whiteColor);
                    border-radius: 12px;
                    padding: 12px;
                    @include pushable();
                    @include noselect();
                    margin-top: 32px;
                }
            }
        }
    }

    .GeojiCnAdminTopBar {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        .GeojiCnAdminTopBarBack {
            padding: 10px 10px 10px 0px;

            .GeojiCnAdminTopBarBackArrow {
                padding-right: 40px;
                width: 13px;
                object-fit: contain;
                @include pushable();
            }
        }

        .GeojiCnAdminTopBarTitle {
            @include fontSizeNormal();
            font-weight: bold;
            color: var(--darkColor);

            .GeojiCnAdminTopBarTitleEmpty {
                width: 24px;
                height: 10px;
                display: inline-block;
                vertical-align: middle;
            }

            .GeojiCnAdminTopBarTitleSaving {
                padding-left: 8px;
                font-size: 12px;
                vertical-align: middle;
                transition: all 0.3s;
            }

            .GeojiCnAdminTopBarTitleSavingHidden {
                opacity: 0.0;
            }
        }

        .GeojiCnAdminTopBarSandbox {
            @include fontSizeSmall();
            color: var(--greenColor);
            background: var(--greenTransparentColor80);
            padding: 4px 12px;
            border-radius: 12px;
            visibility: hidden;
        }

        .GeojiCnAdminTopBarSandboxVisible {
            visibility: visible;
        }
    }

    .GeojiCnAdminPromoterLinks {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 64px);
        }

        .GeojiCnAdminPromoterLinksCreateButton {
            @include fontSizeMedium();
            color: var(--whiteColor);
            border-radius: 30px;
            width: 220px;
            padding: 12px 0px;
            margin: 16px auto 0px auto;

            @include blueGreenGradient();

            @include pushable();
            @include noselect();
        }

        .GeojiCnAdminPromoterLinksNone {
            .GeojiCnAdminPromoterLinksNoneEmoji {
                @include fontSizeTitle();
                padding: 32px 0px 0px 0px;
            }

            .GeojiCnAdminPromoterLinksNoneTitle {
                @include fontSizeMid();
                color: var(--darkColor);
                padding: 16px 0px;
            }

            .GeojiCnAdminPromoterLinksNoneBody {
                @include fontSizeNormal();
                color: var(--grayColor);
                padding: 0px 0px 32px 0px;
            }
        }

        .GeojiCnAdminPromoterLinksList {

            .GeojiCnAdminPromoterLinksListTapToCopy {
                @include fontSizeMid();
                color: var(--grayColor);
                padding: 16px 0px;
            }

            .GeojiCnAdminPromoterLink {
                color: var(--darkColor);
                @include pushableSimple();
                @include noselect();

                .GeojiCnAdminPromoterLinkTop {
                    @include fontSizeNormal();
                    padding-bottom: 4px;
                }

                .GeojiCnAdminPromoterLinkBottom {
                    @include fontSizeMedium();
                }

                .GeojiCnAdminPromoterLinkContent {
                    background: var(--offWhiteColor);
                    border-radius: 12px;
                    margin-bottom: 16px;
                    padding: 16px;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: center;
                    align-items: flex-start;

                    .GeojiCnAdminPromoterLinkTop {
                        width: 100%;
                        display: flex;
                        flex-flow: row wrap-reverse;
                        justify-content: space-between;
                        align-items: center;

                        .GeojiCnAdminPromoterLinkTopText {}

                        .GeojiCnAdminPromoterLinkTopEdit {
                            @include fontSizeSmall();
                            background: var(--greenColor);
                            color: var(--whiteColor);
                            padding: 2px 12px;
                            border-radius: 6px;
                            flex-shrink: 0;
                            @include pushableSimple();
                        }
                    }
                }

                .GeojiCnAdminPromoterLinkCopied {
                    background: var(--dashboardColor);
                    border-radius: 12px;
                    margin-bottom: 16px;
                    padding: 16px;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: center;
                    align-items: flex-start;
                    color: var(--whiteColor);
                }
            }
        }

        .GeojiCnAdminPromoterLinksEdit {
            margin-top: 16px;

            .GeojiCnAdminPromoterLinksEditButton {
                background: var(--greenColor);
                @include fontSizeMedium();
                color: var(--whiteColor);
                border-radius: 12px;
                padding: 12px;
                @include pushableSimple();
                @include noselect();
                margin-top: 32px;
            }
        }
    }

    .GeojiCnAdminPromoCodes {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 64px);
        }

        .SettingsToggle {
            width: 100%;
            justify-content: center;
            margin: 16px 0px 0px 0px;

            .SettingsToggleName {
                @include fontSizeMid();
                color: var(--darkColor);
            }

            .react-toggle {
                .react-toggle-track {
                    background-color: var(--grayColor);
                }

                .react-toggle-thumb {
                    border: 1px solid var(--grayColor);
                }
            }

            .react-toggle--checked {
                .react-toggle-track {
                    background-color: var(--greenColor);
                }

                .react-toggle-thumb {
                    border: 1px solid var(--greenColor);
                }
            }
        }

        .GeojiCnAdminPromoCodesCreateButton {
            @include fontSizeMedium();
            color: var(--whiteColor);
            border-radius: 30px;
            width: 220px;
            padding: 12px 0px;
            margin: 16px auto 0px auto;

            @include blueGreenGradient();

            @include pushable();
            @include noselect();
        }

        .GeojiCnAdminPromoCodesNone {
            .GeojiCnAdminPromoCodesNoneEmoji {
                @include fontSizeTitle();
                padding: 32px 0px 0px 0px;
            }

            .GeojiCnAdminPromoCodesNoneTitle {
                @include fontSizeMid();
                color: var(--darkColor);
                padding: 16px 0px;
            }

            .GeojiCnAdminPromoCodesNoneBody {
                @include fontSizeNormal();
                color: var(--grayColor);
                padding: 0px 0px 32px 0px;
            }
        }

        .GeojiCnAdminPromoCodesList {

            .GeojiCnAdminPromoCodesListTapToCopy {
                @include fontSizeMid();
                color: var(--grayColor);
                padding: 16px 0px;
            }

            .GeojiCnAdminPromoCode {
                color: var(--darkColor);
                @include pushableSimple();
                @include noselect();

                .GeojiCnAdminPromoCodeContent {
                    background: var(--offWhiteColor);
                    border-radius: 12px;
                    margin-bottom: 16px;
                    padding: 16px;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: center;
                    align-items: flex-start;

                    .GeojiCnAdminPromoCodeTop {
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        color: var(--darkColor);
                        padding-bottom: 4px;

                        .GeojiCnAdminPromoCodeTopCode {
                            @include fontSizeMid();
                        }

                        .GeojiCnAdminPromoCodeTopDiscount {
                            @include fontSizeMedium();
                        }
                    }

                    .GeojiCnAdminPromoCodeDescription {
                        @include fontSizeNormal();
                        color: var(--grayColor);
                        padding-bottom: 4px;
                    }

                    .GeojiCnAdminPromoCodeBottom {
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        .GeojiCnAdminPromoCodeBottomUsed {
                            @include fontSizeMedium();
                            color: var(--darkColor);
                        }

                        .GeojiCnAdminPromoCodeBottomEdit {
                            @include fontSizeSmall();
                            background: var(--greenColor);
                            color: var(--whiteColor);
                            padding: 2px 12px;
                            border-radius: 6px;
                            flex-shrink: 0;
                            @include pushableSimple();
                        }
                    }
                }

                .GeojiCnAdminPromoCodeCopied {
                    background: var(--dashboardColor);
                    border-radius: 12px;
                    margin-bottom: 16px;
                    padding: 16px;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: center;
                    align-items: flex-start;
                    color: var(--whiteColor);

                    .GeojiCnAdminPromoCodeCopiedTop {
                        @include fontSizeMid();
                        padding-bottom: 4px;
                    }

                    .GeojiCnAdminPromoCodeCopiedBottom {
                        @include fontSizeMedium();
                        padding-bottom: 24px;
                    }
                }
            }

            .GeojiCnAdminPromoCodeDeleted {
                cursor: default;

                .GeojiCnAdminPromoCodeContent {
                    background: var(--whiteColor);
                    border: 1px solid var(--offWhiteColor);
                    border-radius: 12px;
                    margin-bottom: 16px;
                    padding: 16px;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: center;
                    align-items: flex-start;

                    .GeojiCnAdminPromoCodeTop {
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        color: var(--grayColor);
                        padding-bottom: 4px;

                        .GeojiCnAdminPromoCodeTopCode {
                            @include fontSizeMid();
                        }

                        .GeojiCnAdminPromoCodeTopDiscount {
                            @include fontSizeMedium();
                        }
                    }

                    .GeojiCnAdminPromoCodeDescription {
                        @include fontSizeNormal();
                        color: var(--grayLightColor);
                        padding-bottom: 4px;
                    }

                    .GeojiCnAdminPromoCodeBottom {
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        .GeojiCnAdminPromoCodeBottomUsed {
                            @include fontSizeMedium();
                            color: var(--grayColor);
                        }

                        .GeojiCnAdminPromoCodeBottomEdit {
                            @include fontSizeSmall();
                            background: var(--greenColor);
                            color: var(--whiteColor);
                            padding: 2px 12px;
                            border-radius: 6px;
                            flex-shrink: 0;
                            @include pushableSimple();
                            display: none;
                        }
                    }
                }
            }
        }

        .GeojiCnAdminPromoCodesEdit {
            margin-top: 16px;

            .GeojiCnAdminPromoCodesDeleteButton {
                background: var(--offWhiteColor);
                @include fontSizeMedium();
                color: var(--redColor);
                border-radius: 12px;
                padding: 12px;
                @include pushableSimple();
                @include noselect();
                margin-top: 32px;
            }

            .GeojiCnAdminPromoCodesEditButton {
                background: var(--greenColor);
                @include fontSizeMedium();
                color: var(--whiteColor);
                border-radius: 12px;
                padding: 12px;
                @include pushableSimple();
                @include noselect();
                margin-top: 16px;
            }
        }
    }

    .GeojiCnAdminReports {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 64px);
        }

        .GeojiCnAdminReportsContent {
            padding-top: 16px;

            .GeojiCnAdminReportsContentSection {
                @include fontSizeMedium();
                color: var(--darkColor);
                border-top: 1px solid var(--offWhiteColor);
                padding-top: 16px;
                margin-top: 16px;
            }

            .GeojiReportsBar {
                .GeojiReportsBarTop {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    background: var(--offWhiteColor);
                    border-radius: 12px;
                    padding: 6px 16px;

                    .GeojiSettingsBarTopImage {
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    .GeojiSettingsBarTopTitle {
                        padding: 0px 16px;
                        @include fontSizeNormal();
                        color: var(--darkColor);
                    }

                    .GeojiSettingsBarTopGrow {
                        flex-grow: 1;
                    }

                    .SettingsToggle {
                        justify-content: center;
                        margin: 0px;

                        .SettingsToggleName {
                            @include fontSizeMid();
                            color: var(--darkColor);
                        }

                        .react-toggle {
                            .react-toggle-track {
                                background-color: var(--grayColor);
                            }

                            .react-toggle-thumb {
                                border: 1px solid var(--grayColor);
                            }
                        }

                        .react-toggle--checked {
                            .react-toggle-track {
                                background-color: var(--greenColor);
                            }

                            .react-toggle-thumb {
                                border: 1px solid var(--greenColor);
                            }
                        }
                    }
                }

                .GeojiReportsBarDescription {
                    @include fontSizeNormal();
                    color: var(--grayColor);
                    padding: 4px 16px 16px 16px;
                    text-align: left;
                }
            }

            hr {
                padding: 0px;
                margin: 32px 0px 32px 0px;
                border: 0px;
                border-top: 1px solid var(--grayLineColor);
            }

            .GeojiCnAdminReportsContentCalendar {
                .GeojiCnAdminReportsContentCalendarSelector {
                    display: inline-block;
                    background: var(--tokenColorGreen);
                    border-radius: 12px;
                    padding: 2px 16px;
                    margin: 8px 0px 0px 0px;
                    @include pushableSimple();
                    @include noselect();

                    &:hover {
                        background: var(--greenColor);

                        .GeojiCnAdminReportsContentCalendarSelectorLabel {
                            color: var(--whiteColor);
                        }
                    }

                    .GeojiCnAdminTokenDetailsTopDateSelectorImg1 {
                        display: inline-block;
                        vertical-align: middle;
                        width: 20px;
                    }

                    .GeojiCnAdminReportsContentCalendarSelectorLabel {
                        display: inline-block;
                        vertical-align: middle;
                        @include fontSizeMedium();
                        color: var(--whiteColor);
                        padding: 4px 8px;
                        transition: 0.5s all;
                    }

                    .GeojiCnAdminReportsContentCalendarSelectorImg2 {
                        display: inline-block;
                        vertical-align: middle;
                        width: 10px;
                    }
                }
            }

            .GeojiCnAdminWhiteAction {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                //@include pushable();
                @include pushableSimple();
                @include noselect();
                padding: 16px;
                border-radius: 16px;
                background: var(--offWhiteColor);

                img {
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                }

                .GeojiCnAdminWhiteActionRight {
                    text-align: left;
                    padding-left: 16px;

                    .GeojiCnAdminWhiteActionRightTitle {
                        @include fontSizeMedium();
                        color: var(--whiteColor);
                        padding-bottom: 4px;
                    }

                    .GeojiCnAdminWhiteActionRightBody {
                        @include fontSizeNormal();
                        color: var(--whiteColor);
                    }
                }
            }

            .GeojiCnAdminWhiteActionDownload {
                margin-top: 16px;
                background: #6DC887;
            }

            .GeojiCnAdminWhiteActionDownloading {
                margin-top: 16px;
                background: var(--greenColor);
            }
        }
    }

    .GeojiCnAdminSettings {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 64px);
        }

        .GeojiCnAdminSettingsContent {
            padding-top: 16px;

            .GeojiCnAdminSettingsSection {
                @include fontSizeMedium();
                color: var(--darkColor);
                border-top: 1px solid var(--offWhiteColor);
                padding-top: 16px;
                margin-top: 16px;
            }

            .GeojiSettingsBar {
                .GeojiSettingsBarTop {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    background: var(--offWhiteColor);
                    border-radius: 12px;
                    padding: 6px 16px;

                    .GeojiSettingsBarTopImage {
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    .GeojiSettingsBarTopTitle {
                        padding: 0px 16px;
                        @include fontSizeNormal();
                        color: var(--darkColor);
                    }

                    .GeojiSettingsBarTopGrow {
                        flex-grow: 1;
                    }

                    .SettingsToggle {
                        justify-content: center;
                        margin: 0px;

                        .SettingsToggleName {
                            @include fontSizeMid();
                            color: var(--darkColor);
                        }

                        .react-toggle {
                            .react-toggle-track {
                                background-color: var(--grayColor);
                            }

                            .react-toggle-thumb {
                                border: 1px solid var(--grayColor);
                            }
                        }

                        .react-toggle--checked {
                            .react-toggle-track {
                                background-color: var(--greenColor);
                            }

                            .react-toggle-thumb {
                                border: 1px solid var(--greenColor);
                            }
                        }
                    }
                }

                .GeojiSettingsBarDescription {
                    @include fontSizeNormal();
                    color: var(--grayColor);
                    padding: 4px 16px 16px 16px;
                    text-align: left;
                }
            }

            .InputDiv {
                .inputBottomLineLightContainer {
                    .inputBottomLineTop {
                        .inputBottomLineTitle {
                            padding-bottom: 4px;
                        }

                        .inputBottomLineDescription {
                            color: var(--grayColor);
                            padding-bottom: 8px;
                        }
                    }
                }
            }
        }
    }

    .GeojiCnAdminInvite {
        width: calc(100% - 64px);
        background: var(--whiteColor);
        border-radius: 36px 36px;
        padding: 32px;
        position: relative;

        @media only screen and (max-width: $adminMaxWidth) {
            border-radius: 0px;
            min-height: calc(100% - 64px);
        }

        .GeojiCnAdminInviteContent {
            padding-top: 16px;

            .InputDiv {
                .inputBottomLineLightContainer {
                    .inputBottomLineTop {
                        .inputBottomLineTitle {
                            padding-bottom: 4px;
                        }

                        .inputBottomLineDescription {
                            color: var(--grayColor);
                            padding-bottom: 8px;
                        }
                    }
                }
            }

            .GeojiCnAdminInviteContentButton {
                background: var(--greenColor);
                @include fontSizeMedium();
                color: var(--whiteColor);
                border-radius: 12px;
                padding: 12px;
                @include pushableSimple();
                @include noselect();
                margin-top: 32px;
            }
        }
    }
}


// The main component for the Geoji theme
.Geoji {
    height: 100%;
    color: var(--darkColor);

    // The loading screen
    .GeojiLoading {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        height: 100%;

        .GeojiLoadingLogo {
            width: 200px;
            @include pulse(2.4s);
        }
    }

    // The error screen
    .GeojiError {
        min-height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        background: var(--whiteColor);
        background: #DF92C3;
		background: -moz-linear-gradient(225deg, #DF92C3 0%, #F99FB3 100%);
		background: -webkit-linear-gradient(225deg, #DF92C3 0%, #F99FB3 100%);
		background: linear-gradient(225deg, #DF92C3 0%, #F99FB3 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#DF92C3",endColorstr="#F99FB3",GradientType=1);

        @include fadeIn(2s);

        @media only screen and (max-width: 414px) {
            height: 100%;
        }

        .GeojiErrorBox {
            background-color: var(--whiteColor);
            /*background: #DF92C3;
            background: -moz-linear-gradient(225deg, #DF92C3 0%, #F99FB3 100%);
            background: -webkit-linear-gradient(225deg, #DF92C3 0%, #F99FB3 100%);
            background: linear-gradient(225deg, #DF92C3 0%, #F99FB3 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#DF92C3", endColorstr="#F99FB3", GradientType=1);*/
            width: calc(100% - 64px);
            max-width: 350px;
            padding: 32px;
            border-radius: 36px;
            @include materialSpreadShadow();

            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 414px) {
                height: calc(100% - 64px);
                border-radius: 0px;
            }

            .GeojiErrorEmoji {
                @include noselect();
                font-size: 128px;
                text-align: center;
                padding-bottom: 32px;
            }

            .GeojiErrorTitle {
                @include fontSizeTitle();
                @include noselect();
                color: var(--darkColor);
                padding-bottom: 32px;
            }

            .GeojiErrorMessage {
                @include fontSizeStat();
                @include noselect();
                color: var(--darkColor);
                padding-bottom: 64px;
            }

            .GeojiErrorButton {
                background: var(--blueColor);
                width: 100%;
                border-radius: 12px;
                @include fontSizeStat();
                font-weight: 600;
                padding: 16px 0px;
                color: var(--whiteColor);
                @include pushable();
                @include noselect();
            }
        }
    }

    // The actual Geoji screen
    .GeojiCn {
        min-height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        @include fadeIn(0.8s);
        background: var(--whiteColor);

        @media only screen and (max-width: 414px) {
            //height: 100%;
            //overflow-y: scroll;
        }

        .GeojiCnInnerDarkTheme {
            box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 1.0);
        }

        .GeojiCnInnerCenterTheme {

            .GeojiCnInnerWhite {
                .GeojiCnTitle {
                    text-align: center;
                }

                .GeojiCnTitleTall {
                    text-align: center;
                }

                .GeojiCnDateInfinite {
                    text-align: center;

                    .GeojiCnDateInfiniteImage {}
                }

                .GeojiCnDate {
                    text-align: center;
                }

                .GeojiCnTime {
                    text-align: center;
                }
            }

            .GeojiCnInnerGray {
                .GeojiCnInnerGrayGroup {
                    #GeojiTokenHeader {}

                    .GeojiCnInnerGrayGroupTitle {
                        text-align: center;
                    }

                    .GeojiCnInnerGrayGroupBody {
                        justify-content: center;

                        .GeojiCnInnerGrayGroupBodyText {
                            text-align: center;
                        }
                    }

                    .GeojiCnInnerGrayGroupDescription {
                        text-align: center;
                    }

                    .GeojiCnInnerGrayGroupWhoGoing {
                        justify-content: center;
                        padding-left: 60px;
                    }

                    .GeojiCnInnerGrayGroupWhoGoingBody {
                        text-align: center;
                    }

                    .GeojiCnInnerGrayGroupRequestAccessBody {
                        text-align: center;
                    }

                    .GeojiCnInnerGrayGroupTokens {
                        .GeojiCnInnerGrayGroupTokenElement {
                            .GeojiCnInnerGrayGroupSection {
                                .GeojiCnInnerGrayGroupSectionTitle {
                                    text-align: center;
                                }

                                .GeojiCnInnerGrayGroupSectionAbout {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }

        .GeojiCnInnerExpanded {
            max-width: $adminMaxWidth;

            @media only screen and (max-width: $adminMaxWidth) {
                border-radius: 0px;
                margin: 0px;
                //height: 100%;
                //overflow-y: scroll;
            }
        }
    }

    .GeojiCnNone {
        animation: none;
    }

    .GeojiCnBackgroundHeader {
        position: fixed;
        z-index: 0;
        top: 0px;
        right: 0px;
        text-align: right;
        padding: 25px 32px 0px 0px;
    }

    @media only screen and (max-width: 842px) {
        .GeojiCnBackgroundHeader {
            display: none;
        }
    }

    @media only screen and (max-width: 1452px) {
        .GeojiCnBackgroundHeaderWide {
            display: none;
        }
    }
}

.GeojiCnFooter {
    padding: 12px;

    //hide on small mobile screens.
    @media only screen and (max-width: 414px) {
        //display: none;
		border-top: 1px solid var(--offWhiteColor);
    }

    .GeojiCnFooterLinks {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        .GeojiCnFooterLink {
            @include fontSizeSmall();
            color: var(--grayColor);
            padding: 0px 8px 4px 8px;
            @include pushable();
            text-decoration: none;
        }
    }

    .GeojiCnFooterCopyright {
        padding-top: 8px;
        @include fontSizeSmall();
        font-weight: normal;
        color: var(--grayColor);
    }
}

.GeojiCnFooterNoHide {
    @media only screen and (max-width: 414px) {
        display: block;
    }
}

// A full screen popup.
.Popup {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    background-color: var(--whiteTransparentColor03);
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    @include fadeIn();
    text-align: center;
    overflow-y: scroll;

    .PopupContainer {
        margin: auto;

        .PopupMain {
            @include fontSizeTitle();
            color: var(--redColor);
            font-weight: bold;
        }

        .PopupTitle {
            @include fontSizeMid();
            color: var(--darkColor);
            font-weight: bold;
            padding-top: 32px;
        }

        .PopupDescription {
            @include fontSizeNormal();
            color: var(--darkColor);
            padding-top: 16px;
        }

        .ButtonDiv {

            .ButtonDivButton {
                display: inline-block;
                vertical-align: top;

                @include fontSizeMedium();
                background: var(--darkColor);
                color: var(--whiteColor);
                min-width: 120px;
                padding: 12px;
                border-radius: 12px;
                margin: 16px 8px 0px 8px;
                @include pushable();
                @include noselect();
            }

            .ButtonDivButtonRed {
                background: var(--redColor);
                color: var(--whiteColor);
            }
        }
    }
}

#AmazonPayHiddenBox {
    display: none;
}

.GeojiCnInnerPurchaseMassAdBand {
    width: 100%;
    height: 100px;
    margin-top: 32px;
    background: blue;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    overflow: hidden;

    .GeojiCnInnerPurchaseMassAdBandShapeBox {
        width: 100%;
        height: 100px;
        position: relative;
        @include wristbandAnimation(2.3s);

        .GeojiCnInnerPurchaseMassAdBandShape {
            position: absolute;
            left: 0px;
            top: 16px;
            width: 68px;
            height: 68px;

            svg {}
        }

        .GeojiCnInnerPurchaseMassAdBandShape1 {
            left: calc(-100% - 34px);
        }

        .GeojiCnInnerPurchaseMassAdBandShape2 {
            left: calc(-50% - 34px);
        }

        .GeojiCnInnerPurchaseMassAdBandShape3 {
            left: -34px;
        }

        .GeojiCnInnerPurchaseMassAdBandShape4 {
            left: calc(50% - 34px);
        }

        .GeojiCnInnerPurchaseMassAdBandShape5 {
            left: calc(100% - 34px);
        }
    }
}

.GeojiCnInnerGrayGroupSection {
    .GeojiCnInnerGrayGroupSectionTitle {
        @include fontSizeMid();
        color: var(--darkColor);
        text-align: left;
        padding: 16px 0px 4px 0px;
    }

    .GeojiCnInnerGrayGroupSectionAbout {
        @include fontSizeNormal();
        color: var(--grayColor);
        text-align: left;
    }
}

.DashboardPageEditorTokensList {
    .GeojiCnInnerGrayGroupTokenElement {
        cursor: move;
        @include noselect();
    }
}

.GeojiCnInnerGrayGroupTokenElement {
    margin-bottom: 16px;

    .GeojiCnInnerGrayGroupToken {
        background: var(--whiteColor);
        border-radius: 12px;
        padding: 12px;

        .GeojiCnInnerGrayGroupTokenTop {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            .GeojiCnInnerGrayGroupTokenLeft {
                text-align: left;

                .GeojiCnInnerGrayGroupTokenLeftTitle {
                    @include fontSizeNormal();
                }

                .GeojiCnInnerGrayGroupTokenLeftPrice {
                    @include fontSizeMedium();
                    padding-top: 2px;
                }
            }

            .GeojiCnInnerGrayGroupTokenRight {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                align-items: center;
                @include fontSizeMid();
                color: var(--darkColor);
                min-width: 98px;

                .GeojiCnInnerGrayGroupTokenRightEdit {
                    @include fontSizeSmall();
                    color: var(--whiteColor);
                    background: var(--greenColor);
                    display: inline-block;
                    padding: 2px 12px;
                    border-radius: 6px;
                    flex-shrink: 0;
                    @include pushable();
                    @include noselect();
                }

                .GeojiCnInnerGrayGroupTokenRightDuplicate {
                    @include fontSizeSmall();
                    color: var(--whiteColor);
                    background: var(--blueColor);
                    display: inline-block;
                    padding: 2px 12px;
                    margin-left: 4px;
                    border-radius: 6px;
                    flex-shrink: 0;
                    @include pushable();
                    @include noselect();
                }

                .GeojiCnInnerGrayGroupTokenRightSoldOut {
                    @include fontSizeMedium();
                    color: var(--grayColor);
                    margin: 0px auto;
                }

                .GeojiCnInnerGrayGroupTokenRightMinus {
                    padding: 4px 10px;
                    @include pushable();
                    @include noselect();
                }

                .GeojiCnInnerGrayGroupTokenRightLabel {
                    border-radius: 12px;
                    background: var(--offWhiteColor);
                    @include noselect();
                    padding: 4px 10px;
                    text-align: center;
                    min-width: 14px;

                    .InputDiv {
                        padding: 0px;

                        .inputBottomLineLightContainer {
                            .inputBottomLineTop {
                                display: flex;
                                flex-flow: row nowrap;
                                justify-content: space-between;
                                align-items: center;

                                .inputBottomLineTitle {
                                    @include fontSizeNormal();
                                    font-weight: bold;
                                    padding-left: 16px;
                                    padding-bottom: 4px;
                                }

                                .inputBottomLineDescription {
                                    @include fontSizeSmall();
                                    color: var(--grayColor);
                                }
                            }

                            .inputBottomLineLightFlexBox {
                                background: none;

                                .inputBottomLineLight {
                                    width: 22px;
                                    text-align: center;
                                    padding: 4px 10px;
                                    background: none;
                                    height: auto;
                                    @include fontSizeMid();
                                    color: var(--darkColor);
                                }
                            }

                            .inputBottomLineInvalid {
                                min-height: 0px;
                                padding: 0px;
                            }
                        }
                    }
                }

                .GeojiCnInnerGrayGroupTokenRightLabelActive {
                    background: var(--greenColor);
                    color: $whiteColor;

                    .InputDiv {
                        .inputBottomLineLightContainer {
                            .inputBottomLineTop {
                                .inputBottomLineTitle {}

                                .inputBottomLineDescription {}
                            }

                            .inputBottomLineLightFlexBox {
                                background: none;

                                .inputBottomLineLight {
                                    color: var(--whiteColor);
                                }
                            }

                            .inputBottomLineInvalid {}
                        }
                    }
                }

                .GeojiCnInnerGrayGroupTokenRightPlus {
                    padding: 4px 10px;
                    color: var(--greenColor);
                    @include pushable();
                    @include noselect();
                }
            }
        }

        .GeojiCnInnerGrayGroupTokenBottomAboutImage {
            margin-top: 2px;

            img {
                width: 100%;
                //max-width: 320px;
                //max-height: 320px;
                border-radius: 12px;
                object-fit: cover;
            }
        }

        .GeojiCnInnerGrayGroupTokenBottomAbout {
            margin-top: 2px;
            @include fontSizeNormal();
            color: var(--grayColor);
            text-align: left;
        }
    }

    .GeojiCnInnerGrayGroupToken:last-of-type {
        margin-bottom: 0px;
    }

    .GeojiCnInnerGrayGroupTokenSoldOut {
        .GeojiCnInnerGrayGroupTokenLeft {
            .GeojiCnInnerGrayGroupTokenLeftTitle {
                color: var(--grayColor);
                text-decoration: line-through;
            }

            .GeojiCnInnerGrayGroupTokenLeftPrice {
                color: var(--grayColor);
                text-align: line-through;
            }
        }

        .GeojiCnInnerGrayGroupTokenRight {
            .GeojiCnInnerGrayGroupTokenRightMinus {
                color: var(--grayColor);
            }

            .GeojiCnInnerGrayGroupTokenRightPlus {
                color: var(--grayColor);
            }
        }
    }

    .GeojiCnInnerGrayGroupTokenMap {
        background: var(--whiteColor);
        border-radius: 12px;
        padding: 2px 2px 12px 2px;

        .GeojiCnInnerGrayGroupTokenMapTitle {
            @include fontSizeMedium();
            color: var(--darkColor);
            padding: 12px;
        }

        .GeojiCnInnerGrayGroupTokenMapKey {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            padding-bottom: 16px;

            .GeojiCnInnerGrayGroupTokenMapKeyElement {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;

                padding-right: 8px;

                .GeojiCnInnerGrayGroupTokenMapKeyElementColor {
                    background: var(--blueColor);
                    width: 14px;
                    height: 14px;
                    border-radius: 4px;
                }

                .GeojiCnInnerGrayGroupTokenMapKeyElementColorGreen {
                    background: var(--greenColor);
                }

                .GeojiCnInnerGrayGroupTokenMapKeyElementColorGray {
                    background: var(--grayLightColor);
                }

                .GeojiCnInnerGrayGroupTokenMapKeyElementName {
                    @include fontSizeSmall();
                    color: var(--grayColor);
                    padding-left: 4px;
                }
            }
        }

        .GeojiCnInnerGrayGroupTokenMapGraphic {
            border-radius: 12px 12px 0px 0px;
            position: relative;
            overflow: hidden;

            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            .GeojiCnInnerGrayGroupTokenMapGraphicImage {
                width: 100%;
                object-fit: contain;
            }

            .GeojiCnInnerGrayGroupTokenMapGraphicSeat {
                left: 0px;
                top: 0px;
                position: absolute;
                background: var(--blueColor);
                width: 30px;
                height: 30px;
                z-index: 2;
                border-radius: 4px;
                @include pushable();
                @include noselect();

                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;

                .GeojiCnInnerGrayGroupTokenMapGraphicSeatText {
                    @include fontSizeMedium();
                    color: $whiteColor;
                    //opacity: 0;
                }

                &:hover {
                    .GeojiCnInnerGrayGroupTokenMapGraphicSeatText {
                        opacity: 1;
                    }
                }
            }

            .GeojiCnInnerGrayGroupTokenMapGraphicSeatSold {
                background: var(--grayLightColor);
            }

            .GeojiCnInnerGrayGroupTokenMapGraphicSeatSelected {
                background: var(--greenColor);

                .GeojiCnInnerGrayGroupTokenMapGraphicSeatText {
                    opacity: 1;
                }
            }

            .GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeCircle {
                border-radius: 50%;
            }

            .GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeSquare {}

            .GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeRect {
                width: 60px;
                margin-left: -15px;
            }

            .GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeVRect {
                height: 60px;
                margin-top: -15px;
            }
        }

        .GeojiCnInnerGrayGroupTokenMapText {
            padding: 10px 10px 0px 10px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            .GeojiCnInnerGrayGroupTokenMapLeft {
                text-align: left;

                .GeojiCnInnerGrayGroupTokenMapLeftTitle {
                    @include fontSizeNormal();
                }

                .GeojiCnInnerGrayGroupTokenMapLeftPrice {
                    @include fontSizeMedium();
                    padding-top: 2px;
                }
            }

            .GeojiCnInnerGrayGroupTokenMapRight {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                align-items: center;
                @include fontSizeMid();
                color: var(--darkColor);
                padding-right: 32px;

                .GeojiCnInnerGrayGroupTokenMapRightLabel {
                    border-radius: 12px;
                    background: var(--offWhiteColor);
                    @include noselect();
                    padding: 4px 10px;
                    text-align: center;
                    min-width: 14px;
                }

                .GeojiCnInnerGrayGroupTokenMapRightLabelActive {
                    background: var(--greenColor);
                    color: var(--whiteColor);
                }
            }
        }

        .GeojiCnInnerGrayGroupTokenMapAbout {
            @include fontSizeNormal();
            color: var(--grayColor);
            text-align: left;
            padding: 4px 12px 0px 12px;
        }
    }

    .GeojiCnInnerGrayGroupTokenMapSoldOut {
        .GeojiCnInnerGrayGroupTokenMapLeft {
            .GeojiCnInnerGrayGroupTokenMapLeftTitle {
                color: var(--grayColor);
                text-decoration: line-through;
            }

            .GeojiCnInnerGrayGroupTokenMapLeftPrice {
                color: var(--grayColor);
                text-align: line-through;
            }
        }
    }

    .GeojiCnInnerGrayGroupTokenTipJar {
        background: var(--darkColor);
        color: var(--whiteColor);
        border-radius: 40px;
        overflow: hidden;

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        @include noselect();

        .GeojiCnInnerGrayGroupTokenTipJarLeft {
            @include fontSizeStat();
            font-weight: bold;
            padding: 22px;
            @include pushable();
            flex-shrink: 0;
        }

        .GeojiCnInnerGrayGroupTokenTipJarCenter {
            @include fontSizeStat();
            font-weight: bold;
            border-right: 2px solid var(--offWhiteColor);
            border-left: 2px solid var(--offWhiteColor);
            flex-grow: 1;
            padding: 15px 0px;

            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;

            .InputDiv {
                padding: 0px;

                .inputBottomLineLightContainer {
                    .inputBottomLineTop {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-items: center;

                        .inputBottomLineTitle {
                            @include fontSizeNormal();
                            font-weight: bold;
                            padding-left: 16px;
                            padding-bottom: 4px;
                        }

                        .inputBottomLineDescription {
                            @include fontSizeSmall();
                            color: var(--grayColor);
                        }
                    }

                    .inputBottomLineLightFlexBox {
                        background: none;

                        .moneyInputSign {
                            @include fontSizeMid();
                            color: var(--whiteColor);
                        }

                        .inputBottomLineLight {
                            width: 22px;
                            text-align: center;
                            padding: 0px;
                            background: none;
                            height: auto;
                            @include fontSizeMid();
                            color: var(--whiteColor);
                        }
                    }

                    .inputBottomLineInvalid {
                        min-height: 0px;
                        padding: 0px;
                    }
                }
            }

            .GeojiCnInnerGrayGroupTokenTipJarCenterTitle {
                @include fontSizeSmall();
                font-weight: bold;
                color: var(--grayLightColor);
                padding: 0px 8px;
            }
        }

        .GeojiCnInnerGrayGroupTokenTipJarRight {
            @include fontSizeStat();
            font-weight: bold;
            padding: 22px;
            @include pushable();
            flex-shrink: 0;
        }
    }

    .GeojiCnInnerGrayGroupTokenAutoTip {
        width: calc(100% - 24px);
        background: var(--whiteColor);
        padding: 12px;
        border-radius: 12px;

        .GeojiCnInnerGrayGroupTokenAutoTipTitle {
            @include fontSizeNormal();
            text-align: left;
            padding: 0px 0px 8px 0px;
            color: var(--darkColor);
        }

        .GeojiCnInnerGrayGroupTokenAutoTipOptions {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            @include noselect();

            .GeojiCnInnerGrayGroupTokenAutoTipOption {
                flex-grow: 1;
                margin: 0px 2px;
                padding: 12px 0px;
                @include pushable();
                background: var(--offWhiteColor);
                border-radius: 12px;

                .GeojiCnInnerGrayGroupTokenAutoTipOptionName {
                    @include fontSizeNormal();
                    color: var(--darkColor);
                }
            }

            .GeojiCnInnerGrayGroupTokenAutoTipOptionSelected {
                background: var(--greenColor);

                .GeojiCnInnerGrayGroupTokenAutoTipOptionName {
                    color: var(--whiteColor);
                }
            }
        }

        .GeojiCnInnerGrayGroupTokenAutoTipAbout {
            @include fontSizeNormal();
            color: var(--grayColor);
            padding: 8px 0px 0px 0px;
            text-align: left;
        }
    }

    .GeojiCnInnerGrayGroupTokenPromoCode {
        background: var(--whiteColor);
        border-radius: 12px;
        padding: 12px;

        .GeojiCnInnerGrayGroupTokenPromoCodeInner {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            .InputDiv {
                flex-grow: 1;
                margin-right: 16px;
            }

            .GeojiCnInnerGrayGroupTokenPromoCodeInnerSubmit {
                width: 63px;
                height: 63px;
                background: var(--offWhiteColor);
                @include fontSizeNormal();
                line-height: 63px;
                border-radius: 8px;
                margin-bottom: 7px;
                @include pushable();

                img {
                    width: 24px;
                    height: 24px;
                    margin: 19px;
                    object-fit: contain;
                }
            }

            .GeojiCnInnerGrayGroupTokenPromoCodeInnerSubmitGreen {
                background: var(--greenColor);
                color: var(--whiteColor);
            }
        }

        .GeojiCnInnerGrayGroupTokenPromoCodeDescription {
            @include fontSizeSmall();
            color: var(--grayColor);
            text-align: left;
        }
    }

    .GeojiCnInnerGrayGroupTokenDateTime {
        background: var(--whiteColor);
        border-radius: 12px;

        .GeojiCnInnerGrayGroupTokenDateTimeTitle {
            @include fontSizeMedium();
            color: var(--darkColor);
            padding: 12px 12px 8px 12px;
        }

        .GeojiCnInnerGrayGroupTokenDateTimeCalendar {
            padding: 0px 0px 8px 0px;

            .rmdp-wrapper {
                width: 100%;
                box-shadow: none;
                border-radius: 12px;
                background: var(--whiteColor);

                .rmdp-border-bottom {
                    border-bottom: 0px;
                }

                .rmdp-colors {
                    display: none;
                }

                .rmdp-calendar {
                    width: 100%;
                    height: 100%;
                    @include noselect();

                    .rmdp-header {
                        .rmdp-header-values {
                            @include fontSizeNormal();
                            color: var(--darkColor);
                        }

                        .rmdp-arrow-container:hover {
                            background: var(--blueColor);
                            box-shadow: none;

                            .rmdp-arrow {
                                border: solid var(--whiteColor);
                                border-width: 0px 2px 2px 0px;
                            }
                        }

                        .rmdp-arrow {
                            border: solid var(--blueColor);
                            border-width: 0px 2px 2px 0px;
                        }
                    }

                    .rmdp-day-picker {
                        flex-flow: row nowrap;
                        justify-content: center;
                        align-items: center;

                        div:first-of-type {
                            width: 100%;

                            .rmdp-week {
                                flex-flow: row nowrap;
                                justify-content: space-between;
                                align-items: center;

                                .rmdp-week-day {
                                    @include fontSizeSmall();
                                    color: var(--blueColor);
                                    width: 34px;
                                    height: 34px;
                                }

                                .rmdp-day {
                                    width: 34px;
                                    height: 34px;
                                    @include fontSizeSmall();
                                    color: var(--darkColor);
                                }

                                .rmdp-today {
                                    span {
                                        background: var(--grayLineColor);
                                        color: var(--darkColor);
                                    }
                                }

                                .rmdp-selected {
                                    span {
                                        box-shadow: none;
                                        background: var(--blueColor);
                                        color: var(--whiteColor);
                                    }

                                    .highlight-blue {
                                        background: var(--blueColor);
                                    }

                                    .highlight-green {
                                        background: var(--greenColor);
                                    }
                                }
                            }
                        }
                    }

                    .rmdp-year-picker {
                        background: var(--whiteColor);

                        .rmdp-ym {
                            .rmdp-day {
                                span {
                                    @include fontSizeSmall();
                                    color: var(--darkColor);
                                }
                            }

                            .rmdp-today {
                                span {
                                    background: var(--grayLineColor);
                                    color: var(--darkColor);
                                }
                            }

                            .rmdp-selected {
                                span {
                                    background: var(--blueColor);
                                    color: var(--whiteColor);
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                    .rmdp-month-picker {
                        background: var(--whiteColor);

                        .rmdp-ym {
                            .rmdp-day {
                                span {
                                    @include fontSizeSmall();
                                    color: var(--darkColor);
                                }
                            }

                            .rmdp-today {
                                span {
                                    background: var(--grayLineColor);
                                    color: var(--darkColor);
                                }
                            }

                            .rmdp-selected {
                                span {
                                    //background: var(--blueColor);
                                    color: var(--whiteColor);
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .GeojiCnInnerGrayGroupTokenDateTimeTimes {
            padding: 0px 12px 8px 12px;
            overflow-y: scroll;
            max-height: 146px;
            //white-space: nowrap;
            text-align: left;

            .GeojiCnInnerGrayGroupTokenDateTimeTimesName {
                @include fontSizeMedium();
                color: var(--darkColor);
                margin: 0px 0px 8px 0px;
            }

            .GeojiCnInnerGrayGroupTokenDateTimeTime {
                @include fontSizeMedium();
                color: $whiteColor;
                background: var(--blueColor);
                padding: 6px 8px;
                margin: 0px 4px 4px 0px;
                border-radius: 8px;
                display: inline-block;
                @include pushable();
                @include noselect();
            }

            @media only screen and (max-width: 413px) {
                .GeojiCnInnerGrayGroupTokenDateTimeTime {
                    padding: 6px 4px;
                    margin: 0px 2px 4px 0px;
                }
            }

            .GeojiCnInnerGrayGroupTokenDateTimeTimeSoldOut {
                text-decoration: line-through;
                cursor: not-allowed;

                &:active {
                    transform: none;
                }
            }

            .GeojiCnInnerGrayGroupTokenDateTimeTimesName2 {
                @include fontSizeMedium();
                color: var(--darkColor);
                margin: 8px 0px 0px 0px;
                text-align: right;
            }
        }

        .GeojiCnInnerGrayGroupTokenDateTimeBottom {
            padding: 0px 12px 12px 12px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            .GeojiCnInnerGrayGroupTokenLeft {
                text-align: left;

                .GeojiCnInnerGrayGroupTokenLeftTitle {
                    @include fontSizeNormal();
                }

                .GeojiCnInnerGrayGroupTokenLeftPrice {
                    @include fontSizeMedium();
                    padding-top: 2px;
                }
            }

            .GeojiCnInnerGrayGroupTokenRight {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                align-items: center;
                @include fontSizeMid();
                color: var(--darkColor);
                min-width: 98px;

                .GeojiCnInnerGrayGroupTokenRightSoldOut {
                    @include fontSizeMedium();
                    color: var(--grayColor);
                    margin: 0px auto;
                }

                .GeojiCnInnerGrayGroupTokenRightMinus {
                    padding: 4px 10px;
                    @include pushable();
                    @include noselect();
                }

                .GeojiCnInnerGrayGroupTokenRightLabel {
                    border-radius: 12px;
                    background: var(--offWhiteColor);
                    @include noselect();
                    padding: 4px 10px;
                    text-align: center;
                    min-width: 14px;

                    .InputDiv {
                        padding: 0px;

                        .inputBottomLineLightContainer {
                            .inputBottomLineTop {
                                display: flex;
                                flex-flow: row nowrap;
                                justify-content: space-between;
                                align-items: center;

                                .inputBottomLineTitle {
                                    @include fontSizeNormal();
                                    font-weight: bold;
                                    padding-left: 16px;
                                    padding-bottom: 4px;
                                }

                                .inputBottomLineDescription {
                                    @include fontSizeSmall();
                                    color: var(--grayColor);
                                }
                            }

                            .inputBottomLineLightFlexBox {
                                background: none;

                                .inputBottomLineLight {
                                    width: 22px;
                                    text-align: center;
                                    padding: 4px 10px;
                                    background: none;
                                    height: auto;
                                    @include fontSizeMid();
                                    color: var(--darkColor);
                                }
                            }

                            .inputBottomLineInvalid {
                                min-height: 0px;
                                padding: 0px;
                            }
                        }
                    }
                }

                .GeojiCnInnerGrayGroupTokenRightLabelActive {
                    background: var(--greenColor);
                    color: $whiteColor;

                    .InputDiv {
                        .inputBottomLineLightContainer {
                            .inputBottomLineTop {
                                .inputBottomLineTitle {}

                                .inputBottomLineDescription {}
                            }

                            .inputBottomLineLightFlexBox {
                                background: none;

                                .inputBottomLineLight {
                                    color: var(--whiteColor);
                                }
                            }

                            .inputBottomLineInvalid {}
                        }
                    }
                }

                .GeojiCnInnerGrayGroupTokenRightPlus {
                    padding: 4px 10px;
                    color: var(--greenColor);
                    @include pushable();
                    @include noselect();
                }
            }
        }

        .GeojiCnInnerGrayGroupTokenBottomAboutImage {
            margin-top: 2px;

            img {
                width: 100%;
                //max-width: 320px;
                //max-height: 320px;
                border-radius: 12px;
                object-fit: cover;
            }
        }

        .GeojiCnInnerGrayGroupTokenBottomAbout {
            margin-top: -10px;
            padding: 0px 12px 12px 12px;
            @include fontSizeNormal();
            color: var(--grayColor);
            text-align: left;
        }
    }
}

.BreakWord {
    word-break: break-word !important;
}

.menu-trigger {
    cursor: pointer;
    @include noselect();
}
.contextmenu {
    width: 250px !important;
    background-color: var(--whiteColor) !important;
    border-radius: 24px !important;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15) !important;
    overflow: hidden;

    .contextmenu__item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        img {
            width: 15px;
            height: 15px;
            margin-right: 15px;
        }

        span {
            @include fontSizeMedium();
            font-size: 13px !important;
            color: var(--darkColor) !important;
            text-align: left !important;
        }
    }
    .contextmenu__item:hover {
        background-color: var(--offWhiteColor) !important;
    }
}