// main: ../../App.scss

$pageEditorDark1: #17181B;
$pageEditorDark2: #2B2D37;
$pageEditorDark3: #474c62;
$pageEditorLight1: #E4E4E5;
$pageEditorLight2: #9C9D9F;

@mixin pageEditorBackground() {
  background: rgb(43,45,55);
  background: linear-gradient(170deg, rgba(43,45,55,1) 0%, rgba(23,24,27,1) 100%);
}

.PageEditor {
  background-color: $pageEditorDark1;
  height: 100%;
  @include fontSizeEditorNormal();
  color: $pageEditorLight1;

  .GeojiLoading {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 64px 0px;

    .GeojiLoadingLogo {
      width: 200px;
      height: 200px;
      @include pulse(2.4s);
    }
  }
  .PageEditorContent {
    height: 100%;
    .PageEditorHeader {
      background-color: $pageEditorDark1;
      height: 50px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .PageEditorHeaderSpace {
        width: 128px;
      }
      .PageEditorHeaderText {
        @include fontSizeEditorNormal();
        color: $pageEditorLight2;
      }
      .PageEditorActions {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding-right: 40px;

        .PageEditorAction {
          width: 36px;
          height: 36px;
          margin: 4px;
          @include pushable();
        }
      }
    }
    .PageEditorSplit {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      height: calc(100% - 50px);

      .PageEditorSplitLeft {
        @include pageEditorBackground();
        flex-grow: 0;
        flex-shrink: 0;
        width: 60px;
        height: 100%;
        margin-right: 2px;

        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        flex-flow: center;

        .PageEditorSplitLeftSection {
          @include pushableNoSelect();
          margin: 12px 2px;

          img {
            width: 30px;
            filter: brightness(0) saturate(100%) invert(65%) sepia(4%) saturate(135%) hue-rotate(182deg) brightness(95%) contrast(93%);
            transition: all 0.5s;
          }
          div {
            @include fontSizeEditorSmall();
            color: $pageEditorLight2;
            transition: all 0.5s;
          }
        }
        .PageEditorSplitLeftSectionSelected {
          img {
            filter: brightness(0) saturate(100%) invert(91%) sepia(4%) saturate(34%) hue-rotate(201deg) brightness(104%) contrast(88%);
          }
          div {
            color: $pageEditorLight1;
          }
        }
        .PageEditorSplitLeftSectionIcon {
          margin-bottom: 22px;
          img {
            filter: none;
          }
        }
      }
      .PageEditorSplitLeftExpanded {
        @include pageEditorBackground();
        flex-grow: 0;
        flex-shrink: 0;
        width: 220px;
        height: 100%;
        margin-right: 2px;
        overflow-y: scroll;
        z-index: 10;

        .PageEditorSplitLeftExpandedXVisible {
          overflow-x: visible;
        }
      }
      .GeojisSection {
        .GeojisSectionLoading {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          padding: 10px 0px;
      
          .GeojiLoadingLogo {
            width: 64px;
            height: 64px;
            @include pulse(2.4s);
          }
        }
        .GeojisSectionBody {
          @include fontSizeEditorNormal();
          padding: 12px 0px;
        }
        .GeojisSectionGeoji {
          display: flex;
          flex-flow: column wrap;
          justify-content: center;
          align-items: center;
          margin: 12px 4px 0px 4px;
          background-color: $pageEditorDark3;
          border-radius: 12px;
          @include pushableNoSelect();

          .GeojisSectionGeojiImage {
            width: 100%;
            border-radius: 12px;
          }
          .GeojisSectionGeojiEmoji {
            font-size: 64px;
          }
          .GeojisSectionGeojiTitle {
            padding: 8px;
          }
        }
        .GeojisSectionGeojiSelected {
          border-bottom: 4px solid var(--greenColor);
          margin-bottom: 32px;
        }
      }
      .ThemeSection {
        .ThemeSectionHeader {
          @include fontSizeEditorMedium();
          text-align: left;
          padding: 10px 0px 10px 12px;
        }
      }
      .SectionsSection {
        .SectionsSectionHeader {
          @include fontSizeEditorMedium();
          text-align: left;
          padding: 0px 0px 0px 12px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          .SectionsSectionHeaderPlus {
            padding: 10px 12px 10px 18px;
            @include pushableNoSelect();
          }
        }
        .SectionsSectionList {
          .SectionsSectionListItems {
            .PageSectionElement {
              cursor: pointer;
              @include fontSizeEditorSmall();
              @include noselect();
              color: $pageEditorLight1;
              padding: 6px 12px 6px 24px;
              text-align: left;
              &:hover {
                background-color: $pageEditorDark1;
              }
            }
            .PageSectionElementSelected {
              background-color: $pageEditorDark1;
            }
          }
        }
        .SectionsSectionNew {

        }
      }
      .PageEditorSplitPreview {
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
        background-color: $pageEditorLight2;
        overflow-y: scroll;
      }
      .PageEditorSplitRight {
        @include pageEditorBackground();
        flex-grow: 0;
        flex-shrink: 0;
        width: 264px;
        height: 100%;
        margin-left: 2px;
        transition: all 0.5s;
        overflow-y: scroll;
      }
    }
  }

  .InputDiv {
    padding: 12px 12px 0px 12px;
    .inputBottomLineLightContainer {
      .inputBottomLineTop {
        padding-bottom: 4px;
        .inputBottomLineTitle {
          @include fontSizeEditorSmall();
        }
      }
      .inputBottomLineLightFlexBox {
        background-color: $pageEditorDark1;
        input {
          background-color: $pageEditorDark1;
          color: $pageEditorLight1;
          @include fontSizeEditorSmall();
          height: auto;
        }

        .react-select-container {
          border-radius: 8px;
          margin: 0px;
          width: 100%;
          input {
            color: $pageEditorLight1 !important;
            @include fontSizeEditorSmall();
            @include editorFont();
            font-size: 13px !important;
            font-weight: 600 !important;
          }
        
          .react-select__control {
            border-width: 0px;
            border-radius: 8px;
            box-shadow: none;
            background-color: $pageEditorDark1;
        
            .react-select__value-container {
              
              .react-select__single-value {
                @include fontSizeEditorSmall();
                color: $pageEditorLight1;
                margin-left: 6px;
              }
            }
            .react-select__indicators {
        
              .react-select__indicator-separator {
                background-color: $pageEditorDark3;
              }
              .react-select__dropdown-indicator {
                color: $pageEditorLight1;
              }
            }
          }
          .react-select__menu {
            border-radius: 8px;
            overflow: hidden;
            border-width: 0px;
            @include materialThickShadow();
            background-color: $pageEditorDark1;
            @include fontSizeEditorSmall();
        
            .react-select__menu-list {
        
              .react-select__option {
                background-color: $pageEditorDark1;
                @include fontSizeEditorSmall();
                color: $pageEditorLight2;
              }
              .react-select__option--is-focused {
                background-color: $pageEditorDark3;
                color: $pageEditorLight1;
              }
              .react-select__option--is-selected {
                background-color: $pageEditorDark3;
                color: $pageEditorLight1;
              }
            }
          }
        }
      }
      .inputBottomLineInvalid {
        display: none;
        visibility: visible;
      }
    }
    .inputBottomLineLightContainerInvalid {
      .inputBottomLineInvalid {
        display: block;
        visibility: visible;
      }
    }
    .inputBottomLineLightFlexBoxColorPicker {
      position: relative;
      .inputPickColorButton {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 34px;
        padding: 0px 10px;
        @include pushableNoSelect();

        .inputPickColorButtonBox {
          width: 12px;
          height: 12px;
          border: 1px solid $pageEditorLight1;
          margin-right: 8px;
        }
        .inputPickColorButtonValue {
          @include fontSizeEditorSmall();
          color: $pageEditorLight1;
        }
      }
      .inputPickColorButtonPopover {
        position: fixed;
        transform: translate(23%, 56%);
        z-index: 2000;
      }
      .inputPickColorButtonCover {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 1999;
      }
    }

    .InputDivFontBottom {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      padding-top: 4px;
      .InputFontSize {
        padding-right: 8px;
        width: 70px;
        flex-grow: 0;
        flex-shrink: 0;
      }
      .InputFontColor {
        width: 100px;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }
}

.PageRender {
  width: 100%;
  height: 100%;
  font-weight: normal;
  position: relative;
  .PageRenderSizePicker {
    display: block;
    position: fixed;
    bottom: 20px;
    transform: translateX(20px);
    margin: 0px auto;
    background-color: $pageEditorDark2;
    border-radius: 20px;
    padding: 0px 0px;
    overflow: hidden;
    @include materialThickShadow();

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .PageRenderSizePickerSize {
      @include fontSizeEditorMedium();
      color: $pageEditorLight1;
      padding: 8px 12px;
      @include pushableNoSelect();
    }
    .PageRenderSizePickerSizeMedium {
      border-right: 1px solid $pageEditorDark1;
      border-left: 1px solid $pageEditorDark1;
    }
    .PageRenderSizePickerSizeSelected {
      background-color: $pageEditorDark3;
    }
  }

  .PageRenderSection {
    .PageRenderSectionHeader {
      height: 60px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      .PageRenderSectionHeaderLeft {
        padding-left: 32px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        img {
          height: 40px;
          object-fit: contain;
        }
        .PageRenderSectionHeaderLeftLink {
          padding: 6px 24px;
          @include pushableNoSelect();
          font-weight: bold;
        }
      }
      .PageRenderSectionHeaderRight {
        margin-right: 32px;
        font-weight: bold;
        padding: 8px 16px;
        border-radius: 16px;
        @include pushableNoSelect();
      }
    }
    .PageRenderSectionMain {
      max-width: 1200px;
      margin: 0px auto;
      img {
        width: 100%;
      }
      h1 {
        display: none;
      }
      h2 {
        display: none;
      }
    }
    .PageRenderSectionText {
      padding: 50px 20px;
      max-width: 800px;
      margin: 0px auto;
      .PageRenderSectionTextHeader {
        font-weight: bold;
        padding-bottom: 32px;
      }
      .PageRenderSectionTextBody {
        font-weight: normal;
      }
    }
    .PageRenderSectionTextParts {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;
      max-width: 800px;
      margin: 0px auto;
      padding: 0px 20px;
      .PageRenderSectionTextPart {
        width: calc(50% - 20px);
        padding: 25px 10px;
        .PageRenderSectionTextPartHeader {
          font-weight: bold;
        }
        .PageRenderSectionTextPartBody {
          font-weight: normal;
        }
      }
    }
    .PageRenderSectionActivities {
      max-width: 800px;
      margin: 0px auto;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      .PageRenderSectionActivitiesHeader {
        font-weight: bold;
        padding: 50px 0px;
      }
      .PageRenderSectionActivitiesContainer {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;
        .PageRenderSectionActivity {
          padding-bottom: 20px;

          .PageRenderSectionActivityLargeContainer {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            height: 220px;
            overflow: hidden;
            .PageRenderSectionActivityLargeLeft {
              width: 40%;
              height: calc(100% - 40px);
              margin: 10px;
              padding: 10px;
              display: flex;
              flex-flow: column nowrap;
              justify-content: center;
              align-items: center;
              .PageRenderSectionActivityLargeLeftHeader {
                font-weight: bold;
                font-size: 22px;
                padding-bottom: 10px;
              }
              .PageRenderSectionActivityLargeLeftBody {
                font-weight: normal;
              }
            }
            img {
              width: 60%;
              flex-shrink: 1;
              flex-grow: 1;
            }
          }

          .PageRenderSectionActivitySmallContainer {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: flex-start;
            img {
              width: 100%;
            }
            .PageRenderSectionActivitySmallHeader {
              text-align: left;
              font-weight: bold;
              padding: 10px 0px 4px 0px;
            }
            .PageRenderSectionActivitySmallBody {
              text-align: left;
            }
          }
        }
        .PageRenderSectionActivityLarge {
          width: 100%;
        }
        .PageRenderSectionActivitySmall {
          width: calc(33% - 20px);
          padding: 10px 0px;
        }
      }
    }
    .PageRenderSectionMap {
      padding: 50px 0px 0px 0px;
      max-width: 1200px;
      margin: 0px auto;
      .PageRenderSectionMapHeader {
        font-weight: bold;
        padding: 50px 0px 20px 0px;
      }
      img {
        width: 100%;
      }
    }
    .PageRenderSectionGetTickets {
      padding: 50px 0px 20px 0px;
      text-align: center;
      max-width: 800px;
      margin: 0px auto;

      .PageRenderSectionGetTicketsButton {
        font-weight: bold;
        width: 320px;
        padding: 16px 16px;
        margin: 0px auto;
        border-radius: 32px;
        @include pushableNoSelect();
      }
      .PageRenderSectionGetTicketsBody {
        font-weight: normal;
        padding: 20px 0px 20px 0px;
      }
    }
  }
}