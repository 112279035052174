@font-face {
    font-family: Karla;
    src: url('fonts/Karla-Regular.ttf');
    font-weight:300;
    font-style: normal;
}
@font-face {
    font-family: Karla;
    src: url('fonts/Karla-Bold.ttf');
    font-weight:600;
    font-style: normal;
}
@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Regular.ttf');
    font-weight:300;
    font-style: normal;
}
@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Bold.ttf');
    font-weight:600;
    font-style: normal;
}

@font-face {
    font-family: Syncopate;
    src: url('fonts/Syncopate-Regular.ttf');
    font-weight:300;
    font-style: normal;
}
@font-face {
    font-family: Syncopate;
    src: url('fonts/Syncopate-Bold.ttf');
    font-weight:600;
    font-style: normal;
}
