// main: ../../App.scss

.AmazonPay {
	background: var(--whiteColor);
	height: calc(100% - 64px);
	padding: 32px;
	.AmazonPayTitle {
		@include fontSizeTitle();
		color: var(--darkColor);
		padding-bottom: 12px;
	}
	.AmazonPayElements {
		background: var(--offWhiteColor);
		border-radius: 12px;
		min-height: 200px;
		padding: 12px;
		.AmazonPayElement {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;
			padding-bottom: 8px;
			@include fontSizeNormal();
			color: var(--darkColor);
			.AmazonPayElementKey {
				font-weight: bold;
				padding-right: 12px;
				min-width: 100px;
				text-align: left;
			}
			.AmazonPayElementValue {

			}
		}
	}
}
