@import "./resources/partials/_fonts.scss";

$whiteColor: #FFFFFF;

@mixin mainFont() {
  font-family: Karla;
  word-break: break-word;
}
@mixin editorFont() {
  font-family: Lato;
  word-break: break-word;
}
//Main font styles
@mixin fontSizeTitle() {
  @include mainFont();
  font-size: 32px;
  font-weight: 600;
}
@mixin fontSizeStat() {
  @include mainFont();
  font-size: 24px;
  font-weight: 300;
}
@mixin fontSizeMid() {
  @include mainFont();
  font-size: 22px;
  font-weight: 600;
}
@mixin fontSizeMedium() {
  @include mainFont();
  font-size: 17px;
  font-weight: 600;
}
@mixin fontSizeNormal() {
  @include mainFont();
  font-size: 17px;
  font-weight: 300;
}
@mixin fontSizeSmall() {
  @include mainFont();
  font-size: 13px;
  font-weight: 600;
}
@mixin fontSizeTiny() {
  @include mainFont();
  font-size: 10px;
  font-weight: 300;
}
//Editor font styles
@mixin fontSizeEditorTitle() {
  @include editorFont();
  font-size: 32px;
  font-weight: 600;
}
@mixin fontSizeEditorStat() {
  @include editorFont();
  font-size: 24px;
  font-weight: 300;
}
@mixin fontSizeEditorMid() {
  @include editorFont();
  font-size: 22px;
  font-weight: 600;
}
@mixin fontSizeEditorMedium() {
  @include editorFont();
  font-size: 17px;
  font-weight: 600;
}
@mixin fontSizeEditorNormal() {
  @include editorFont();
  font-size: 17px;
  font-weight: 300;
}
@mixin fontSizeEditorSmall() {
  @include editorFont();
  font-size: 13px;
  font-weight: 600;
}
@mixin fontSizeEditorTiny() {
  @include editorFont();
  font-size: 10px;
  font-weight: 300;
}

/// Creates a material shadow
@mixin materialSmallShadow() {
  //use this to show a little separation between components
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}
@mixin materialThickShadow() {
  //use this to show a little separation between components
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
}
@mixin materialSpreadShadow() {
  //use this for highlighting a button or element on the screen
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}

@mixin pushable() {
	transition: all 0.5s;
	cursor: pointer;

	&:active {
		transform: scale(0.9);
	}
}
@mixin pushableNoSelect() {
	transition: all 0.5s;
	cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

	&:active {
		transform: scale(0.9);
	}
}
@mixin pushableSimple() {
	transition: all 0.5s;
	cursor: pointer;
}
@mixin pushableLoading() {
	transition: all 0.5s;
	cursor: wait;

	&:active {
		transform: scale(1.0);
	}
}
@mixin noselect() {
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
@mixin select() {
	-webkit-touch-callout: text; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
     -khtml-user-select: text; /* Konqueror HTML */
       -moz-user-select: text; /* Firefox */
        -ms-user-select: text; /* Internet Explorer/Edge */
            user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
@mixin draggable() {
	-webkit-app-region: drag;
}
@mixin notDraggable() {
	-webkit-app-region: no-drag;
}
@mixin hideScrollbar() {
	::-webkit-scrollbar {
		height: 0px;
	}
}
@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
@mixin multiLineEllipsis($lineCount) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: $lineCount;
  -webkit-box-orient: vertical;
  white-space: normal;
}
@mixin blueGreenGradient() {
  background: rgb(3,169,244);
  background: -moz-linear-gradient(90deg, rgba(3,169,244,1) 0%, rgba(138,195,73,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(3,169,244,1) 0%, rgba(138,195,73,1) 100%);
  background: linear-gradient(90deg, rgba(3,169,244,1) 0%, rgba(138,195,73,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#03a9f4",endColorstr="#0afac4",GradientType=1);
}

html {
  width: 100%;
  height: 100%;
  background: var(--whiteColor);

  //SET THE THEME COLORS.
  --darkColor: #000000;
  --darkTransparentColor90: #0000001A;
  --darkTransparentColor30: #000000B3;
  --blueColor: #03A9F4;
  --blueColorLighten45: #def4ff;
  --blueDarkenColor10: #0286c2;
  --dashboardColor: #3D39CC;
  --dashboardColor2: #9E9CE5;
  --dashboardColor2Transparent80: #9E9CE532;
  --grayColor: #8E8E93;
  --grayLightColor: #B8B8B7;
  --grayLineColor: #D8D8D8;

  --greenColor: #8AC349;
  --greenTransparentColor80: #8AC34932;
  --greenLightenColor30: #8bc34a;
  --offWhiteColor: #F6F6F6;
  --offWhiteTransparentColor0: #F6F6F600;
  --redColor: #FF3B30;
  --tokenColor: #D6CD00;
  --whiteColor: #FFFFFF;
  --whiteTransparentColor00: #FFFFFF00;
  --whiteTransparentColor03: #FFFFFFF8;
  --whiteTransparentColor10: #FFFFFFE6;
  --whiteTransparentColor30: #FFFFFFB4;
  --whiteTransparentColor90: #FFFFFF1A;
  --payPalColor: #0070BA;
  --venmoColor: #3894D0;
  --amazonColor: #232F3E;

  --tokenColorBlue: #67A7EE;
  --tokenColorGreen: #6DC887;
  --tokenColorOrange: #F6C26F;
  --tokenColorPink: #EE67BD;
  --tokenColorRed: #EE677E;

  --startEngineGreen: #01D1B8;
}
html.darkTheme {

  //SET THE DARK THEME COLORS
  --darkColor: #FFFFFF;
  --darkTransparentColor90: #FFFFFF1A;
  --darkTransparentColor30: #FFFFFFB3;
  --grayLineColor: #8E8E93;

  --offWhiteColor: #2C2C2E;
  --offWhiteTransparentColor0: #2C2C2E00;
  --redColor: #FF453A;
  --tokenColor: #D6CD00;
  --whiteColor: #000000;
  --whiteTransparentColor00: #00000000;
  --whiteTransparentColor03: #000000F8;
  --whiteTransparentColor10: #000000E6;
  --whiteTransparentColor30: #000000B4;
  --whiteTransparentColor90: #0000001A;
}

body {
  width: 100%;
  height: 100%;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;

  background: var(--whiteColor);
  /*background: rgb(138,195,73);
  background: -moz-linear-gradient(225deg, rgba(138,195,73,1) 0%, rgba(3,169,244,1) 100%);
  background: -webkit-linear-gradient(225deg, rgba(138,195,73,1) 0%, rgba(3,169,244,1) 100%);
  background: linear-gradient(225deg, rgba(138,195,73,1) 0%, rgba(3,169,244,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8ac349",endColorstr="#03a9f4",GradientType=1);*/

  .App-header {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
    font-size: calc(10px + 2vmin);
    color: white;
    .App-logo {
      height: 150px;
      pointer-events: none;
    }
    .App-logo-text {
      height: 100px;
      pointer-events: none;
      padding: 40px 0px;
    }
    .Spacer {
      flex-grow: 1;
    }
    .App-Bottom {
      .App-Store {
        cursor: pointer;
        width: 120px;
        padding-bottom: 20px;
      }
      .App-Links {
        padding-bottom: 20px;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;

        .App-Link {
          color: var(--grayColor);
          font-size: 32px;
          font-weight: 500;
          text-decoration: none;
          transition: all 300ms;

          &:hover {
            color: var(--whiteColor);
          }
        }
        .App-Link-Separator {
          width: 1px;
          height: 32px;
          background: var(--whiteColor);
          margin: 0px 10px;
        }
      }
    }
  }
}

//INPUTS
.ButtonDiv {
  margin: 10px auto 0px auto;
}
.ForgotPasswordDiv {
  margin: 10px auto 0px auto;
}
.InputDiv {
  padding: 10px 0px 0px 0px;
}
.InputDivHideInvalid {
  .inputBottomLineLightContainer {
    .inputBottomLineInvalid {
      min-height: 0px;
    }
  }
}
.InputExpander {
  text-align: left;
  max-width: 420px;
  margin: 0px auto 20px auto;
  cursor: pointer;
  @include fontSizeNormal();
  font-weight: 400;
}
.InputBreakDiv {
  padding: 0px 0px 0px 0px;
}
.InputSection {
  margin: 0px auto;
  padding: 20px 0px 0px;
  @include fontSizeStat();
  color: var(--darkColor);
  text-align: left;
  width: 480px;

  @media (max-width: 480px) {
    width: 280px;
  }
}
.SettingsToggle {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;

  .SettingsToggleName {
    padding-left: 15px;
    @include fontSizeNormal();
    color: var(--grayColor);
  }
}
.inputBottomLineLightContainer {
  width: 100%;
  text-align: center;
  display: inline-block;

  .inputBottomLineTitle {
    @include fontSizeSmall();
    text-transform: none;
    text-align: left;
    padding: 0px 12px 0px 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .inputBottomLineDescription {
    @include fontSizeNormal();
    text-align: left;
    padding: 0px 12px 4px 12px;
  }
  .inputBottomLineNote {
    @include fontSizeTiny();
    font-weight: 300;
    color: var(--grayColor);
    text-align: left;
    padding: 10px 12px 0px 0px;
  }
  .inputBottomLineFee {
    @include fontSizeTiny();
    font-weight: 400;
    color: var(--grayColor);
    text-align: left;
    padding: 10px 12px 0px 0px;
  }

  .inputBottomLineInvalid {
    @include fontSizeSmall();
    text-transform: none;
    min-height: 17px;
    visibility: hidden;
    color: var(--redColor);
    text-align: right;
    padding: 0px 12px 0px 12px;
    //margin-top: 5px;
  }

  .inputBottomLineLightFlexBox {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px;
    margin: 0px;
    @include fontSizeMedium();
    background-color: var(--offWhiteColor);
    background-image: none;
    text-align: left;
    border-radius: 8px;
    box-shadow: none;
    border: 0px;
    color: var(--darkColor);

    .inputBottomLineIcon {
      height: 20px;
      padding: 0px 12.5px 0px 0px;
      transition: all 500ms ease-in-out;
    }
    .inputBottomLineIconHidden {
      opacity: 0.0;
      width: 0px;
      padding: 0px;
    }

    .manualSelect {
      width: 100%;
      height: 45px;
      -webkit-appearance: none;
      outline: none;
      box-shadow: none;
      border: 0px;
      background-color: var(--offWhiteColor);
      background-image: none;
      margin: 0px;
      padding: 0px 12.5px;
      color: var(--darkColor);
    }
  }
  .inputBottomLineLightFlexBoxClear {
    background: transparent;

    .inputBottomLineIcon {
      padding-left: 10px;
    }
    .inputBottomLineIconHidden {
      padding: 0px;
    }
  }
  .inputBottomLineMobileDate {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 0px;
    margin: 0px;
    @include fontSizeMedium();
    background-color: var(--offWhiteColor);
    background-image: none;
    text-align: left;
    border-radius: 8px;
    box-shadow: none;
    border: 0px;
    color: var(--darkColor);

    input {
      vertical-align: middle;
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }

    .inputBottomLineIcon {
      height: 20px;
      padding: 0px 12.5px 0px 0px;
      transition: all 500ms ease-in-out;
    }
    .inputBottomLineIconHidden {
      opacity: 0.0;
      width: 0px;
      padding: 0px;
    }
  }
  .GoogleMapsDropdownContainer {
    margin: 5px;
    @include fontSizeNormal();
    color: var(--grayColor);

    .GoogleMapsDropdownContainerLoading {
      display: none;
    }
    .GoogleMapsItem, .GoogleMapsItemActive {
      @include pushable();
      padding: 5px;
      border-radius: 8px;
    }
    .GoogleMapsItemActive {
      background: var(--offWhiteColor);
    }
  }

  .inputBottomLineLight {
    display: inline-block;
    width: 100%;
    height: 45px;
    padding: 9px 12.5px 9px 12.5px;
    margin: 0px;
    @include fontSizeMedium();
    background-color: var(--offWhiteColor);
    background-image: none;
    text-align: left;
    border-radius: 8px;
    box-shadow: none;
    border: 0px;
    color: var(--darkColor);

    &:focus {
      outline: 0px !important;
    }
  }
  .inputBottomLineBlockLight {
  	height: 1px;
  	width:calc(100% - 24px);
  	margin:0px 0px 0px 12px;
  	background-color: var(--darkColor);
  	transition: background-color 0.5s ease, width 0.5s ease;
  }
  .inputBottomLineLight:focus ~ .inputBottomLineBlockLight {
  	background-color: var(--grayColor);
  	width:calc(100% - 24px);
  }

  .inputBottomLineCheckbox {
    display: flex;
    flex-flow: row nowrap;
    padding: 4px 12px 0px 12px;
    justify-content: flex-start;
    align-items: stretch;

    //this is the thing we are clicking
    label {
      width: 22px;
      height: 22px;
      border-radius: 4px;
      @include pushable();
      transition: all 300ms;

      .dualImageSingle {
        margin: 0px;
        vertical-align: middle;
        width: 14px;
      }
    }
    .inputBottomLineCheckboxTrue {
      background: var(--darkColor);
    }
    .inputBottomLineCheckboxFalse {
      background: var(--offWhiteColor);
    }
    span {
      @include fontSizeMid();
      color: var(--offWhiteColor);
      padding: 0px 0px 0px 16px;
    }
    //hide the input
    input {
      border: 0;
      clip: rect(0 0 0 0);
      clippath: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }
  .inputBottomLineCheckboxLight {
    .inputBottomLineCheckboxTrue {
      background: var(--darkColor);
    }
    .inputBottomLineCheckboxFalse {
      background: var(--whiteColor);
      border: 1px solid var(--grayColor);
    }
    span {
      color: var(--grayColor);
    }
  }
}


.inputBottomLineLightContainerInvalid {

  .inputBottomLineInvalid {
    visibility: visible;
  }

  .inputBottomLineLight {
    color: var(--redColor);
  }
  .inputBottomLineBlockLight {
    //background-color: var(--redColor);
  }
  .inputBottomLineLight:focus ~ .inputBottomLineBlockLight {
  	background-color: var(--redColor);
  }
}
.inputBottomLineLightContainerValid {

  .inputBottomLineLight {
    color: var(--darkColor);
  }
  .inputBottomLineBlockLight {
    //background-color: var(--redColor);
  }
  .inputBottomLineLight:focus ~ .inputBottomLineBlockLight {
  	background-color: var(--darkColor);
  }
}

.FileSelectorDiv {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  .FileSelectorDivImageContainer {
    width: 116px;
    height: 116px;
    border-radius: 16px;
    margin: 0px 16px 0px 0px;
    padding: 8px;
    background: var(--offWhiteColor);
    position: relative;

    .FileSelectorDivImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 16px;
    }

    .FileSelectorDivImageX {
      position: absolute;
      right: -16px;
      top: -12px;

      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }
  }
  .FileSelectorDivButton {
    @include fontSizeMedium();
    color: var(--whiteColor);
    border-radius: 30px;
    padding: 12px 22px;
    margin: 12px 0px;
    background: var(--darkColor);
    @include pushable();
    @include noselect();
  }

  .FileSelectorUploadInput {
    width: 0.1px;
  	height: 0.1px;
  	opacity: 0;
  	overflow: hidden;
  	position: absolute;
  	z-index: -1;
  }
  .FileSelectorUploadInputLabel {
    width: 100%;
    height: 100%;
    padding: 7px 20px;
    margin: 0px;
    @include fontSizeMedium();
    color: var(--grayColor);
    cursor: pointer;
    transition: background-color 0.5s, box-shadow 0.5s, color 0.5s;
    vertical-align: middle;
  }
}

.FullScreenFileSelector {
  position: fixed;
  z-index: 102;
  left: 0px;
  top: 0px;
  width: calc(100% - 64px);
  height: calc(100% - 64px);
  background: var(--whiteTransparentColor03);
  overflow: scroll;
  padding: 32px;
  text-align: center;

  .FullScreenFileSelectorButton {
    @include fontSizeMedium();
    color: var(--whiteColor);
    border-radius: 30px;
    padding: 12px 30px;
    margin: 12px 0px;
    background: var(--grayColor);
    @include pushable();
    @include noselect();
    display: inline-block;
  }

  .FullScreenFileSelectorFileLoading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .FullScreenFileSelectorTitle {
    @include fontSizeTitle();
    color: var(--darkColor);
  }

  .FullScreenFileSelectorDescription {
    @include fontSizeNormal();
    color: var(--grayColor);
    padding-top: 16px;
  }

  .FullScreenFileSelectorFileTypes {
    @include fontSizeSmall();
    color: var(--darkColor);
    font-weight: 600;
    padding-top: 8px;
  }
  .FullScreenFileSelectorFileUploadButtons {
    
    .FullScreenFileSelectorButton {
      @include fontSizeMedium();
      color: var(--whiteColor);
      border-radius: 30px;
      padding: 12px 30px;
      margin: 12px auto;
      background: var(--grayColor);
      @include pushable();
      @include noselect();
      display: block;
      width: 140px;
    }
    .FullScreenFileSelectorButtonUpload {
      background-color: var(--blueColor);
    }
    .FullScreenFileSelectorButtonCancel {
      background-color: var(--grayLightColor);
    }
  }

  .FullScreenFileUploaderTitle {
    @include fontSizeTitle();
    color: var(--darkColor);
    padding-top: 30px;
    text-align: center;
  }
  .FullScreenFileUploaderInput {
    width: 0.1px;
  	height: 0.1px;
  	opacity: 0;
  	overflow: hidden;
  	position: absolute;
  	z-index: -1;
  }
  .FullScreenFileUploaderInput + label {
    @include fontSizeNormal();
    font-size: 1px;
    color: var(--whiteColor);
    border-radius: 22px;
    cursor: pointer;
    transition: background-color 0.5s, box-shadow 0.5s, color 0.5s;
    vertical-align: top;

    img {
      width: 100%;
      height: 100%;
      opacity: 1.0;
      @include pushable();
      transition: opacity 0.5s, transform 0.5s;

      &:hover {
        opacity: 0.6;
      }
      &:active {
        opacity: 0.4;
      }
    }
  }
  .FullScreenFileUploaderInput:focus + label,
  .FullScreenFileUploaderInput + label:hover {
    //background-color: lighten($purpleColor, 10%);
  }

  .FullScreenFileSelectorContainer {
    padding-top: 32px;
  }
  .FullScreenFileSelectorFiles {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transition: all 500ms;

    .FullScreenFileSelectorFile {
      width: 200px;
      height: 200px;
      cursor: pointer;
      margin: 0px 20px 40px 20px;
      position: relative;

      .FullScreenFileSelectorFileImage {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
        opacity: 1.0;
        @include pushable();
        transition: opacity 0.5s, transform 0.5s;

        &:hover {
          opacity: 0.6;
        }
        &:active {
          opacity: 0.4;
        }
      }

      .FullScreenFileSelectorFileX {
        position: absolute;
        width: 20px;
        height: 20px;
        top: -10px;
        left: -10px;
        z-index: 100;
      }
    }
  }
}
.FullScreenFileSelectorUploading {
  background: var(--blueColorLighten45);

  .FullScreenFileSelectorFiles {
    opacity: 0.1;
  }
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-scale-multiple,
.la-ball-scale-multiple > div {
    position: relative;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
.la-ball-scale-multiple {
    display: block;
    text-align: center;
    margin: 0px auto;
    font-size: 0;
    color: var(--whiteColor);
}
.la-ball-scale-multiple.la-dark {
    color: var(--darkColor);
}
.la-ball-scale-multiple.la-purple {
    color: var(--blueColor);
}
.la-ball-scale-multiple.la-light {
    color: var(--offWhiteColor);
}
.la-ball-scale-multiple > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}
.la-ball-scale-multiple {
    width: 32px;
    height: 32px;
}
.la-ball-scale-multiple > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    opacity: 0;
    -webkit-animation: ball-scale-multiple 1s 0s linear infinite;
       -moz-animation: ball-scale-multiple 1s 0s linear infinite;
         -o-animation: ball-scale-multiple 1s 0s linear infinite;
            animation: ball-scale-multiple 1s 0s linear infinite;
}
.la-ball-scale-multiple > div:nth-child(2) {
    -webkit-animation-delay: .2s;
       -moz-animation-delay: .2s;
         -o-animation-delay: .2s;
            animation-delay: .2s;
}
.la-ball-scale-multiple > div:nth-child(3) {
    -webkit-animation-delay: .4s;
       -moz-animation-delay: .4s;
         -o-animation-delay: .4s;
            animation-delay: .4s;
}
.la-ball-scale-multiple.la-sm {
    width: 16px;
    height: 16px;
}
.la-ball-scale-multiple.la-sm > div {
    width: 16px;
    height: 16px;
}
.la-ball-scale-multiple.la-2x {
    width: 64px;
    height: 64px;
}
.la-ball-scale-multiple.la-2x > div {
    width: 64px;
    height: 64px;
}
.la-ball-scale-multiple.la-3x {
    width: 96px;
    height: 96px;
}
.la-ball-scale-multiple.la-3x > div {
    width: 96px;
    height: 96px;
}
/*
 * Animation
 */
@-webkit-keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@-moz-keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        -moz-transform: scale(0);
             transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        -moz-transform: scale(1);
             transform: scale(1);
    }
}
@-o-keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        -o-transform: scale(0);
           transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        -o-transform: scale(1);
           transform: scale(1);
    }
}
@keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
           -moz-transform: scale(0);
             -o-transform: scale(0);
                transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1);
           -moz-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);
    }
}


@keyframes ldio-2yvf4wa6rj5 {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.45,0,0.9,0.55)
  }
  0% {
    transform: translate(0,0)
  }
  50% {
    transform: translate(0,50px);
    animation-timing-function: cubic-bezier(0,0.45,0.55,0.9);
  }
  100% {
    transform: translate(0,0);
  }
}

.loadingio-spinner-ball-cm9vm1ej40l {
  width: 88px;
  height: 88px;
  display: inline-block;
  overflow: hidden;
  background: none;

  .ldio-2yvf4wa6rj5 div {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--greenColor);
    left: 29px;
    top: 10px;
    animation: ldio-2yvf4wa6rj5 1s linear infinite;
  }
  .ldio-2yvf4wa6rj5 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-2yvf4wa6rj5 div { box-sizing: content-box; }
}
.loadingio-spinner-ball-cm9vm1ej40l-dark {
  .ldio-2yvf4wa6rj5 div {
    background: #000000;
  }
}
.loadingio-spinner-ball-cm9vm1ej40l-dark {
  .ldio-2yvf4wa6rj5 div {
    background: var(--darkColor);
  }
}
.loadingio-spinner-ball-cm9vm1ej40l-light {
  .ldio-2yvf4wa6rj5 div {
    background: var(--whiteColor);
  }
}
.loadingio-spinner-ball-cm9vm1ej40l-medium {
  width: 200px;
  height: 200px;
  .ldio-2yvf4wa6rj5 div {
    width: 60px;
    height: 60px;
    left: 70px;
    top: 20px;
  }
}
.loadingio-spinner-ball-cm9vm1ej40l-large {
  width: 300px;
  height: 300px;
  .ldio-2yvf4wa6rj5 div {
    width: 90px;
    height: 90px;
    left: 105px;
    top: 30px;
  }
}

//Animations
@mixin grayLoading {
  background: linear-gradient(270deg, var(--offWhiteColor), var(--grayLineColor), var(--offWhiteColor));
  background-size: 400% 400%;

  -webkit-animation: GrayLoadingAnimation 3s ease-in-out infinite;
  -moz-animation: GrayLoadingAnimation 3s ease-in-out infinite;
  -o-animation: AnimationName 3s ease-in-out infinite;
  animation: GrayLoadingAnimation 3s ease-in-out infinite;
}

@-webkit-keyframes GrayLoadingAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes GrayLoadingAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes GrayLoadingAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@import "./resources/partials/_legal.scss";
@import "./resources/partials/_geoji.scss";
@import "./resources/partials/_amazonpay.scss";
@import "./resources/partials/_handle.scss";
@import "./resources/partials/_demo.scss";
@import "./resources/partials/_homepage.scss";
@import "./resources/partials/_dashboard.scss";
@import "./resources/partials/_pageeditor.scss";
