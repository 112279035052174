// main: ../../App.scss

// The dark themed sidebar for the dashboard
.Legal {
  text-align: left;
  padding: 20px;
  background: var(--whiteColor);
  color: var(--darkColor);

  .LegalTitle {
    @include fontSizeTitle();
  }
  .LegalDate {
    @include fontSizeTiny();
    padding-bottom: 20px;
  }
  .LegalSection {
    @include fontSizeMedium();
    font-weight: 600;
    padding-top: 20px;
  }
  .LegalSectionLarge {
    @include fontSizeStat();
    font-weight: 600;
    padding-top: 20px;
  }
  .LegalBody {
    @include fontSizeNormal();

    a {
      color: var(--blueColor);
      font-weight: bold;
    }
  }
}
