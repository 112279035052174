// main: ../../App.scss

$mainMaxWidth: 1200px;

@media only screen and (max-width: 800px) {
  #gradient-canvas {
    margin: 0px;
    width: 100%;
    border-radius: 0px;
  }
}

.HomeHeaderSignIn {
  .HomeHeaderSignInLink {
    text-decoration: none;
  }
  .HomeHeaderSignInProfile {
    @include fontSizeMedium();
    color: var(--darkColor);
    background-color: var(--whiteColor);
    text-decoration: none;
    border-radius: 19px;
    border: 2px solid var(--offWhiteColor);
    @include pushable();
    @include noselect();
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--offWhiteColor);
    }

    .HomeHeaderSignInProfileHamburger {
      height: 16px;
      width: 16px;
      object-fit: contain;
      margin: 0px 12px;
    }
    .URLImage {
      display: block;
      height: 30px;
      width: 30px;
      margin: 3px 3px;
      object-fit: cover;
      border-radius: 15px;
    }
    .HomeHeaderSignInProfilePhoto {
      height: 30px;
      width: 30px;
      object-fit: cover;
      border-radius: 15px;
    }
    .HomeHeaderSignInProfileName {
      @include fontSizeSmall();
      color: var(--darkColor);
      margin: 3px 12px 3px 6px;
      max-width: 108px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .HomeHeaderSignInButton {
    @include fontSizeMedium();
    color: var(--whiteColor);
    background-color: var(--darkColor);
    text-decoration: none;
    padding: 8px 22px;
    border-radius: 12px;
    @include pushable();
    @include noselect();
  }
}
@media only screen and (max-width: 700px) {
  .HomeHeaderSignIn {
    display: none;
  }
}

.AppHome {
  text-align: center;
  height: auto;
  width: 100%;
  position: relative;

  background: var(--whiteColor);
  @include fontSizeNormal();
  color: var(--darkColor);

  a {
    text-decoration: none;
  }

  p, h1, h2, h3, h4, h5, h6 {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .HomeHeader {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    max-width: $mainMaxWidth - 64px;
    margin: 0px auto;
    padding: 22px 32px;
    position: relative;

    .HomeHeaderLogo {
      .HomeHeaderLogoImage {
        height: 44px;
        object-fit: contain;
        @include pushable();
      }
    }

    .HomeHeaderLinks {
      .HomeHeaderLink {
        display: inline-block;
        @include fontSizeMedium();
        color: var(--grayColor);
        text-decoration: none;
        padding-left: 16px;
        @include pushable();
        @include noselect();

        &:hover {
          color: var(--darkColor);
        }
      }
      .HomeHeaderLinkSelected {
        color: var(--darkColor);
      }
      .HomeHeaderLinkPricing {
        color: var(--grayLightColor);
        text-decoration: line-through;
        position: relative;

        &:hover {
          color: var(--grayLightColor);
        }

        .HomeHeaderLinkPricingFree {
          position: absolute;
          top: -20px;
          left: 24px;
          transform: rotate(-19deg);
          color: var(--greenColor);
        }
      }
    }

    .HomeHeaderIcon {
      display: none;
      @include pushable();
      height: 34px;
      width: 44px;

      .HomeHeaderIconImage {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    @media only screen and (max-width: 700px) {
      .HomeHeaderLinks {
        display: none;
      }

      .HomeHeaderSignIn {
        display: none;
      }

      .HomeHeaderIcon {
        display: block;
      }
    }
  }

  .HomeHeaderOpen {
    margin: 0px auto;
    padding: 22px 32px;
    position: fixed;
    background: var(--whiteTransparentColor03);
    height: calc(100% - 44px);
    width: calc(100% - 64px);
    z-index: 10000;
    @include fadeIn(0.3s);

    .HomeHeaderOpenContent {
      text-align: left;

      .HomeHeaderOpenContentTop {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        .HomeHeaderLogo {
          .HomeHeaderLogoImage {
            height: 44px;
            object-fit: contain;
            @include pushable();
          }
        }

        .HomeHeaderIcon {
          @include pushable();
          height: 34px;
          width: 44px;

          .HomeHeaderIconImage {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }

      .HomeHeaderLinks {
        padding: 32px 0px;

        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;

        .HomeHeaderLink {
          @include fontSizeMedium();
          color: var(--darkColor);
          text-decoration: none;
          padding-bottom: 32px;
          @include pushable();
          @include noselect();

          &:hover {
            color: var(--darkColor);
          }
        }
        .HomeHeaderLinkSelected {
          color: var(--darkColor);
        }
        .HomeHeaderLinkPricing {
          color: var(--grayLightColor);
          text-decoration: line-through;
          position: relative;

          .HomeHeaderLinkPricingFree {
            position: absolute;
            text-decoration: none;
            color: var(--greenColor);
            top: -20px;
            left: 8px;
            transform: rotate(-19deg);
          }

          &:hover {
            color: var(--grayLightColor);
          }
        }

        .HomeHeaderLinksBreak {
          width: 100%;
          height: 1px;
          background-color: var(--offWhiteColor);
        }
        .HomeHeaderSignIn2 {
          width: 100%;

          .HomeHeaderSignInLink {
            text-decoration: none;
          }
          .HomeHeaderSignInProfile {
            @include fontSizeMedium();
            color: var(--darkColor);
            background-color: var(--whiteColor);
            text-decoration: none;
            border-radius: 19px;
            margin-top: 32px;
            border: 2px solid var(--offWhiteColor);
            @include pushable();
            @include noselect();
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
        
            &:hover {
              background-color: var(--offWhiteColor);
            }
        
            .HomeHeaderSignInProfileHamburger {
              height: 16px;
              width: 16px;
              object-fit: contain;
              margin: 0px 12px;
            }
            .URLImage {
              display: block;
              height: 30px;
              width: 30px;
              margin: 3px 3px;
              object-fit: cover;
              border-radius: 15px;
            }
            .HomeHeaderSignInProfilePhoto {
              height: 30px;
              width: 30px;
              object-fit: cover;
              border-radius: 15px;
            }
            .HomeHeaderSignInProfileName {
              @include fontSizeSmall();
              color: var(--darkColor);
              margin: 3px 12px 3px 6px;
        
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* number of lines to show */
                      line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
          .HomeHeaderSignInButton {
            @include fontSizeMedium();
            color: var(--whiteColor);
            background-color: var(--darkColor);
            text-decoration: none;
            padding: 8px 22px;
            margin-top: 32px;
            border-radius: 12px;
            text-align: center;
            @include pushable();
            @include noselect();
          }
        }
      }
    }
  }

  .HomePagePageHeader {
    .StartEngineHeaderStretch {
      background: var(--darkColor);
      @include fontSizeNormal();
      color: var(--whiteColor);
  
      .StartEngineHeader {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
  
        max-width: $mainMaxWidth;
        margin: 0px auto;
        padding: 12px 32px;
  
        .StartEngineHeaderImage {
          width: 105px;
          height: 105px;
        }
        .StartEngineHeaderBody {
          margin: 0px 30px;
          text-align: left;
          flex-grow: 1;
          .StartEngineHeaderBodyTitle {
            @include fontSizeTitle();
            padding-bottom: 8px;
          }
          .StartEngineHeaderBodyDescription {
            @include fontSizeMedium();
          }
        }
        .StartEngineHeaderButton {
          @include fontSizeMedium();
          background: var(--startEngineGreen);
          color: var(--whiteColor);
          padding: 16px 20px;
          border-radius: 12px;
          display: block;
          @include pushable();
          @include noselect();
        }
      }
      @media only screen and (max-width: 630px) {
        .StartEngineHeader {
          flex-flow: row wrap;
          justify-content: center;
  
          .StartEngineHeaderImage {
  
          }
          .StartEngineHeaderBody {
            margin: 16px 30px;
            text-align: center;
          }
          .StartEngineHeaderButton {
            margin-bottom: 8px;
          }
        }
      }
    }
  
    .IceSkatingHeaderStretch {
      background: #4AA2D9;
      @include fontSizeNormal();
      color: var(--whiteColor);
  
      .IceSkatingHeader {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
  
        max-width: $mainMaxWidth;
        margin: 0px auto;
        padding: 12px 32px;
  
        .IceSkatingHeaderImage {
          width: 105px;
          height: 105px;
        }
        .IceSkatingHeaderBody {
          margin: 0px 30px;
          text-align: left;
          flex-grow: 1;
          .IceSkatingHeaderBodyTitle {
            @include fontSizeTitle();
            padding-bottom: 8px;
          }
          .IceSkatingHeaderBodyDescription {
            @include fontSizeMedium();
          }
        }
        .IceSkatingHeaderButton {
          @include fontSizeMedium();
          background: #BBE2F2;
          color: var(--darkColor);
          padding: 16px 20px;
          border-radius: 12px;
          display: block;
          @include pushable();
          @include noselect();
        }
      }
      @media only screen and (max-width: 770px) {
        .IceSkatingHeader {
          flex-flow: row wrap;
          justify-content: center;
  
          .IceSkatingHeaderImage {
          }
          .IceSkatingHeaderBody {
            margin: 16px 30px;
            text-align: center;
            width: 100%;
          }
          .IceSkatingHeaderButton {
            margin-bottom: 8px;
          }
        }
      }
    }
    .IceSkatingHeaderStretch2 {
      background: #166a9b;
    }

    .PumpkinHeaderStretch {
      background: #F27407;
      @include fontSizeNormal();
      color: var(--whiteColor);
  
      .PumpkinHeader {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
  
        max-width: $mainMaxWidth;
        margin: 0px auto;
        padding: 12px 32px;
  
        .PumpkinHeaderImage {
          width: 105px;
          height: 105px;
        }
        .PumpkinHeaderBody {
          margin: 0px 30px;
          text-align: left;
          flex-grow: 1;
          .PumpkinHeaderBodyTitle {
            @include fontSizeTitle();
            padding-bottom: 8px;
          }
          .PumpkinHeaderBodyDescription {
            @include fontSizeMedium();
          }
        }
        .PumpkinHeaderButton {
          @include fontSizeMedium();
          background: #fce4ce;
          color: var(--darkColor);
          padding: 16px 20px;
          border-radius: 12px;
          display: block;
          @include pushable();
          @include noselect();
        }
      }
      @media only screen and (max-width: 770px) {
        .PumpkinHeader {
          flex-flow: row wrap;
          justify-content: center;
  
          .PumpkinHeaderImage {
          }
          .PumpkinHeaderBody {
            margin: 16px 30px;
            text-align: center;
            width: 100%;
          }
          .PumpkinHeaderButton {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .HomeSellTickets {
    position: relative;
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 0px 32px;

    #gradient-background {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: calc(100% - 64px);
      margin: 0px 32px;
      height: 100%;
      border-radius: 32px;
      z-index: 10;

      background: #cffb73;
    }
    #gradient-canvas {
        //This sets the colors for the background
        --gradient-color-1: #dbedc9; /*Green*/
        --gradient-color-2: #dbedc9; /*Blue*/
        --gradient-color-3: #c5c3ef; /*Dashboard*/
        --gradient-color-4: #c5c3ef; /*Token Red*/

        position: absolute;
        bottom: 0px;
        left: 0px;
        width: calc(100% - 64px);
        margin: 0px 32px;
        height: 50%;
        border-radius: 0px 0px 32px 32px;
        z-index: 11;
    }
    #gradient-overlay {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: calc(100% - 64px);
      margin: 0px 32px;
      height: calc(50% + 2px);
      border-radius: 0px 0px 32px 32px;
      z-index: 12;

      background: rgb(207,251,115);
      background: -moz-linear-gradient(0deg, rgba(207,251,115,0) 0%, rgba(207,251,115,1) 100%);
      background: -webkit-linear-gradient(0deg, rgba(207,251,115,0) 0%, rgba(207,251,115,1) 100%);
      background: linear-gradient(0deg, rgba(207,251,115,0) 0%, rgba(207,251,115,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cffb73",endColorstr="#cffb73",GradientType=1);
    }

    .HomePageContent {
      color: var(--whiteColor);
      position: relative;
      z-index: 13;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 32px;

      border-radius: 32px;

      .HomePageContentLeft {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100px;
        padding-right: 16px;
        text-align: left;

        .HomePageContentLeftTitle {
          @include fontSizeTitle();
          font-size: 44px;
          color: var(--darkColor);
          text-align: left;
          max-width: 440px;
          margin: 0px;
        }
        .HomePageContentLeftSubtitle {
          @include fontSizeMid();
          font-weight: normal;
          color: var(--darkColor);
          text-align: left;
          padding: 24px 0px 0px 0px;
          max-width: 440px;
        }
        .HomePageContentLeftButton {
          display: inline-block;
          @include fontSizeMedium();
          color: var(--whiteColor);
          border-radius: 16px;
          width: 180px;
          padding: 12px 0px;
          margin-top: 32px;
          text-decoration: none;
          background: var(--darkColor);
          text-align: center;

          @include pushable();
          @include noselect();
        }
        .HomePageContentCompanies {
          width: 100%;
          max-width: 380px;
          margin: 64px 0px;
        }
      }
      .HomePageContentRight {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100px;
        text-align: right;
        padding-left: 16px;
        //height: 100px;

        .HomeSellTicketsGraph1 {
          position: relative;
          top: 80px;
          width: 50%;
        }
        .HomeSellTicketsGraph2 {
          position: relative;
          top: 40px;
          width: 50%;
        }
        .HomeSellTicketsGraphic {
          position: relative;
          width: 100%;
        }

        @media only screen and (max-width: 1200px) {
          .HomeSellTicketsGraph1 {
            top: 80px;
          }
          .HomeSellTicketsGraph2 {
            top: 40px;
          }
        }
        @media only screen and (max-width: 1024px) {
          .HomeSellTicketsGraph1 {
            top: 80px;
          }
          .HomeSellTicketsGraph2 {
            top: 40px;
          }
        }
        @media only screen and (max-width: 944px) {
          .HomeSellTicketsGraph1 {
            top: 0px
          }
          .HomeSellTicketsGraph2 {
            top: 0px;
          }
        }
      }
      @media only screen and (max-width: 800px) {
        flex-flow: row wrap;
        border-radius: 0px;

        .HomePageContentLeft {
          flex-basis: 100%;
          padding-right: 0px;

          .HomePageContentLeftTitle {
            font-size: 32px;
            text-align: center;
            max-width: none;
          }
          .HomePageContentLeftTitle2 {
            font-size: 24px;
            text-align: center;
            max-width: none;
          }
          .HomePageContentLeftSubtitle {
            text-align: center;
            max-width: none;
          }
          .HomePageContentLeftDescription {
            text-align: center;
            max-width: none;
          }

          .HomePageContentLeftButton {
            margin: 32px auto 0px auto;
          }
          .HomePageContentCompanies {
            width: 100%;
            max-width: none;
          }
        }
        .HomePageContentRight {
          height: auto;
          padding-left: 0px;
        }

        .HomeSellTicketsGraph1 {
          top: 0px
        }
        .HomeSellTicketsGraph2 {
          top: 0px;
        }
      }
      @media only screen and (max-width: 600px) {
        .HomePageContentRight {
        }
      }
      @media only screen and (max-width: 444px) {
        .HomePageContentRight {
        }
      }
    }

    @media only screen and (max-width: 800px) {
      padding: 0px;

      #gradient-background {
        width: 100%;
        margin: 0px;
        border-radius: 0px;
      }
      #gradient-canvas {
        width: 100%;
        margin: 0px;
        border-radius: 0px;
      }
      #gradient-overlay {
        width: 100%;
        margin: 0px;
        border-radius: 0px;
      }

      .HomePageContent {
        padding: 32px 16px;
      }
    }
  }

  .HomeTurningPassions {
    position: relative;
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 0px 32px;

    .HomePageContent {
      color: var(--whiteColor);
      position: relative;
      z-index: 13;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 32px;

      border-radius: 32px;

      .HomePageContentLeft {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100px;
        padding-right: 16px;
        text-align: left;

        .HomePageContentLeftTitle {
          @include fontSizeTitle();
          font-size: 44px;
          color: var(--darkColor);
          text-align: left;
          max-width: 440px;
          margin: 0px;
        }
        .HomePageContentLeftSubtitle {
          @include fontSizeMid();
          font-weight: normal;
          color: var(--grayColor);
          text-align: left;
          padding: 24px 0px 0px 0px;
          max-width: 440px;
        }
        .HomePageContentLeftButton {
          display: inline-block;
          @include fontSizeMedium();
          color: var(--whiteColor);
          border-radius: 12px;
          width: 180px;
          padding: 12px 0px;
          margin-top: 32px;
          text-decoration: none;
          @include blueGreenGradient();
          text-align: center;

          @include pushable();
          @include noselect();
        }
      }
      .HomePageContentRight {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100px;
        text-align: right;
        padding-left: 16px;

        .HomeSellTicketsGraphic {
          position: relative;
          width: 100%;
        }
      }

      @media only screen and (max-width: 800px) {
        flex-flow: row wrap;
        border-radius: 0px;

        .HomePageContentLeft {
          flex-basis: 100%;
          padding-right: 0px;
          text-align: center;

          .HomePageContentLeftTitle {
            font-size: 32px;
            text-align: center;
            max-width: none;
          }
          .HomePageContentLeftTitle2 {
            font-size: 24px;
            text-align: center;
            max-width: none;
          }
          .HomePageContentLeftSubtitle {
            text-align: center;
            max-width: none;
          }
          .HomePageContentLeftDescription {
            text-align: center;
            max-width: none;
          }

          .HomePageContentLeftButton {
            margin: 32px auto;
          }
        }
        .HomePageContentRight {
          padding-left: 0px;
          text-align: center;

          .HomeSellTicketsGraphic {
            max-width: 500px;
          }
        }
      }
      @media only screen and (max-width: 600px) {
        .HomePageContentRight {
        }
      }
      @media only screen and (max-width: 444px) {
        .HomePageContentRight {
        }
      }
    }

    @media only screen and (max-width: 800px) {
      padding: 0px;

      .HomePageContent {
        padding: 32px 16px;
      }
    }
  }

  .HomeCompanies {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background: var(--offWhiteColor);

    .HomeCompany {
      max-height: 44px;
      object-fit: contain;
      width: calc(100% - 64px);
      padding: 40px 0px;
    }
  }

  .HomeShotSection {
    position: relative;

    .HomeShotSectionTitle {
      @include fontSizeTitle();
      font-size: 44px;
      color: var(--darkColor);

      max-width: $mainMaxWidth;
      margin: 0px auto;
      padding: 40px 32px;
    }

    .HomeMapImage {
      width: 100%;
      img {
        width: calc(100%);
        object-fit: cover;
        min-height: 300px;
      }
    }

    .HomeShotSectionImage {
      max-width: $mainMaxWidth;
      margin: 0px auto;
      padding: 0px 32px;

      img {
        width: 100%;
        max-height: 530px;
        object-fit: contain;
      }
    }

    .HomeShotSectionBody {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: flex-start;

      max-width: $mainMaxWidth;
      margin: 0px auto;
      padding: 24px 32px;

      .HomeShotSectionBodyPart {
        padding: 16px 16px;
        width: 480px;
        flex-grow: 0;
        flex-shrink: 1;

        .HomeShotSectionBodyPartTitle {
          text-align: left;
          @include fontSizeMedium();
          color: var(--darkColor);
          padding-bottom: 8px;
          border-bottom: 2px solid var(--grayLineColor);
        }
        .HomeShotSectionBodyPartDescription {
          text-align: left;
          padding-top: 8px;
          @include fontSizeNormal();
          color: var(--grayColor);
        }
      }
    }

    @media only screen and (max-width: 800px) {
      .HomeShotSectionTitle {
        font-size: 32px;
        padding: 40px 16px;
      }
      .HomeShotSectionImage {
        padding: 0px 16px;
      }
      .HomeShotSectionBody {
        padding: 24px 16px;
      }
    }

    @media only screen and (max-width: 640px) {
      .HomeShotSectionBody {
        flex-flow: row wrap;

        .HomeShotSectionBodyPart {
          width: 100%;
        }
      }
    }
  }

  .HomeNetworkSection {
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 40px 32px;
    position: relative;

    .HomeNetworkSectionBackground {
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .HomeNetworkSectionContent {
      position: absolute;
      left: 0px;
      right: 0px;
      margin: 0px auto;
      top: calc(50% - 80px);
      bottom: 0px;


      .HomeNetworkSectionContentTitle {
        @include fontSizeTitle();
        font-size: 44px;
        color: var(--darkColor);
        max-width: 430px;
        margin: 0px auto;
        padding-bottom: 24px;
      }
      .HomeNetworkSectionContentDescription {
        @include fontSizeMid();
        font-weight: normal;
        color: var(--grayColor);
      }
    }

    @media only screen and (max-width: 900px) {
      .HomeNetworkSectionContent {
        top: calc(50% - 80px);
        .HomeNetworkSectionContentTitle {
          font-size: 40px;
        }
      }
    }

    @media only screen and (max-width: 800px) {
      .HomeNetworkSectionContent {
        top: calc(50% - 100px);
        .HomeNetworkSectionContentTitle {
          font-size: 32px;
        }
      }
    }

    @media only screen and (max-width: 640px) {
      .HomeNetworkSectionContent {
        top: auto;
        position: relative;
        padding-bottom: 16px;
        .HomeNetworkSectionContentTitle {
          font-size: 32px;
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .HomeNetworkSection {
      padding: 40px 16px;
    }
  }

  .HomeSplitSection {
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 40px 32px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .HomeSplitSectionLeft {
      width: 100%;
      flex-shrink: 1;
      img {
        width: 100%;
        max-height: 530px;
        object-fit: contain;
      }
    }
    .HomeSplitSectionRight {
      width: 100%;
      flex-shrink: 1;
      text-align: left;
      .HomeSplitSectionRightTitle {
        @include fontSizeTitle();
        font-size: 44px;
        color: var(--darkColor);
      }
      .HomeSplitSectionRightDescription {
        @include fontSizeMid();
        font-weight: normal;
        color: var(--grayColor);
        padding-top: 24px;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .HomeSplitSection {
      padding: 40px 16px;
      flex-flow: row wrap-reverse;

      .HomeSplitSectionRight {
        padding-bottom: 40px;

        .HomeSplitSectionRightTitle {
          font-size: 32px;
          text-align: center;
        }
        .HomeSplitSectionRightDescription {
          text-align: center;
        }
      }
    }
  }

  .HomeCalculator {
    padding: 32px;
    margin: 0px auto;
    position: relative;
    z-index: 100;
    background: var(--whiteColor);

    .HomeCalculatorLine {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      @include fontSizeTitle();

      .HomeCalculatorLineGroup {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        padding-bottom: 16px;
      }
      .HomeCalculatorLineSmallHide {
      }
      .HomeCalculatorLineText {
        color: var(--grayColor);
      }
      .HomeCalculatorLineEarn {
        color: var(--darkColor);
        padding: 0px 16px;
      }
      .HomeCalculatorLineSelector {
        padding: 0px 16px 0px 16px;
        color: var(--darkColor);

        .inputBottomLineLightContainer {
          border-bottom: 2px solid var(--darkColor);
          .inputBottomLineLightFlexBox {
            background: var(--whiteColor);
            input {
              background: var(--whiteColor);
              text-align: center;
              height: auto;
              color: var(--darkColor);
              @include fontSizeTitle();
              padding: 0px;
            }
            .react-select-container {
              width: 220px;

              .react-select__control {
                border: 0px;
                box-shadow: 0 0 0 0 white;

                .react-select__value-container {
                  .react-select__single-value {
                    @include fontSizeTitle();
                  }
                }
                .react-select__indicators {
                  .react-select__indicator-separator {
                    display: none;
                  }
                }
              }
              .react-select__control--menu-is-open {
                box-shadow: 0 0 0 0 white;
              }

              .react-select__menu {
                .react-select__menu-list {
                  .react-select__option {
                    &:hover {
                      background: lighten(#8AC349, 30%);
                    }
                  }
                  .react-select__option--is-selected {
                    background: var(--greenColor);
                  }
                }
              }
            }
            select {
              @include fontSizeMid();
              background: var(--whiteColor);
            }
          }
          .inputBottomLineInvalid {
            display: none;
          }
        }
      }
      .HomeCalculatorLineInput {
        padding: 0px 16px 0px 16px;
        color: var(--darkColor);

        .inputBottomLineLightContainer {
          border-bottom: 2px solid var(--darkColor);
          .moneyInputSign {
            @include fontSizeTitle();
            color: var(--darkColor);
          }
          .inputBottomLineLightFlexBox {
            background: var(--whiteColor);
            input {
              background: var(--whiteColor);
              text-align: center;
              height: auto;
              color: var(--darkColor);
              @include fontSizeTitle();
              padding: 0px;
            }
            .react-select-container {
              background: red;
            }
          }
          .inputBottomLineInvalid {
            display: none;
          }
        }
      }
    }

    .HomeCalculatorMap {
      margin: 0px auto;
      width: 640px;
      max-width: 100%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .HomeCalculator {
      padding: 16px;

      .HomeCalculatorLine {
        @include fontSizeMid();

        .HomeCalculatorLineInput {
          padding: 0px 8px 0px 8px;
          color: var(--darkColor);

          .inputBottomLineLightContainer {
            .moneyInputSign {
              @include fontSizeMid();
            }
            .inputBottomLineLightFlexBox {
              input {
                @include fontSizeMid();
              }
            }
            .inputBottomLineInvalid {
              display: none;
            }
          }
        }

        .HomeCalculatorLineSelector {
          padding: 0px 8px 0px 8px;

          .inputBottomLineLightContainer {
            .inputBottomLineLightFlexBox {
              input {
                @include fontSizeMid();
              }
              .react-select-container {
                width: 180px;

                .react-select__control {

                  .react-select__value-container {
                    .react-select__single-value {
                      @include fontSizeMid();
                    }
                  }
                  .react-select__indicators {
                    .react-select__indicator-separator {
                    }
                  }
                }
                .react-select__control--menu-is-open {
                }

                .react-select__menu {
                  .react-select__menu-list {
                    .react-select__option {
                      &:hover {
                      }
                    }
                    .react-select__option--is-selected {
                    }
                  }
                }
              }
            }
            .inputBottomLineInvalid {
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 440px) {
    .HomeCalculator {
      padding: 16px;

      .HomeCalculatorLine {
        @include fontSizeMid();
        //justify-content: flex-start;

        .HomeCalculatorLineSmallHide {
          display: none;
        }
        .HomeCalculatorLineInput {
          padding: 0px 8px 0px 8px;
          color: var(--darkColor);

          .inputBottomLineLightContainer {
            .moneyInputSign {
              @include fontSizeMid();
            }
            .inputBottomLineLightFlexBox {
              input {
                @include fontSizeMid();
              }
            }
            .inputBottomLineInvalid {
              display: none;
            }
          }
        }
      }
    }
  }

  .HomeFeaturedEvents {
    padding: 32px 0px 0px 0px;

    .HomeFeaturedEventsTitle {
      @include fontSizeTitle();
      font-size: 54px;
      color: var(--darkColor);
      text-align: center;
      padding-bottom: 32px;
    }
    .HomeFeaturedEventsList {
      display: block;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      width: calc(100% - 32px);
      padding: 0px 0px 32px 32px;

      .HomeFeaturedEventsListItem {
        display: inline-block;
        vertical-align: top;
        padding-right: 32px;
        @include pushable();
        @include noselect();
        width: 267px;

        .HomeFeaturedEventsListItemTop {
          width: 267px;
          height: 267px;
          background: var(--offWhiteColor);
          border-radius: 12px;
          overflow: hidden;

          .HomeFeaturedEventsListItemTopImage {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .HomeFeaturedEventsListItemTopEmoji {
            @include fontSizeTitle();
            color: var(--darkColor);
            line-height: 267px;
            text-align: center;
            font-size: 130px;
          }
        }
        .HomeFeaturedEventsListItemTitle {
          @include fontSizeMedium();
          color: var(--darkColor);
          text-align: left;
          padding: 4px 8px 0px 8px;
          white-space: normal;
          width: calc(100% - 16px);
        }
        .HomeFeaturedEventsListItemDate {
          text-align: left;
          padding: 0px 8px 0px 8px;

          img {
            margin-top: 8px;
            width: 30px;
          }
          span {
            @include fontSizeNormal();
            color: var(--grayColor);
          }
        }
      }
    }

    @media only screen and (max-width: 800px) {
      .HomeFeaturedEventsTitle {
        font-size: 32px;
      }
    }
  }

  .HomeMap {
    position: relative;
    width: 100%;

    .HomeMapImage {
      position: absolute;
      z-index: 1;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .HomeMapContent {
      position: relative;
      z-index: 10;
      width: 100%;

      .HomeMapContentRight {
        padding: 32px 32px 200px 50%;

        .HomeMapContentRightTitle {
          @include fontSizeTitle();
          color: var(--grayColor);
          text-align: left;
          max-width: 440px;
          margin: 0px;
        }
        .HomeMapContentRightDescription {
          @include fontSizeNormal();
          color: var(--grayColor);
          text-align: left;
          padding: 32px 0px;
          max-width: 440px;
        }
        .HomeMapContentRightButton {
          @include fontSizeMedium();
          color: var(--whiteColor);
          border-radius: 30px;
          width: 180px;
          padding: 12px 0px;

          @include blueGreenGradient();

          @include pushable();
          @include noselect();
        }
      }
    }
  }
  .HomeMapVertical {
    position: relative;
    display: none;
    width: 100%;
    height: 600px;

    .HomeMapImage {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 600px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .HomeMapContent {
      position: relative;
      z-index: 10;
      width: 100%;

      .HomeMapContentRight {
        padding: 16px;

        .HomeMapContentRightTitle {
          @include fontSizeTitle();
          color: var(--grayColor);
          text-align: center;
          margin: 0px auto;
          max-width: 440px;
        }
        .HomeMapContentRightDescription {
          @include fontSizeNormal();
          color: var(--grayColor);
          text-align: center;
          margin: 0px auto;
          padding: 32px 0px;
          max-width: 440px;
        }
        .HomeMapContentRightButton {
          @include fontSizeMedium();
          color: var(--whiteColor);
          border-radius: 30px;
          width: 180px;
          padding: 12px 0px;
          text-align: center;
          margin: 0px auto 16px auto;

          @include blueGreenGradient();

          @include pushable();
          @include noselect();
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .HomeMap {
      display: none;
    }
    .HomeMapVertical {
      display: block;
    }
  }

  .HomeAllYouNeed {
    @include fontSizeMid();
    color: var(--whiteColor);
    padding: 8px 32px;
    //@include blueGreenGradient();
    background: var(--grayColor);
  }

  .HomeCheckout {
    background: var(--dashboardColor);
    width: 100%;
    height: 600px;
    position: relative;
    z-index: 100;

    &:before {
      content: '';
      position: absolute;
      z-index: 101;
      top: -8px;
      left: 0px;
      height: 8px;
      width: 100%;
      @include blueGreenGradient();
    }
  }

  .HomeSectionContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 32px;

    .HomeSectionContentImage {
      flex-basis: 50%;
      flex-shrink: 1;
      padding-right: 16px;
      img {
        width: 100%;
        max-height: 600px;
      }
    }
    .HomeSectionContentText {
      text-align: left;
      flex-basis: 50%;
      flex-shrink: 1;
      padding-left: 16px;

      .HomeSectionContentTextTitle {
        @include fontSizeTitle();
        color: var(--darkColor);
        max-width: 440px;
        margin: 0px;
      }
      .HomeSectionContentTextSubtitle {
        @include fontSizeMedium();
        color: var(--darkColor);
        max-width: 440px;
        margin: 4px 0px 0px 0px;
      }
      .HomeSectionContentTextBody {
        @include fontSizeNormal();
        color: var(--darkColor);
        padding: 32px 0px;
        max-width: 440px;
      }
      .HomeSectionContentTextImages {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 16px;

        img {
          height: 24px;
          padding-right: 16px;
          object-fit: contain;
        }
      }
      .HomeSectionContentTextButton {
        text-align: center;
        @include fontSizeMedium();
        color: var(--whiteColor);
        border-radius: 30px;
        width: 180px;
        padding: 12px 0px;

        @include blueGreenGradient();

        @include pushable();
        @include noselect();
      }
      .HomeSectionContentTextButtonRed {
        background: rgb(213,15,142);
        background: -moz-linear-gradient(90deg, rgba(213,15,142,1) 0%, rgba(255,148,148,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(213,15,142,1) 0%, rgba(255,148,148,1) 100%);
        background: linear-gradient(90deg, rgba(213,15,142,1) 0%, rgba(255,148,148,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d50f8e",endColorstr="#ff9494",GradientType=1);
      }
    }

    @media only screen and (max-width: 600px) {
      flex-flow: row wrap-reverse;

      .HomeSectionContentImage {
        flex-basis: 100%;
      }
      .HomeSectionContentText {
        flex-basis: 100%;
        text-align: center;
        padding-bottom: 32px;

        .HomeSectionContentTextTitle {
          margin: 0px auto;
        }
        .HomeSectionContentTextBody {
          margin: 0px auto;
        }
        .HomeSectionContentTextImages {
          justify-content: center;
        }
        .HomeSectionContentTextButton {
          margin: 0px auto;
        }
      }
    }
  }
  .HomeSectionContentReverse {
    @media only screen and (max-width: 600px) {
      flex-flow: row wrap;

      .HomeSectionContentText {
        padding-bottom: 32px;
      }
    }
  }

  .HomeVirtualTerminal {
    width: 100%;
    position: relative;

    background: rgb(255,208,224);
    background: -moz-linear-gradient(180deg, rgba(255,208,224,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,208,224,1) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(255,208,224,1) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd0e0",endColorstr="#ffffff",GradientType=1);

    &:before {
      content: '';
      position: absolute;
      z-index: 101;
      top: -2px;
      left: 0px;
      height: 2px;
      width: 100%;
      @include blueGreenGradient();
    }
  }
  .HomeRedCarpet {
    width: 100%;
  }

  .HomePaidFast {
    width: 100%;
  }

  .HomeTeamAccess {
    width: 100%;
  }

  .HomePhone {
    width: 100%;
  }

  .HomeTestimonial {
    text-align: center;
    width: 100%;
    background: var(--offWhiteColor);

    .HomeTestimonialContent {
      max-width: $mainMaxWidth;
      margin: 0px auto;
      padding: 40px 32px;

      .HomeTestimonialTitle {
        max-width: 440px;
        margin: 0px auto;
        @include fontSizeTitle();
        font-size: 44px;
        color: var(--darkColor);
        padding-bottom: 40px;
      }
      .HomeTestimonialVideo {
        width: 640px;
        max-width: 100%;
        margin: 0px auto;
        border-radius: 25px;
        overflow: hidden;
      }
      .HomeTestimonialBody {
        max-width: 600px;
        margin: 0px auto;
        @include fontSizeNormal();
        color: var(--darkColor);
        padding-top: 40px;
        padding-bottom: 40px;

        a {
          text-decoration: none;
          color: var(--blueColor);
          font-weight: bold;
          @include pushable();

          &:hover {
            color: var(--dashboardColor);
          }
        }
      }
      .HomeTestimonialButton {
        display: inline-block;
        text-align: center;
        margin: 0px auto;
        @include fontSizeMedium();
        color: var(--whiteColor);
        border-radius: 12px;
        width: 180px;
        padding: 12px 0px;

        @include blueGreenGradient();

        @include pushable();
        @include noselect();
      }
    }

    @media only screen and (max-width: 800px) {
      .HomeTestimonialContent {
        padding: 40px 16px;
        .HomeTestimonialTitle {
          font-size: 32px;
        }
      }
    }
  }

  .BlogArticle {
    width: calc(100% - 128px);
    max-width: 1200px;
    padding: 64px;
    margin: 0px auto;
    text-align: left;

    @media only screen and (max-width: 800px) {
      padding: 32px;
      width: calc(100% - 64px);
    }

    .BlogArticleTitle {
      width: 100%;
      max-width: 700px;
      margin: 0px auto;

      @include fontSizeTitle();
      font-size: 44px;
      color: var(--darkColor);
      padding: 0px 0px 20px 0px;

      @media only screen and (max-width: 800px) {
        font-size: 32px;
      }
    }
    .BlogArticleSubtitle {
      width: 100%;
      max-width: 700px;
      margin: 0px auto;

      @include fontSizeNormal();
      font-size: 24px;
      color: var(--grayColor);
      text-align: left;
      width: 100%;
      border-bottom: 1px solid var(--grayLineColor);
      padding-bottom: 20px;
    }
    .BlogArticleAuthor {
      width: 100%;
      max-width: 700px;
      margin: 0px auto;

      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 0px;

      .BlogArticleAuthorImage {
        border-radius: 30px;
        width: 60px;
        height: 60px;
        object-fit: cover;
        margin-right: 20px;
      }
      .BlogArticleAuthorBody {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;

        .BlogArticleAuthorBodyName {
          @include fontSizeNormal();
          color: var(--darkColor);
        }
        .BlogArticleAuthorBodyDate {
          @include fontSizeNormal();
          color: var(--grayColor);
        }
      }
    }
    .BlogArticleMainImage {
      width: 100%;
      margin: 0px auto;
    }
    .BlogArticleBlock {
      .BlogArticleBlockHeader {
        width: 100%;
        max-width: 700px;
        margin: 0px auto;

        @include fontSizeTitle();
        font-size: 32px;
        color: var(--darkColor);
        padding: 40px 0px 20px 0px;
      }
      .BlogArticleBlockText {
        width: 100%;
        max-width: 700px;
        margin: 0px auto;

        @include fontSizeNormal();
        font-size: 20px;
        color: var(--darkColor);
        padding: 0px 0px 20px 0px;

        a, a:focus, a:hover, a:visited {
          color: var(--blueColor);
        }
        a:hover {
          text-decoration: underline;
        }

        code {
          background: var(--offWhiteColor);
          color: var(--darkColor);
          padding: 8px 16px;
          border-radius: 4px;
          display: block;
        }

        blockquote {
          padding: 0px 40px;
          margin: 20px 0px;
          border-left: 4px solid var(--darkColor);
          border-radius: 4px;

          blockquote {
            border: 0px;
            font-style: italic;
            color: var(--grayColor);
            margin: 0px;
            padding: 8px 0px 0px 20px;
          }
        }

        img {
          width: 100%;
          margin: 0px auto;
          text-align: center;
          padding: 16px 0px;
        }

        h1 {
          font-size: 44px;
          padding: 40px 0px 20px 0px;

          @media only screen and (max-width: 800px) {
            font-size: 32px;
          }
        }
        h2 {
          font-size: 32px;
          padding: 40px 0px 20px 0px;
        }
        h3 {
          font-size: 28px;
          padding: 40px 0px 20px 0px;
        }
        h4 {
          font-size: 24px;
          padding: 40px 0px 20px 0px;
        }
        h5 {
          font-size: 22px;
          padding: 40px 0px 20px 0px;
        }
        h6 {
          font-size: 22px;
          padding: 40px 0px 20px 0px;
        }
      }
      .BlogArticleBlockImage {
        max-width: 700px;
        width: 100%;
        margin: 0px auto;
        text-align: center;

        img {
          width: 100%;
        }
      }
      .BlogArticleBlockVideo {
        max-width: 700px;
        width: 100%;
        margin: 0px auto;
        text-align: center;
      }
    }
  }

  .BlogError {
    .BlogErrorContent {
      padding: 64px;

      .BlogErrorContentTitle {
        @include fontSizeTitle();
        font-size: 44px;
        color: var(--darkColor);
        padding: 0px 0px 20px 0px;
      }
      .BlogErrorContentBody {
        @include fontSizeNormal();
        font-size: 22;
        color: var(--grayColor);
      }

      @media only screen and (max-width: 800px) {
        .BlogErrorContentTitle {
          font-size: 32px;
        }
      }
    }
  }

  .BlogLoading {
    width: calc(100% - 128px);
    max-width: 1200px;
    padding: 64px;
    margin: 0px auto;
    text-align: left;

    @media only screen and (max-width: 800px) {
      padding: 32px;
      width: calc(100% - 64px);
    }

    .BlogLoadingTitle {
      @include grayLoading();
      border-radius: 22px;

      width: 100%;
      max-width: 700px;
      margin: 0px auto;
      height: 100px;
      margin-bottom: 30px;

      @media only screen and (max-width: 800px) {
        height: 70px;
      }
    }
    .BlogLoadingSubtitle {
      @include grayLoading();
      border-radius: 22px;

      width: 100%;
      max-width: 700px;
      margin: 0px auto;
      height: 64px;
      margin-bottom: 25px;
    }
    .BlogLoadingAuthor {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;

      width: 100%;
      max-width: 700px;
      margin: 0px auto;
      margin-bottom: 20px;

      .BlogLoadingAuthorImage {
        @include grayLoading();
        border-radius: 30px;

        width: 60px;
        height: 60px;
      }
      .BlogLoadingAuthorContent {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        margin-left: 20px;

        .BlogLoadingAuthorContentName {
          @include grayLoading();
          border-radius: 16px;
          margin-bottom: 8px;
          margin-top: 10px;

          width: 120px;
          height: 18px;
        }
        .BlogLoadingAuthorContentPublished {
          @include grayLoading();
          border-radius: 16px;

          width: 120px;
          height: 18px;
        }
      }
    }
    .BlogLoadingImage {
      @include grayLoading();
      width: 100%;
      margin: 0px auto;
      height: 600px;
      border-radius: 22px;

      @media only screen and (max-width: 800px) {
        height: 300px;
      }
    }
  }
}

.App100 {
  height: 100%;
}

.AppHomeMenuOpen {
  height: 100%;
}

.ContactPage {
  .ContactPageContent {
    width: calc(100% - 64px);
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 32px;
    min-height: 400px;

    .ContactPageHeader {
      @include fontSizeTitle();
      font-size: 48px;
      color: var(--darkColor);
      margin: 0px;
    }
    .ContactPageMethods {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;

      .ContactPageMethod {
        padding: 32px 16px 0px 16px;
        text-align: left;
        .ContactPageMethodBar {
          width: 200px;
          height: 4px;
          @include blueGreenGradient();
        }
        .ContactPageMethodTitle {
          text-align: left;
          @include fontSizeMedium();
          color: var(--darkColor);
          padding: 16px 0px 8px 0px;
          margin: 0px;
        }
        .ContactPageMethodBody {
          text-align: left;
          @include fontSizeNormal();
          color: var(--grayColor);
          @include pushable();

          &:hover {
            color: var(--blueColor);
          }
        }
      }
    }
  }
}

.UnsubscribedPage {
  .UnsubscribedPageContent {
    width: calc(100% - 64px);
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 32px;
    min-height: 400px;

    .UnsubscribedPageHeader {
      @include fontSizeTitle();
      color: var(--darkColor);
      margin: 0px;
    }
    .UnsubscribedPageBody {
      @include fontSizeNormal();
      color: var(--grayColor);
      margin: 32px 0px 0px 0px;
    }
  }
}

.AboutPage {
  .AboutPageContent {
    width: calc(100% - 64px);
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 32px;
    min-height: 400px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;

    .AboutPageLeft {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100px;
      position: relative;

      .AboutPageHeader1 {
        @include fontSizeTitle();
        color: var(--darkColor);
        text-align: left;
        width: 220px;
        margin: 0px auto;
      }
      .AboutPageHeader2 {
        @include fontSizeTitle();
        font-size: 48px;
        color: var(--greenColor);
        text-align: left;
        width: 220px;
        margin: 0px auto;
        padding-bottom: 8px;
      }
      .AboutPageHeaderQuote {
        @include fontSizeMedium();
        color: var(--grayColor);
        text-align: left;
        width: 220px;
        margin: 12px auto 0px auto;
      }
      .AboutPageImage {
        width: 100%;
        object-fit: contain;
        position: relative;
        left: -100px;
      }
    }
    .AboutPageRight {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100px;
      text-align: left;

      .AboutPageSectionTitle {
        @include fontSizeStat();
        font-weight: bold;
        color: var(--darkColor);
        margin: 0px;
      }
      .AboutPageSectionBody {
        @include fontSizeMid();
        font-weight: normal;
        color: var(--grayColor);
        padding-top: 5px;
        padding-bottom: 32px;

        .AboutPageSectionBodyDTFAT {
          font-weight: bold;
          font-style: italic;
        }
        .AboutPageSectionBodyGeoji {
          font-weight: bold;
          color: var(--darkColor);
        }
      }
      .AboutPageSectionFooter {
        @include fontSizeTitle();
        font-weight: normal;
        color: var(--grayColor);
        margin: 0px;

        .AboutPageSectionFooterHighlight {
          font-weight: bold;
          color: var(--darkColor);
        }
      }
    }

    @media only screen and (max-width: 800px) {
      flex-flow: row wrap;
      .AboutPageLeft {
        flex-basis: 100%;
        margin-bottom: 32px;

        .AboutPageImage {
          left: 0px;
          right: 0px;
          max-height: 400px;
        }
      }
      .AboutPageRight {
        flex-basis: 100%;
      }
    }
  }
}

.ReachoutPage {
  .ReachoutPageContent {
    width: calc(100% - 64px);
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 32px;
    min-height: 400px;
    text-align: center;

    .ReachoutPageLoading {
      @include grayLoading();
      width: 100%;
      max-width: 320px;
      height: 63px;
      margin: 12px auto;
      border-radius: 12px;
    }
    .ReachoutPageSuccess {
      padding: 32px 0px 0px 0px;
      .ReachoutPageSuccessTitle {
        @include fontSizeTitle();
        color: var(--darkColor);
      }
      .ReachoutPageSuccessBody {
        @include fontSizeNormal();
        color: var(--grayColor);
        padding-top: 8px;
      }
    }
    .ReachoutPageTitle {
      @include fontSizeTitle();
      font-size: 48px;
      color: var(--darkColor);
    }
    .ReachoutPageDescription {
      max-width: 320px;
      margin: 0px auto;
      @include fontSizeNormal();
      color: var(--darkColor);
      padding: 32px 0px;
    }
    .ReachoutPageIdeas {

      .ReachoutPageIdea {

      }
    }
    .InputDiv {
      max-width: 320px;
      margin: 0px auto;

      .inputBottomLineLightContainer {
        .inputBottomLineLightFlexBox {
          input {
          }
        }
      }
    }
    .ReachoutPageButton {
      @include fontSizeMedium();
      color: var(--whiteColor);
      border-radius: 30px;
      max-width: 320px;
      width: 100%;
      padding: 12px 0px;
      margin: 12px auto;
      text-decoration: none;
      background: var(--darkColor);
      @include pushable();
      @include noselect();
    }
    .ReachoutPageDownloads {
      img {
        width: 100%;
        max-width: 220px;
        margin-bottom: 16px;
        @include noselect();
      }
      .ReachoutPageDownloadsBar {
        width: 400px;
        max-width: 100%;
        height: 4px;
        border-radius: 2px;
        margin: 16px auto;
        @include blueGreenGradient();
      }
      .ReachoutPageDownloadsAlternative {
        max-width: 320px;
        margin: 0px auto;
        @include fontSizeNormal();
        color: var(--darkColor);
        padding: 16px 0px;
      }
    }
    .ReachoutPageTestimonials {
      //display: flex;
      //flex-flow: row wrap;
      //justify-content: center;
      //align-items: flex-start;

      .ReachoutPageTestimonial {
        max-width: 320px;
        margin: 64px auto 0px auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        .ReachoutPageTestimonialLeft {
          padding-right: 12px;
          img {
            width: 80px;
            height: 80px;
            object-fit: contain;
          }
        }
        .ReachoutPageTestimonialRight {
          text-align: left;
          @include fontSizeSmall();
          font-weight: normal;
          color: var(--darkColor);
          padding-top: 8px;

          .ReachoutPageTestimonialRightQuote {
            padding-bottom: 12px;
          }
          .ReachoutPageTestimonialRightName {
            font-weight: bold;
            padding-bottom: 2px;
          }
          .ReachoutPageTestimonialRightTitle {

          }
        }
      }
    }
    .ReachoutPageTrust {
      margin-top: 64px;
      .ReachoutPageTrustTitle {
        @include fontSizeMedium();
        color: var(--grayColor);
        padding-bottom: 16px;
      }
      .ReachoutPageTrustBrands {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        .ReachoutPageTrustBrand {
          height: 24px;
          object-fit: contain;
          padding-left: 16px;
          padding-right: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }
}

.BrandPage {
  .BrandPageContent {
    width: calc(100% - 64px);
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 32px;
    min-height: 400px;

    .BrandPageContentTitle {
      @include fontSizeTitle();
      color: var(--darkColor);
      text-align: left;
      padding-bottom: 16px;
    }

    .BrandPageContentTitleLine {
      height: 2px;
      width: 44px;
      background: var(--darkColor);
      margin-bottom: 16px;
      border-radius: 1px;
    }

    .BrandPageContentDescription {
      @include fontSizeNormal();
      color: var(--grayColor);
      text-align: left;
      padding-bottom: 32px;
    }

    .BrandPageContentDownloadLink {
      display: inline-block;
      text-align: center;

      .BrandPageContentDownload {
        width: 180px;
        height: 44px;
        @include blueGreenGradient();
        @include fontSizeMedium();
        color: var(--whiteColor);
        line-height: 44px;
        border-radius: 22px;
        margin-bottom: 32px;
        @include pushable();
        margin-left: 16px;
      }
    }

    .BrandPageContentLogos {
      text-align: left;

      .BrandPageContentLogosList {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 32px;

        .BrandPageContentLogosListItem {
          width: 220px;
          height: 140px;
          margin: 16px;
          border-radius: 12px;
          overflow: hidden;
          border: 2px solid var(--whiteColor);

          .BrandPageContentLogosListItemImage {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .BrandPageContentLogosListItemOdd {
          border: 2px solid var(--offWhiteColor);
        }
      }
    }

    .BrandPageContentColors {
      text-align: left;
      .BrandPageContentColorsSection {
        @include fontSizeStat();
        color: var(--grayColor);
        text-align: left;
        padding-bottom: 32px;
      }
      .BrandPageContentColorsList {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 2px solid var(--offWhiteColor);

        .BrandPageContentColorsListItem {
          width: 100%;
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: flex-start;
          .BrandPageContentColorsListItemBar {
            width: 100%;
            height: 72px;
            border-radius: 16px;
          }
          .BrandPageContentColorsListItemName {
            @include fontSizeNormal();
            color: var(--grayColor);
            padding: 12px 12px 4px 12px;
          }
          .BrandPageContentColorsListItemHex {
            @include fontSizeNormal();
            color: var(--grayColor);
            padding: 0px 12px 12px 12px;
          }
        }
      }
    }
  }
}

.BankPage {
  .BankPageContent {
    width: calc(100% - 64px);
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 32px;
    min-height: 400px;

    .BankPageContentLogin {
      text-align: center;
      .BankPageContentLoginTitle {
        @include fontSizeTitle();
        color: var(--darkColor);
        padding-bottom: 58px;
      }
      .BankPageContentLoginDescription {
        @include fontSizeNormal();
        color: var(--grayColor);
        padding-bottom: 32px;
        max-width: 350px;
        margin: 0px auto;
      }
      .BankPageContentLoginButton {
        @include fontSizeMedium();
        color: var(--whiteColor);
        background: var(--darkColor);
        border-radius: 12px;
        width: 100%;
        max-width: 350px;
        margin: 0px auto;
        line-height: 50px;
        @include pushable();
      }
    }
    .BankPageContentLink {
      text-align: center;
      .BankPageContentLinkTitle {
        @include fontSizeTitle();
        color: var(--darkColor);
        padding-bottom: 16px;
      }
      .BankPageContentLinkName {
        @include fontSizeMid();
        color: var(--blueColor);
        padding-bottom: 16px;
      }
      .BankPageContentLinkDescription {
        @include fontSizeNormal();
        color: var(--grayColor);
        padding-bottom: 32px;
        max-width: 350px;
        margin: 0px auto;
      }
      .BankPageContentLinkButton {
        @include fontSizeMedium();
        color: var(--whiteColor);
        background: var(--darkColor);
        border-radius: 12px;
        width: 100%;
        max-width: 350px;
        margin: 0px auto;
        line-height: 50px;
        @include pushable();
      }
      .BankPageContentLinkButtonDisabled {
        background: var(--grayColor);
        @include pushableLoading();
      }
    }
    .BankPageContentSuccess {
      text-align: center;
      .BankPageContentSuccessTitle {
        @include fontSizeTitle();
        color: var(--darkColor);
        padding-bottom: 58px;
      }
      .BankPageContentSuccessDescription {
        @include fontSizeNormal();
        color: var(--grayColor);
        padding-bottom: 32px;
        max-width: 350px;
        margin: 0px auto;
      }
    }

    .GeojiCnInnerPrompt {
      width: calc(100% - 64px);
      max-width: 350px;
      margin: 0px auto;
      background: var(--whiteColor);
      border-radius: 36px 36px;
      padding: 32px;

      @media only screen and (max-width: 414px) {
        border-radius: 0px 0px;
        min-height: calc(100% - 64px);
      }

      .GeojiCnInnerPromptTopBar {
        text-align: left;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        .GeojiCnInnerPromptTopBarBackArrow {
          padding-right: 67px;
          width: 13px;
          object-fit: contain;
          @include pushable();
          flex-shrink: 0;
        }
        .GeojiCnInnerPromptTopBarTitle {
          @include fontSizeMedium();
          color: var(--darkColor);
          flex-grow: 1;
          text-align: center;
          flex-shrink: 1;
        }
        .GeojiCnInnerPromptTopBarSandbox {
          @include fontSizeSmall();
          color: var(--greenColor);
          background: var(--greenTransparentColor80);
          padding: 4px 12px;
          border-radius: 12px;
          flex-shrink: 0;
        }
        .GeojiCnInnerPromptTopBarSandboxEmpty {
          width: 80px;
          height: 20px;
          flex-shrink: 0;
        }
      }
      .GeojiCnInnerPromptEmoji {
        font-size: 128px;
      }
      .GeojiCnInnerPromptTitle {
        @include fontSizeTitle();
        color: var(--darkColor);
        padding-bottom: 32px;
      }
      .GeojiCnInnerPromptField {
      }
      .GeojiCnInnerPromptError {
        @include fontSizeNormal();
        font-weight: 600;
        color: var(--redColor);
        padding-top: 16px;
      }
      .GeojiCnInnerPromptLoading {
        padding-bottom: 100px;
        .GeojiCnInnerPromptLoadingLogo {
          width: 100px;
          height: 100px;
          object-fit: contain;
          filter: brightness(0.9);
          @include pulse(2.4s);
        }
      }
      .GeojiCnInnerPromptDescription {
        color: var(--grayColor);
        padding-bottom: 64px;
        padding-top: 32px;

        .GeojiCnInnerPromptDescriptionLink {
          color: var(--darkColor);
          display: inline-block;
          @include pushable();
        }
      }
      .GeojiCnInnerPromptButton {
        background: var(--darkColor);
        width: 100%;
        border-radius: 12px;
        @include fontSizeStat();
        font-weight: 600;
        padding: 16px 0px;
        color: var(--whiteColor);
        @include pushable();
        @include noselect();
      }
      .GeojiCnInnerPromptButton2 {
        background: var(--greenColor);
      }
      .GeojiCnInnerPromptButtonLoading {
        width: 100%;
        height: 60px;
        border-radius: 12px;
        @include grayLoading();
      }
      .GeojiCnInnerPurchaseTale {
        @include fontSizeNormal();
        color: var(--grayColor);
        text-align: center;
        padding-top: 32px;

        .GeojiCnInnerPurchaseTaleBold {
          color: var(--darkColor);
          font-weight: bold;
          padding: 4px 0px;
        }
      }
      .AppStoreBadge {
        display: inline-block;
        overflow: hidden;
        border-radius: 13px;
        width: 250px;
        height: 60px;
        margin-top: 16px;

        img {
          border-top-left-radius: 13px;
          border-top-right-radius: 13px;
          border-bottom-right-radius: 13px;
          border-bottom-left-radius: 13px;
          width: 100%;
          height: 100%;
        }
      }

      .GeojiCnInnerPurchase {
        .GeojiCnInnerPurchaseNoAccessTitle {
          padding-top: 32px;
          @include fontSizeTitle();
          color: var(--darkColor);
        }
        .GeojiCnInnerPurchaseNoAccessBody {
          padding-top: 32px;
          @include fontSizeNormal();
          color: var(--grayColor);
        }
        .GeojiCnInnerPurchaseNoAccessID {
          padding-top: 32px;
          @include fontSizeMedium();
          color: var(--blueColor);
        }
        .GeojiCnInnerPurchaseBar {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 8px;

          .GeojiCnInnerPurchaseBarEmoji {
            @include fontSizeNormal();
            font-size: 40px;
            background: var(--offWhiteColor);
            border-radius: 12px;
            width: 70px;
            min-width: 70px;
            height: 100px;
            line-height: 100px;
            border-radius: 12px;
            margin-right: 10px;
          }
          .GeojiCnInnerPurchaseBarList {
            flex-grow: 1;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            padding-top: 8px;

            .GeojiCnInnerPurchaseBarDate {
              @include fontSizeNormal();
              font-weight: bold;
              color: var(--darkColor);

              .GeojiCnInnerPurchaseBarDateTime {
                font-weight: normal;
                color: var(--grayColor);
                display: inline-block;
                padding-left: 8px;
              }
            }

            .GeojiCnInnerPurchaseBarItem {
              flex-grow: 1;
              width: 100%;
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-start;
              align-items: center;
              @include fontSizeNormal();
              color: var(--grayColor);
              padding-top: 4px;

              .GeojiCnInnerPurchaseBarItemQuantity {
                font-weight: bold;
                padding-right: 8px;
                flex-shrink: 0;
              }
              .GeojiCnInnerPurchaseBarItemTitle {
                text-align: left;
                flex-grow: 1;
              }
              .GeojiCnInnerPurchaseBarItemPrice {
                color: var(--darkColor);
                font-weight: bold;
                padding-left: 8px;
                flex-shrink: 0;
              }
            }
          }
          .GeojiCnInnerPurchaseBarLoading {
            width: 100%;
            height: 20px;
            border-radius: 12px;
            margin-top: 8px;
            @include grayLoading();
          }
        }

        .GeojiCnInnerPurchaseTotal {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          margin-top: 32px;

          .GeojiCnInnerPurchaseTotalTitle {
            @include fontSizeTitle();
            color: var(--darkColor);
          }
          .GeojiCnInnerPurchaseTotalValue {
            @include fontSizeTitle();
            color: var(--darkColor);
          }
        }

        .GeojiCnInnerPurchaseProfile {
          margin-top: 16px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          .GeojiCnInnerPurchaseProfileImageView {
            flex-shrink: 0;
            width: 70px;
            height: 70px;
            position: relative;
            background: var(--offWhiteColor);
            border-radius: 24px;
            margin-right: 8px;
            @include pushable();

            .GeojiCnInnerPurchaseProfileImageViewImage {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 24px;
            }
            .GeojiCnInnerPurchaseProfileImageViewImageDefault {
              margin-top: 25%;
              width: 50%;
              height: 50%;
              object-fit: contain;
            }
            .GeojiCnInnerPurchaseProfileImageViewEdit {
              position: absolute;
              left: -3px;
              bottom: -3px;
              width: 21px;
              height: 21px;
              object-fit: contain;
            }
          }
          .GeojiCnInnerPurchaseProfileRight {
            flex-grow: 1;
            text-align: left;
          }
        }
        .GeojiCnInnerPurchaseNotes {
          .GeojiCnInnerPurchaseNotesLoading {
            width: 100%;
            height: 44px;
            border-radius: 12px;
            margin-top: 34px;
            margin-bottom: 27px;
            @include grayLoading();
          }
        }
        .GeojiCnInnerPurchaseWaivers {
          padding-bottom: 32px;
          .GeojiCnInnerPurchaseWaiversTitle {
            @include fontSizeMid();
            color: var(--darkColor);
            text-align: left;
            margin-top: 32px;
          }
          .GeojiCnInnerPurchaseWaiversW {
            background: var(--offWhiteColor);
            border-radius: 12px;
            margin-top: 16px;
            padding: 12px 16px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            @include pushable();
            @include noselect();

            .GeojiCnInnerPurchaseWaiversWLeft {
              flex-grow: 1;
              text-align: left;
              .GeojiCnInnerPurchaseWaiversWLeftTitle {
                @include fontSizeNormal();
                font-weight: 600;
                color: var(--grayColor);
                padding-bottom: 8px;
              }
              .GeojiCnInnerPurchaseWaiversWLeftBody {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                .GeojiCnInnerPurchaseWaiversWLeftBodySignature {
                  flex-grow: 0;
                  margin-right: 8px;

                  img {
                    width: 26px;
                    object-fit: contain;
                  }
                }
                .GeojiCnInnerPurchaseWaiversWLeftBodyName {
                  flex-grow: 1;
                  @include fontSizeNormal();
                  color: var(--darkColor);
                  font-weight: bold;
                  font-style: italic;
                }
              }
            }
            .GeojiCnInnerPurchaseWaiversWRight {
              flex-grow: 0;
              img {
                width: 12px;
                object-fit: contain;
              }
            }
          }
        }

        .GeojiCnInnerPurchaseMassLoading {
          width: 100%;
          height: 68px;
          border-radius: 12px;
          margin-top: 32px;
          @include grayLoading();
        }
        .GeojiCnInnerPurchaseMassContainer {
          width: calc(100% + 64px);
          margin-left: -32px;

          .GeojiCnInnerPurchaseMassAd {
            .GeojiCnInnerPurchaseMassAdBandText {
              width: calc(100% - 32px);
              padding: 16px 16px 0px 16px;
              display: flex;
              flex-flow: row nowrap;
              justify-content: center;
              align-items: center;

              .GeojiCnInnerPurchaseMassAdBandTextL {
                width: 24px;
                height: 24px;
                margin-right: 8px;
                flex-shrink: 0;
              }
              .GeojiCnInnerPurchaseMassAdBandTextM {
                flex-grow: 1;

                .GeojiCnInnerPurchaseMassAdBandTextMT {
                  @include fontSizeMid();
                  color: var(--darkColor);
                }
                .GeojiCnInnerPurchaseMassAdBandTextMB {
                  @include fontSizeNormal();
                  color: var(--grayColor);
                }
              }
              .GeojiCnInnerPurchaseMassAdBandTextR {
                width: 24px;
                height: 24px;
                margin-left: 8px;
                flex-shrink: 0;
              }
            }

            .GeojiCnInnerPurchaseMassAdNumber {
              margin-top: 16px;
              @include fontSizeTitle();
              color: var(--darkColor);
              font-size: 64px;
              line-height: 100px;
              background: var(--offWhiteColor);
              min-height: 100px;
              display: inline-block;
              min-width: 36px;
              text-align: center;
              border-radius: 50px;
              padding: 0px 32px;
            }
          }

          .GeojiCnInnerPurchaseMassBottom {
            @include fontSizeNormal();
            color: var(--grayColor);
            margin-top: 16px;
          }
        }
        .GeojiCnInnerPurchaseMapContainer {
          width: 100%;

          .GeojiCnInnerGrayGroupTokenMap {
            background: var(--whiteColor);
            border-radius: 12px;
            margin-top: 32px;

            .GeojiCnInnerGrayGroupTokenMapGraphic {
              border-radius: 12px 12px 0px 0px;
              position: relative;
              overflow: hidden;

              display: flex;
              flex-flow: row nowrap;
              justify-content: center;
              align-items: center;

              .GeojiCnInnerGrayGroupTokenMapGraphicImage {
                width: 100%;
                object-fit: contain;
              }

              .GeojiCnInnerGrayGroupTokenMapGraphicSeat {
                left: 0px;
                top: 0px;
                position: absolute;
                background: var(--grayLightColor);
                //border: 1px solid lighten(var(--blueColor), 30%);
                width: 30px;
                height: 30px;
                z-index: 2;
                border-radius: 4px;
                @include pushable();
                @include noselect();

                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;

                .GeojiCnInnerGrayGroupTokenMapGraphicSeatText {
                  @include fontSizeMedium();
                  color: var(--whiteColor);
                  opacity: 0;
                }

                &:hover {
                  .GeojiCnInnerGrayGroupTokenMapGraphicSeatText {
                    opacity: 1;
                  }
                }
              }
              .GeojiCnInnerGrayGroupTokenMapGraphicSeatSelected {
                background: var(--greenColor);
                //border: 1px solid lighten(var(--greenColor), 30%);

                .GeojiCnInnerGrayGroupTokenMapGraphicSeatText {
                  opacity: 1;
                }
              }

              .GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeCircle {
                border-radius: 50%;
              }
              .GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeSquare {
              }
              /*.GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeRect {
                width: 60px;
                margin-left: -15px;
              }
              .GeojiCnInnerGrayGroupTokenMapGraphicSeatShapeVRect {
                height: 60px;
                margin-top: -15px;
              }*/
            }

            .GeojiCnInnerGrayGroupTokenMapAbout {
              @include fontSizeNormal();
              color: var(--grayColor);
              text-align: left;
              padding: 4px 12px 0px 12px;
            }
          }
        }

        .GeojiCnInnerPurchaseTicketBar {
          position: relative;
          left: -32px;
          width: calc(100% + 64px);
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          z-index: 2;
          padding-top: 32px;
          .GeojiCnInnerPurchaseTicketBarLeft {
            width: 15px;
            height: 30px;
            flex-grow: 0;
            border-radius: 0px 15px 15px 0px;
            background: var(--darkColor);
          }
          .GeojiCnInnerPurchaseTicketBarMiddle {
            height: 1px;
            flex-grow: 1;
            border-bottom: 2px dashed var(--darkColor);
            margin: 0px 16px;
          }
          .GeojiCnInnerPurchaseTicketBarRight {
            width: 15px;
            height: 30px;
            flex-grow: 0;
            border-radius: 15px 0px 0px 15px;
            background: var(--darkColor);
          }
        }
        .GeojiCnInnerPurchaseTicketImage {
          width: 200px;
          padding-top: 16px;
        }
        .GeojiCnInnerPurchaseLoading {
          width: 100%;
          height: 50px;
          border-radius: 12px;
          margin-top: 32px;
          @include grayLoading();
        }
        .GeojiCnInnerPurchaseSaveButton {
          width: 100%;
          height: 50px;
          line-height: 50px;
          background: var(--darkColor);
          border-radius: 12px;
          @include fontSizeNormal();
          font-weight: bold;
          color: var(--whiteColor);
          margin-top: 32px;
          @include pushable();
        }
        .GeojiCnInnerPurchaseSaveButtonGray {
          background: var(--grayLightColor);
          color: var(--whiteColor);
        }
        .GeojiCnInnerPurchaseSaveButtonBlue {
          background: var(--blueColor);
          color: var(--whiteColor);
        }

        .GeojiCnInnerPurchaseField {
          .InputDiv {
            .inputBottomLineLightContainer {
              .inputBottomLineTop {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                .inputBottomLineTitle {
                  @include fontSizeNormal();
                  font-weight: bold;
                  padding-left: 16px;
                  padding-bottom: 4px;

                  .inputBottomLineTitleEmoji {
                    padding-left: 8px;
                    font-size: 12px;
                    vertical-align: middle;
                  }
                }
                .inputBottomLineDescription {
                  @include fontSizeSmall();
                  color: var(--grayColor);
                }
              }
              .inputBottomLineLightFlexBox {
                .inputBottomLineLight {
                  height: 26px;
                }
              }
            }
          }
        }

        .GeojiCnInnerPurchaseKeepYourTickets {
          @include fontSizeNormal();
          color: var(--grayColor);
          text-align: center;
          padding-top: 16px;
        }
      }

      .GeojiCnInnerPurchaseEmail {
        padding-top: 32px;

        .GeojiCnInnerPurchaseField {
          .InputDiv {
            .inputBottomLineLightContainer {
              .inputBottomLineTop {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                .inputBottomLineTitle {
                  @include fontSizeNormal();
                  font-weight: bold;
                  padding-left: 16px;
                  padding-bottom: 4px;

                  .inputBottomLineTitleEmoji {
                    padding-left: 8px;
                    font-size: 12px;
                    vertical-align: middle;
                  }
                }
                .inputBottomLineDescription {
                  @include fontSizeSmall();
                  color: var(--grayColor);
                }
              }
              .inputBottomLineLightFlexBox {
                .inputBottomLineLight {
                  height: 26px;
                }
              }
            }
          }
        }
        .GeojiCnInnerPurchaseEmailButton {
          margin-top: 32px;
          padding: 16px 0px;
          width: 100%;
          background: var(--greenColor);
          border-radius: 12px;
          @include pushable();
          @include noselect();

          @include fontSizeMedium();
          color: var(--whiteColor);
        }
        .GeojiCnInnerPurchaseEmailButtonLoading {
          margin-top: 32px;
          width: 100%;
          height: 50px;
          border-radius: 12px;
          @include grayLoading();
        }
      }
    }
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
