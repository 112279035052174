// main: ../../App.scss

.HandlePage {
  width: calc(100% - 64px);
  background: var(--whiteColor);
  border-radius: 36px 36px;
  padding: 32px;

  @media only screen and (max-width: 414px) {
    border-radius: 0px;
    margin: 0px;
    height: calc(100% - 64px);
    overflow-y: scroll;
  }

  .HandlePageTop {
    position: relative;
    .HandlePageTopBackground {
      width: calc(100% + 64px);
      height: 132px;
      position: absolute;
      z-index: 0;
      top: -64px;
      left: -32px;
      border-radius: 36px 36px 0px 0px;
      background: var(--offWhiteColor);
      overflow: hidden;

      @media only screen and (max-width: 414px) {
        border-radius: 0px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .HandlePageTopForeground {
      position: relative;
      z-index: 1;
      width: 125px;
      height: 125px;
      margin: 32px auto 32px auto;
      background: var(--offWhiteColor);
      border-radius: 42px;
      border: 5px solid var(--whiteColor);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .HandlePageTitle {
    @include fontSizeMid();
    color: var(--darkColor);
    margin: 0px;
  }
  .HandlePageBio {
    @include fontSizeNormal();
    color: var(--darkColor);
    padding: 16px 0px 16px 0px;
    white-space: pre-wrap;
    margin: 0px;
  }
  .HandlePageCategory {
    .HandlePageCategoryTitle {
      @include fontSizeMedium();
      color: var(--darkColor);
      padding-top: 16px;
      text-align: left;
    }
  }
  .HandlePageLink {
    @include fontSizeNormal();
    font-weight: bold;
    color: var(--darkColor);
    text-decoration: none;
    .HandlePageLinkBody {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      background: var(--offWhiteColor);
      padding: 12px;
      margin-top: 16px;
      border-radius: 12px;

      .HandlePageLinkBodyEmoji {
        margin-right: 12px;
      }
      .HandlePageLinkBodyName {
        text-align: left;
      }
    }
  }
}

.Pushable {
  @include pushable();
}
.HandlePageGeoji {
  margin-top: 16px;
  width: 100%;
  display: block;
  text-decoration: none;
  @include noselect();

  .HandlePageGeojiBody {
    background: var(--offWhiteColor);
    border-radius: 12px;
    padding: 6px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 12px);

    .HandlePageGeojiImage {
      width: 100px;
      height: 100px;
      object-fit: cover;
      background: var(--whiteColor);
      border-radius: 8px;
      flex-shrink: 0;
    }
    .HandlePageGeojiEmoji {
      width: 100px;
      height: 100px;
      background: var(--whiteColor);
      border-radius: 8px;
      text-align: center;
      line-height: 100px;
      @include fontSizeNormal();
      font-size: 60px;
      flex-shrink: 0;
    }
    .HandlePageGeojiRight {
      padding-top: 4px;
      padding-left: 12px;
      text-align: left;
      //flex-shrink: 1;
      flex-basis: 100%;
      flex-grow: 1;

      .HandlePageGeojiRightTop {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 4px;

        .HandlePageGeojiRightDate {
          @include fontSizeMedium();
          color: var(--darkColor);
          text-align: left;
          padding-right: 12px;

          img {
            height: 14px;
            object-fit: contain;
          }
        }
        .HandlePageGeojiRightButton {
          @include fontSizeSmall();
          color: var(--whiteColor);
          background: var(--greenColor);
          display: inline-block;
          padding: 2px 12px;
          border-radius: 6px;
          flex-shrink: 0;
        }
        .HandlePageGeojiRightButtonEdit {
          @include fontSizeSmall();
          color: var(--whiteColor);
          background: var(--greenColor);
          display: inline-block;
          padding: 2px 12px;
          border-radius: 6px;
          flex-shrink: 0;
          @include pushable();
        }
        .HandlePageGeojiRightButtonOpen {
          @include fontSizeSmall();
          color: var(--whiteColor);
          background: var(--blueColor);
          display: inline-block;
          padding: 2px 12px;
          border-radius: 6px;
          flex-shrink: 0;
          margin-right: 8px;
          @include pushable();
        }
      }

      .HandlePageGeojiRightTitle {
        @include fontSizeSmall();
        font-size: 15px;
        font-weight: normal;
        color: var(--grayColor);
      }
      .HandlePageGeojiRightWhen {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding-top: 6px;

        img {
          height: 16px;
          margin-right: 4px;
        }
        .HandlePageGeojiRightWhenText {
          @include fontSizeSmall();
          color: var(--grayColor);
        }
      }
    }
  }
}
