// main: ../../App.scss

.DashboardPage {
  .GeojiLoading {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 64px 0px;

    .GeojiLoadingLogo {
      width: 200px;
      height: 200px;
      @include pulse(2.4s);
    }
  }
  .DashboardPageContent {
    width: calc(100% - 64px);
    max-width: $mainMaxWidth;
    margin: 0px auto;
    padding: 32px;
    min-height: 400px;

    .DashboardPageHeader {
      @include fontSizeTitle();
      font-size: 48px;
      color: var(--darkColor);
      margin: 0px 0px 32px 0px;
    }
    .DashboardPageBar {
      width: 400px;
      max-width: 100%;
      height: 4px;
      border-radius: 2px;
      margin: 0px auto;
      @include blueGreenGradient();
    }
    //Actions
    .DashboardPageActions {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;
      padding: 32px 0px;

      .DashboardPageAction {
        padding: 32px;
        margin: 16px;
        text-align: center;
        background: var(--offWhiteColor);
        width: 200px;
        height: 300px;
        border-radius: 25px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        @include pushable();

        &:hover {
          background: var(--blueColorLighten45);
        }

        .DashboardPageActionImage {
          width: 100px;
        }
        .DashboardPageActionTitle {
          text-align: center;
          @include fontSizeMedium();
          color: var(--darkColor);
          padding: 12px 0px 4px 0px;
        }
        .DashboardPageActionBody {
          text-align: center;
          @include fontSizeNormal();
          color: var(--grayColor);
        }
      }
    }

    .DashboardPageButtons {
      .DashboardPageButton {
        @include fontSizeMedium();
        color: var(--darkColor);
        background: var(--offWhiteColor);
        border-radius: 30px;
        width: 220px;
        padding: 12px 0px;
        margin: 16px auto 0px auto;

        @include pushable();
        @include noselect();
      }
    }

    //Geojis
    .DashboardPageGeojis {
      .HandlePageGeoji {
        width: 100%;
        max-width: 400px;
        margin: 16px auto 0px auto;
      }
    }
    .AddButton {
      @include fontSizeMedium();
      color: var(--whiteColor);
      border-radius: 30px;
      width: 220px;
      padding: 12px 0px;
      margin: 16px auto 16px auto;
      background: var(--greenColor);

      @include pushable();
      @include noselect();
    }
    .CreateButton {
      @include fontSizeMedium();
      color: var(--whiteColor);
      border-radius: 30px;
      width: 220px;
      padding: 12px 0px;
      margin: 16px auto 0px auto;

      @include blueGreenGradient();

      @include pushable();
      @include noselect();
    }
    .DeleteButton {
      @include fontSizeMedium();
      color: var(--redColor);
      border-radius: 30px;
      width: 220px;
      padding: 12px 0px;
      margin: 16px auto 0px auto;
      background: var(--offWhiteColor);

      @include pushable();
      @include noselect();
    }

    //Ticket Purchases
    .DashboardPageEditor {
      width: 440px;
      max-width: 100%;
      margin: 0px auto;

      .SettingsToggleBottom {
        @include fontSizeSmall();
        color: var(--grayColor);
        padding-bottom: 16px;
      }
      .SettingsToggle {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin: 16px auto 8px auto;

        .SettingsToggleName {
          padding: 0px 16px;
          @include fontSizeMid();
          color: var(--darkColor);
          width: 140px;
          text-align: left;
        }
        .SettingsToggleNameLeft {
          text-align: right;
        }
        .react-toggle {
          .react-toggle-track {
            background-color: var(--grayColor);
          }
          .react-toggle-thumb {
            border: 1px solid var(--grayColor);
          }
        }
        .react-toggle--checked {
          .react-toggle-track {
            background-color: var(--greenColor);
          }
          .react-toggle-thumb {
            border: 1px solid var(--greenColor);
          }
        }
      }
      .inputBottomAreaLight {
        @include fontSizeMedium();
        transition: all 0.5s;
        color: var(--darkColor);
        height: auto;
        min-height: 79px;
        width: 100%;
        background: transparent;
        padding: 9px 12px;
        border: 0px;
        resize: none;

        &:focus {
          outline: 0px !important;
        }
      }
      .inputBottomLineLightContainer {

        .inputBottomLineTitle {
          padding: 0px 12px 4px 0px;
        }
        .inputBottomLineDescription {
          @include fontSizeSmall();
          color: var(--grayColor);
          padding: 0px 12px 4px 0px;
        }
        .inputBottomLineNote {
        }
        .inputBottomLineFee {
        }

        .inputBottomLineInvalid {
        }

        .inputBottomLineLightFlexBox {

          .moneyInputSign {
            margin-right: -8px;
            margin-left: 8px;
            z-index: 10;
          }

          .inputBottomLineIcon {
          }
          .inputBottomLineIconHidden {
          }

          .manualSelect {
          }
        }
        .inputBottomLineLightFlexBoxClear {

          .inputBottomLineIcon {
          }
          .inputBottomLineIconHidden {
          }
        }
        .inputBottomLineLightFlexBoxGrow {
          align-items: flex-start;
          height: auto;
        }
        .inputBottomLineLightFlexBoxCalendar {
          align-items: flex-start;
          height: auto;

          .inputBottomLineIcon {
            height: 30px;
            padding: 8px 0px 0px 0px;
          }
          .inputBottomLineIconHidden {
            display: none;
          }
        }
        .inputBottomLineMobileDate {

          input {
          }

          .inputBottomLineIcon {
          }
          .inputBottomLineIconHidden {
          }
        }
        .GoogleMapsDropdownContainer {

          .GoogleMapsDropdownContainerLoading {
          }
          .GoogleMapsItem, .GoogleMapsItemActive {
          }
          .GoogleMapsItemActive {
          }
        }

        .inputBottomLineLight {

          &:focus {
          }
        }
        .inputBottomLineBlockLight {
        }
        .inputBottomLineLight:focus ~ .inputBottomLineBlockLight {
        }

        .inputBottomLineCheckbox {

          //this is the thing we are clicking
          label {
            .dualImageSingle {
            }
          }
          .inputBottomLineCheckboxTrue {
          }
          .inputBottomLineCheckboxFalse {
          }
          span {
          }
          //hide the input
          input {
          }
        }
        .inputBottomLineCheckboxLight {
          .inputBottomLineCheckboxTrue {
          }
          .inputBottomLineCheckboxFalse {
          }
          span {
          }
        }
      }

      .DashboardPageEditorBreak {
        width: 400px;
        max-width: 100%;
        height: 4px;
        border-radius: 2px;
        background: var(--offWhiteColor);
        margin: 32px auto;
      }
      .DashboardPageEditorSectionTitle {
        @include fontSizeMid();
        color: var(--grayColor);
      }
      .SettingsToggleDetails {
        @include fontSizeSmall();
        color: var(--grayColor);
        padding-bottom: 12px;
      }

      .EmojiList {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin: 0px auto;
        overflow-x: scroll;
        height: 55px;

        .EmojiListTitle {
          @include fontSizeNormal();
          padding-right: 12px;
          flex-shrink: 0;
        }

        .EmojiItem {
          @include fontSizeTitle();
          padding: 2px 8px;
          background: var(--offWhiteColor);
          border-radius: 12px;
          margin-right: 8px;
          @include pushable();
          flex-shrink: 0;
        }
      }

      .DashboardPageEditorTokens {
        background: var(--offWhiteColor);
        border-radius: 8px;
        padding: 8px;
      }
    }
  }
}
